const validateBillingItem = (name, value) => {
    switch (name) {
        case 'billing_cardnumber':
            return value.replace(/\s/g, '').length === 16;
        case 'billing_expiration':
            return value.replace(/\s/g, '').length === 7;
        case 'billing_cvv':
            return value.replace(/\s/g, '').length === 3;
        case 'billing_postalcode':
        case 'billing_address1':
        case 'billing_address2':
        case 'billing_country':
        case 'billing_city':
        case 'billing_provincestate':
            return value.length > 0;
        default:
            return false;
    }
};

export function billingReducer(prevBillingState, action) {
    const newBillingState = {...prevBillingState};
    const {name, value} = action.payload;
    newBillingState[name] = value;
    return newBillingState;
}

export function billingErrorReducer(prevBillingErrorState, action) {
    const newBillingErrorState = {...prevBillingErrorState};
    const {name, value} = action.payload;
    newBillingErrorState[name] = validateBillingItem(name, value);
    return newBillingErrorState;
}
