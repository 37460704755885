import React, {useContext} from 'react';
import '../../assets/css/addonViewOnly.css';
import {OnBoardContext} from '../../contexts/onboardContext';

// import { OnBoardContext } from "../../contexts/onboardContext";
import {FormattedMessage} from 'react-intl';
import currency from '../../currency/currency.json';

const AddonViewOnly = ({
    addonName,
    itemName,
    itemType,
    quantity,
    price,
    discount,
    discountPercent,
    currencyCode,
    // quoteType,
}) => {
    const {onboardInfo} = useContext(OnBoardContext);

    const calculateUnitPrice = (price, quantity) => {
        return Number(quantity) / Number(price);
    };

    const quoteType = onboardInfo[0].planCode;

    let itemDiscount;

    if (discount) {
        itemDiscount = (
            <div className='disc'>
                <FormattedMessage id='order.discount' />
                {currency['symbols'][currencyCode]['symbol']}
                {parseInt(discount).toFixed(2)} <FormattedMessage id='order.per_item' />
            </div>
        );
    } else if (discountPercent) {
        itemDiscount = (
            <div className='disc'>
                <FormattedMessage id='order.discount' /> {discountPercent}%
            </div>
        );
    } else {
        itemDiscount = <></>;
    }

    const applyDiscount = (originalPrice, discountValue, discountType, quantity) => {
        let newPrice = 0;
        if (discountType === '%') {
            newPrice = originalPrice - (originalPrice * discountValue) / 100;
        }

        if (discountType === '-') {
            newPrice = originalPrice - discountValue * quantity;
        }

        return newPrice;
    };

    const processBrackets = (addonString) => {
        let result = '';

        if (addonString.includes('[Standard]')) {
            result = String(addonString).split('[Standard] ');
            return result[1];
        } else if (addonString.includes('[Monthly]')) {
            result = String(addonString).split('[Monthly] ');
            return result[1];
        } else if (addonString.includes('[Yearly]')) {
            result = String(addonString).split('[Yearly] ');
            return result[1];
        } else if (addonString.includes('[Annuel]')) {
            result = String(addonString).split('[Annuel] ');
            return result[1];
        } else if (addonString.includes('[Mensuel]')) {
            result = String(addonString).split('[Mensuel] ');
            return result[1];
        } else if (addonString.includes('[Extra]')) {
            result = String(addonString).replace('[Extra] ', 'Extra - ');
            return result;
        } else {
            return addonString; // rectified to return addonString, the origin name of the addon, if the text does not contain any brackets (this return is needed by custom quotes, since some names do not contain brackets)
        }
    };

    // if(!addonName.includes('Croo Talk') ){//display the item ONLY if it's not the croo talk plan name itself
    if (!addonName.includes('PBX') && onboardInfo[0].planCode !== 'cust-quote') {
        //display the item ONLY if it's not the croo talk plan name itself
        return (
            //also check if onboardInfo[0].planCode !== 'cust-quote' , meaning that only apply the display: none condition if the plan is not hubspot
            // <div className="add-on-con view" style={{ display: addonName.includes('PBX') && onboardInfo[0].planCode !== 'cust-quote' ? 'none' : null }} >
            <div className={`add-on-con view ${quoteType === 'cust-quote' ? 'quote' : ''}`}>
                {/* <div className="name-view">test {addonName}</div> */}
                {itemType !== undefined ? (
                    <FormattedMessage id={itemName.trim()}>
                        {(placeholder) => (
                            <div
                                className={`name-view-bill ${
                                    quoteType === 'cust-quote' ? 'quote' : ''
                                }`}
                            >
                                <span className='brackets'>{itemType}</span>
                                {String(placeholder).replace('Croo Talk', 'Cloud PBX')}
                            </div>
                        )}
                    </FormattedMessage>
                ) : (
                    <div className={`name-view-bill ${quoteType === 'cust-quote' ? 'quote' : ''}`}>
                        {processBrackets(addonName)}
                    </div>
                )}

                {price > 0 ? (
                    <>
                        <div
                            className={`qty-view-bill ${quoteType === 'cust-quote' ? 'quote' : ''}`}
                        >
                            <span></span>
                            <input type='text' disabled defaultValue={quantity} />
                        </div>
                        <div
                            className={`unit-prc-view-bill ${
                                quoteType === 'cust-quote' ? 'quote' : ''
                            }`}
                        >
                            <span>
                                <span className='prc-value'>
                                    {currency['symbols'][currencyCode]['symbol']}
                                    {/* {applyDiscount(price, discountPercent, '%', quantity).toFixed(
                                        2
                                    )} */}
                                    {calculateUnitPrice(quantity, price).toFixed(2)}
                                </span>{' '}
                                <span className='prc-code testing here'>
                                    {currency['symbols'][currencyCode]['code']}
                                </span>
                            </span>
                        </div>
                        {discountPercent && (
                            <div
                                className={`prc-view-bill ${
                                    quoteType === 'cust-quote' ? 'quote' : ''
                                }`}
                            >
                                <span>
                                    <span className='prc-value'>
                                        {currency['symbols'][currencyCode]['symbol']}
                                        {applyDiscount(
                                            price,
                                            discountPercent,
                                            '%',
                                            quantity
                                        ).toFixed(2)}
                                    </span>{' '}
                                    <span className='prc-code'>
                                        {currency['symbols'][currencyCode]['code']}
                                    </span>
                                </span>
                            </div>
                        )}
                        {discount && (
                            <div
                                className={`prc-view-bill ${
                                    quoteType === 'cust-quote' ? 'quote' : ''
                                }`}
                            >
                                <span>
                                    <span className='prc-value'>
                                        {currency['symbols'][currencyCode]['symbol']}
                                        {applyDiscount(price, discount, '-', quantity).toFixed(2)}
                                    </span>{' '}
                                    <span className='prc-code'>
                                        {currency['symbols'][currencyCode]['code']}
                                    </span>
                                </span>
                            </div>
                        )}
                        {!discount && !discountPercent && (
                            <div
                                className={`prc-view-bill ${
                                    quoteType === 'cust-quote' ? 'quote' : ''
                                }`}
                            >
                                <span>
                                    <span className='prc-value'>
                                        {currency['symbols'][currencyCode]['symbol']}
                                        {price.toFixed(2)}
                                    </span>{' '}
                                    <span className='prc-code'>
                                        {currency['symbols'][currencyCode]['code']}
                                    </span>
                                </span>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div
                            className={`qty-view-bill ${quoteType === 'cust-quote' ? 'quote' : ''}`}
                        >
                            <input type='text' disabled defaultValue={quantity} />
                        </div>
                        <div
                            className={`unit-prc-view-bill ${
                                quoteType === 'cust-quote' ? 'quote' : ''
                            }`}
                        >
                            <FormattedMessage id='order.included' />
                        </div>
                        <div className={`prc-view-bill`}>
                            <FormattedMessage id='order.included' />
                        </div>
                    </>
                )}
                {itemDiscount}
            </div>
        );
    } else if (!addonName.includes('Croo Talk') && onboardInfo[0].planCode === 'cust-quote') {
        return (
            //also check if onboardInfo[0].planCode !== 'cust-quote' , meaning that only apply the display: none condition if the plan is not hubspot
            // <div className="add-on-con view" style={{ display: addonName.includes('PBX') && onboardInfo[0].planCode !== 'cust-quote' ? 'none' : null }} >
            <div className={`add-on-con view`}>
                {itemType !== undefined ? (
                    <FormattedMessage id={itemName.trim()}>
                        {(placeholder) => (
                            <div
                                className={`name-view-bill ${
                                    quoteType === 'cust-quote' ? 'quote' : ''
                                }`}
                            >
                                <span className='brackets'>{itemType}</span>
                                {String(placeholder).replace('Croo Talk', 'Cloud PBX')}
                            </div>
                        )}
                    </FormattedMessage>
                ) : (
                    <div className={`name-view-bill ${quoteType === 'cust-quote' ? 'quote' : ''}`}>
                        {processBrackets(addonName)}
                    </div>
                )}

                {price > 0 ? (
                    <>
                        <div
                            className={`qty-view-bill ${quoteType === 'cust-quote' ? 'quote' : ''}`}
                        >
                            <span></span>
                            <input type='text' disabled defaultValue={quantity} />
                        </div>
                        <div
                            className={`unit-prc-view-bill ${
                                quoteType === 'cust-quote' ? 'quote' : ''
                            }`}
                        >
                            <span>
                                <span className='prc-value'>
                                    {currency['symbols'][currencyCode]['symbol']}
                                    {calculateUnitPrice(quantity, price).toFixed(2)}
                                </span>
                                <span className='prc-code'>
                                    {currency['symbols'][currencyCode]['code']}
                                </span>
                            </span>
                        </div>
                        {discountPercent && (
                            <div
                                className={`prc-view-bill ${
                                    quoteType === 'cust-quote' ? 'quote' : ''
                                }`}
                            >
                                <span>
                                    <span className='prc-value'>
                                        {currency['symbols'][currencyCode]['symbol']}
                                        {applyDiscount(
                                            price,
                                            discountPercent,
                                            '%',
                                            quantity
                                        ).toFixed(2)}
                                    </span>
                                    <span className='prc-code'>
                                        {currency['symbols'][currencyCode]['code']}
                                    </span>
                                </span>
                            </div>
                        )}
                        {discount && (
                            <div
                                className={`prc-view-bill ${
                                    quoteType === 'cust-quote' ? 'quote' : ''
                                }`}
                            >
                                <span>
                                    <span className='prc-value'>
                                        {currency['symbols'][currencyCode]['symbol']}
                                        {applyDiscount(price, discount, '-', quantity).toFixed(2)}
                                    </span>
                                    <span className='prc-code'>
                                        {currency['symbols'][currencyCode]['code']}
                                    </span>
                                </span>
                            </div>
                        )}
                        {!discount && !discountPercent && (
                            <div
                                className={`prc-view-bill ${
                                    quoteType === 'cust-quote' ? 'quote' : ''
                                }`}
                            >
                                <span>
                                    <span className='prc-value'>
                                        {currency['symbols'][currencyCode]['symbol']}
                                        {price.toFixed(2)}
                                    </span>
                                    <span className='prc-code'>
                                        {currency['symbols'][currencyCode]['code']}
                                    </span>
                                </span>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div
                            className={`qty-view-bill ${quoteType === 'cust-quote' ? 'quote' : ''}`}
                        >
                            <input type='text' disabled defaultValue={quantity} />
                        </div>
                        <div
                            className={`unit-prc-view-bill ${
                                quoteType === 'cust-quote' ? 'quote' : ''
                            }`}
                        >
                            {/* <FormattedMessage id='order.included' /> */}
                            <span className='prc-value'>
                                {currency['symbols'][currencyCode]['symbol']}
                                {price.toFixed(2)}
                            </span>
                            <span className='prc-code'>
                                {currency['symbols'][currencyCode]['code']}
                            </span>
                        </div>
                        <div className={`prc-view-bill`}>
                            {/* <FormattedMessage id='order.included' /> */}
                            <span className='prc-value'>
                                {currency['symbols'][currencyCode]['symbol']}
                                {price.toFixed(2)}
                            </span>
                            <span className='prc-code'>
                                {currency['symbols'][currencyCode]['code']}
                            </span>
                        </div>
                    </>
                )}
                {itemDiscount}
            </div>
        );
    } else {
        return null;
    }
};

export default AddonViewOnly;
