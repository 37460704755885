import React, {useContext, useState, useEffect} from 'react';
import {OnBoardContext} from '../../contexts/onboardContext';
import PlanManager from '../../components/addons/planManager';
import '../../assets/css/orderInfo.css';
import ContactInfoView from './contactInfoView';
import ContactOnboardInfoView from './contactOnboardInfoView';
import {FormattedMessage} from 'react-intl';
import currency from '../../currency/currency.json';

let renderAmount = 0;
let reorderedVal = false;
//https://codesandbox.io/s/jlx4vq1l9w?file=/src/index.js
//https://codesandbox.io/s/wu7yj?file=/src/option2/App.jsx
const OrderInfo = ({order, onSubmit, onStageChange}) => {
    const {
        onboardInfo,
        setOnBoardInfo,
        orderData,
        quoteTotal,
        setQuoteTotal,
        setQuoteInfo,
        emailLang,
        onboardStatus,
        setOnboardStatus,
        onBoardCurrency,
        quoteNotFound,
        quoteExpired,
        setAddonChange,
        addonChange,
    } = useContext(OnBoardContext);

    let total = 0;
    let totalChanged = true;

    const [orderQuoteTotal, setOrderQuoteTotal] = useState(quoteTotal);

    let bePrice = {};

    let term = '';
    if (onboardInfo[0].isMonthly) {
        term = '[Monthly]';
    } else {
        term = '[Yearly]';
    }

    const calculateTotal = () => {
        total = 0;

        for (let index = 0; index < orderData['order_values'].length; index++) {
            const order = orderData['order_values'][index];

            if (order['price'] > 0 && order['type'] !== '[Extra]') {
                total += order['price'];
            }
        }

        totalChanged = !totalChanged;

        //
        //
    };

    useEffect(() => {
        calculateTotal();
    }, [orderData]);

    useEffect(() => {
        setQuoteTotal(total);
    }, [totalChanged]);

    useEffect(() => {}, [onboardInfo]);

    const query = new URLSearchParams(window.location.search);
    let qty = query.get('qty');

    if (/^\d+$/.test(qty) === false) {
        qty = 1;
    } else {
    }

    useEffect(() => {
        let planCode = onboardInfo[0]['planCode'];

        if (planCode !== 'cust-quote') {
            // fetch(`http://127.0.0.1:8000/quotes/all_prices`)
            fetch(`${process.env.REACT_APP_SERVER_URL}/quotes/all_prices`)
                // this will get any price related query from the back-end
                // we only need two types of order_values : Monthly/Yearly and Extra
                // once data has been fetched from the BE, the prices of each element will show accordingly
                // NOTE: This only applies to the SELF-ONBOARD process
                // fetch(`https://iqibge86aa.execute-api.us-east-2.amazonaws.com/api/quotes/all_prices`)
                .then((response) => response.json())
                .then((data) => {
                    bePrice = data;

                    const dataOnboard = onboardInfo;

                    let totalParam = 0;

                    for (let index = 0; index < dataOnboard[2]['order_values'].length; index++) {
                        //if order is of type [Monthly] or [Yearly], then get the key price based on the planCode chosen, also taking the term of Monthly/Yearly into account
                        if (
                            dataOnboard[2]['order_values'][index]['type'] === '[Monthly]' ||
                            dataOnboard[2]['order_values'][index]['type'] === '[Yearly]'
                        ) {
                            //check first if the qty parameter exists
                            //if it's the case, we set the data we need with the price and the appropriate quantity
                            //when we set totalOrder, we notify the app to recalculate the total accordingly at the same time
                            if (qty) {
                                dataOnboard[2]['order_values'][index]['price'] =
                                    bePrice[planCode][term];
                                dataOnboard[2]['order_values'][index]['quantity'] = parseInt(qty);
                                totalParam = bePrice[planCode][term] * parseInt(qty);

                                dataOnboard[2].totalOrder = totalParam;
                            } else {
                                //otherwise if the qty paramater does not exist, do like usual, which is calculate with the usual price and quantity
                                dataOnboard[2]['order_values'][index]['price'] =
                                    bePrice[planCode][term];
                                totalParam +=
                                    dataOnboard[2]['order_values'][index]['price'] *
                                    dataOnboard[2]['order_values'][index]['quantity'];
                            }
                        }

                        //if order is of type [Extra], then get the key price based on the full english name of the item
                        if (dataOnboard[2]['order_values'][index]['type'] === '[Extra]') {
                            dataOnboard[2]['order_values'][index]['price'] =
                                bePrice[dataOnboard[2]['order_values'][index]['name']][term];

                            //make sure to take into the sum the enabled extra add-ons
                            if (dataOnboard[2]['order_values'][index]['enabled'] === true) {
                                totalParam +=
                                    dataOnboard[2]['order_values'][index]['price'] *
                                    dataOnboard[2]['order_values'][index]['quantity'];
                            }
                        }

                        //if order is of type [Extra], then get the key price based on the full english name of the item
                        if (
                            dataOnboard[2]['order_values'][index]['price'] === -1 &&
                            dataOnboard[2]['order_values'][index].dependID
                        ) {
                            let depID = dataOnboard[2]['order_values'][index]?.dependID;
                            dataOnboard[2]['order_values'][index]['quantity'] =
                                dataOnboard[2]['order_values'][depID]['quantity'];
                        }
                    }

                    dataOnboard[2]['totalOrder'] = totalParam; //we have to change totalOrder based on the re-render and take into account the extras

                    setOnboardStatus('idle');
                });
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        const dataOnboard = onboardInfo;
        dataOnboard[1] = onboardInfo[1];

        if (dataOnboard[1] !== undefined) {
            //make sure first that dataOnboard[1] exists before we do anything else with it
            dataOnboard[1]['contact_email_lang'] = emailLang;
        }

        dataOnboard[2] = orderData;

        // onStageChange("quoteBillingInfo");//will change this later on

        if (onboardInfo[0]['planCode'] === 'cust-quote') {
            setQuoteInfo(onboardInfo);
            onStageChange('quoteBillingInfo');

            return;
        } else {
            onStageChange('billingInfo');
        }
    };

    const testAddonView = (dataOrders) => {
        let data = dataOrders;

        let arrExtra = [];

        let arrNoExtra = data[2]['order_values'];

        //take care of the extra items, put them all at the bottom of the array
        for (let index = 0; index < data[2]['order_values'].length; index++) {
            const order = data[2]['order_values'][index];
            if (order['name'].includes('[Extra]')) {
                arrExtra.push(order);

                arrNoExtra[index] = null;
            }
        }

        let newData = data;
        //add to the addon view and remove any extra nulls
        newData[2]['order_values'] = arrNoExtra.filter((n) => n).concat(arrExtra);

        //take care of the pbx array, put it after the first element
        for (let index = 0; index < newData[2]['order_values'].length; index++) {
            const order = newData[2]['order_values'][index];

            if (order['name'].includes('PBX')) {
                //swap with index=0
                swap(newData[2]['order_values'], index, 0);
            }
        }

        function swap(arr, from, to) {
            arr.splice(from, 1, arr.splice(to, 1, arr[from])[0]);
        }

        setOnBoardInfo(newData);
    };

    // if(reordered === false && onboardInfo[0]['planCode'] === 'cust-quote' && renderAmount < 1){
    if (reorderedVal === false && onboardInfo[0]['planCode'] === 'cust-quote') {
        // reorderAddonView();
        // setReordered(true);

        reorderedVal = true;
    } else {
    }

    calculateTotal();

    //reordering only applies to plancode
    if (onboardInfo[0]['planCode'] === 'cust-quote') {
        testAddonView(onboardInfo);
    }

    renderAmount++;

    if (!order)
        return (
            <div>
                <div className='order-text'>
                    <h3><FormattedMessage id='plan.not_found' /></h3>
                </div>
                <div className='btn-total'>
                    <div className='check-con'>
                        <button onClick={handleSubmit}>
                            <FormattedMessage id='order.continue' />
                        </button>
                    </div>
                </div>
            </div>
        );

    //make sure that the quote has been found, if not show error
    if (quoteNotFound === false)
        return (
            <>
                {quoteExpired && (
                    <p style={{marginTop: '3em'}}>
                        <FormattedMessage id='order.quote_expired' />{' '}
                        <FormattedMessage id='order.contact_sale' />
                    </p>
                )}
                <div>
                    {onboardInfo[0].planCode === 'cust-quote' && !quoteExpired && (
                        <ContactInfoView onStageChange={onStageChange} />
                    )}
                    {onboardInfo[0].planCode !== 'cust-quote' && (
                        <ContactOnboardInfoView onStageChange={onStageChange} />
                    )}
                    {onboardInfo[0]?.planCode ? (
                        <>
                            <PlanManager
                                type={onboardInfo[0]['planCode']}
                                term={term}
                                setOrderQuoteTotal={setOrderQuoteTotal}
                                setAddonChange={setAddonChange}
                                setQuoteTotal={setQuoteTotal}
                                addonChange={addonChange}
                            />
                        </>
                    ) : (
                        <>
                            <PlanManager
                                type={'cust-quote'}
                                term={term}
                                setOrderQuoteTotal={setOrderQuoteTotal}
                                setAddonChange={setAddonChange}
                                setQuoteTotal={setQuoteTotal}
                                addonChange={addonChange}
                            />
                        </>
                    )}

                    <div className='btn-total'>
                        <div className='total'>
                            <h1 style={{justifyContent: quoteExpired ? 'flex-start' : 'flex-end'}}>
                                <FormattedMessage id='order.sub_total' />:
                                <div className='price'>
                                    {
                                        onboardInfo[2]?.totalOrder ? (
                                            <>
                                                <span>
                                                    {currency['symbols'][onBoardCurrency]['symbol']}
                                                    {parseFloat(onboardInfo[2].totalOrder).toFixed(
                                                        2
                                                    )}
                                                </span>
                                            </>
                                        ) : (
                                            <>
                                                <span className='ord-subtotal'>
                                                    {currency['symbols'][onBoardCurrency]['symbol']}
                                                    {parseFloat(orderQuoteTotal).toFixed(2)}
                                                </span>
                                            </>
                                        )
                                        // :
                                        //     (
                                        //         <>
                                        //             <span className="ord-subtotal">{currency['symbols'][onBoardCurrency]['symbol']}{parseFloat(quoteTotal).toFixed(2)}</span>
                                        //         </>
                                        //     )
                                    }
                                    <span>{currency['symbols'][onBoardCurrency]['code']}</span>
                                </div>
                            </h1>
                        </div>

                        {
                            // onboardInfo[2]?.['order_values'].length !== 0  (
                            //display 'Pay Quote' button only when there is at least already one existing contact to the quote
                            onboardInfo[0].planCode === 'cust-quote' ? (
                                !onboardInfo[4]['contacts'].length > 0 ? ( //check if there is any contact to be added yet, if it's the case, we have to use the formik validation submit
                                    //formik, add handleSubmit
                                    <div className='check-con'>
                                        <button type='submit' form='form1'>
                                            <FormattedMessage id='order.pay_quote' />
                                        </button>
                                    </div>
                                ) : (
                                    //if at least one contact exists, we are not going to use formik validation (the related UI won't even display yet), just call handleSubmit instead
                                    //if the quote has expired, the user won't be able to go any further
                                    !quoteExpired && (
                                        <div className='check-con'>
                                            <button onClick={handleSubmit}>
                                                <FormattedMessage id='order.continue' />
                                            </button>
                                        </div>
                                    )
                                )
                            ) : (
                                //check first if fetching is done, the user cannot press on continue as long as the price fetching is not over
                                onboardStatus === 'idle' && (
                                    <div className='check-con'>
                                        <button onClick={handleSubmit}>
                                            <FormattedMessage id='order.continue' />
                                        </button>
                                    </div>
                                )
                            )
                        }
                    </div>
                </div>
            </>
        );
    else {
        return (
            <div className='quote-error'>
                <FormattedMessage id='page.quote_error' />
            </div>
        );
    }
};

export default OrderInfo;
