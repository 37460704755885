import React, {useContext} from 'react';
import {FormattedMessage} from 'react-intl';
import {OnBoardContext} from '../../contexts/onboardContext';

const AddonHeaderView = () => {
    const {onboardInfo} = useContext(OnBoardContext);
    const quoteType = onboardInfo[0].planCode;
    return (
        <div className={`add-on-con view header ${quoteType === 'cust-quote' ? 'quote' : ''}`}>
            <div
                className={`name-view-bill ${quoteType === 'cust-quote' ? 'quote' : ''}`}
                style={{fontSize: '17px'}}
            >
                Item
            </div>
            <div className={`qty-view-bill ${quoteType === 'cust-quote' ? 'quote' : ''}`}>
                <div>
                    {/* Quantity */}
                    <FormattedMessage id={`billing.item_qty`} />
                </div>
            </div>
            <div className={`unit-prc-view-bill ${quoteType === 'cust-quote' ? 'quote' : ''}`}>
                <div>
                    <span>
                        <FormattedMessage id='billing.item_unitPrice' />
                    </span>
                </div>
            </div>
            <div className={`prc-view-bill ${quoteType === 'cust-quote' ? 'quote' : ''}`}>
                {/* Item total */}
                <FormattedMessage id='billing.item_total' />
            </div>
        </div>
    );
};

export default AddonHeaderView;
