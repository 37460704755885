import React, {useState, useContext} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import './App.css';
import BusinessPlanPage from './pages/businessPlanPage';
import PaymentPage from './pages/paymentPage';
import AfterPaymentPage from './pages/afterPaymentPage';
import {IntlProvider} from 'react-intl';
import {OnBoardContext} from './contexts/onboardContext';
import messages_fr from './translations/fr.json';
import messages_en from './translations/en.json';
import logo from './assets/image/logo.png';
import {FormattedMessage} from 'react-intl';

// localization //
const messages = {
    fr: messages_fr,
    en: messages_en,
};

var default_language = 'en';
var language = default_language;
var queryString = new URLSearchParams(window.location.search);

if (queryString.get('lang')) {
    // first get language from query string
    language = queryString.get('lang');
} else if (sessionStorage.getItem('language')) {
    // the check for language in session
    language = sessionStorage.getItem('language');
} else {
    // finally get language from browser
    language =
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage ||
        'en';
    // language without region code
    language = language.split(/[-_]/)[0];
}
// make sure that detected language is either french or english. If not, set to default
language = language === 'en' || language === 'fr' ? language : default_language;
sessionStorage.setItem('language', language);

let langSwitched = 0;

function App() {
    const [langChange, setLangChange] = useState(language);

    const {pageLang, setPageLang} = useContext(OnBoardContext);

    const handleLang = (e) => {
        

        langSwitched++;

        setLangChange(e.target.value);
        setPageLang(e.target.value);
    };

    
    

    const query = new URLSearchParams(window.location.search);

    let token = query.get('lang');

    

    //
    //

    if (token !== null) {
        
        

        //token can only be one of two values - either fr or en. If it's none of those then we use the  default_language
        if (token !== 'fr' && token !== 'en') {
            token = default_language;
            
        }

        
        language = token;
    } else {
        
        language = 'en';
    }

    
    
    

    return (
        //On first render of this component, use the default language, on any other renders, use the pageLang state as locale's value
        <IntlProvider
            locale={langSwitched < 1 ? language : pageLang}
            messages={langSwitched < 1 ? messages[language] : messages[pageLang]}
        >
            {/* <IntlProvider locale= {render === true ? pageLang : language } messages={render === true ? messages[pageLang] : messages[language]} > */}
            <div className='App' style={{backgroundColor: 'white'}}>
                <nav className='onb-nav'>
                    <ul className='nav-link-list'>
                        <li>
                            <a
                                className='onb-nav-link'
                                href='https://support.croo.io/'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                SUPPORT
                            </a>
                        </li>
                        <li>
                            <a className='onb-nav-link' href='tel:+1855-936-6326'>
                                <FormattedMessage id='nav.call' />: 1 (855) 936-6326
                            </a>
                        </li>
                    </ul>
                </nav>

                <div className='lng-choice'>
                    <img className='logo' src={logo} alt='company logo' />

                    <select className='btn-box' onChange={handleLang}>
                        {/* Two use cases on how the user lands on the business plan page:
                        1. User comes directly to the page, no token to setup. default language is EN
                        2. User comes where a token with lang exists. In this case, the language to be set will be based on the parameter set for lang=, the state called pageLang has to be used
                    */}

                        {/* <>
                        <option value='en'>EN</option>
                        <option value='fr'>FR</option>
                    </>  */}

                        {
                            //  we use langSwitched as a benchmark for displaying the button language order, no matter the use case from which the user lands on the business page,
                            //  langSwitched will always be 0 at first. Because of that, we always have to use token the first time the business plan page is displayed.
                            //  If langSwitched is >= 1, the user has interacted with the language switcher at least once, we have to use pageLang as a benchmark instead

                            langSwitched < 1 ? (
                                <>
                                    {token === null && (
                                        <>
                                            <option value='en'>EN</option>
                                            <option value='fr'>FR</option>
                                        </>
                                    )}
                                    {token === 'en' && (
                                        <>
                                            <option value='en'>EN</option>
                                            <option value='fr'>FR</option>
                                        </>
                                    )}
                                    {token === 'fr' && (
                                        <>
                                            <option value='fr'>FR</option>
                                            <option value='en'>EN</option>
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {pageLang === 'en' && (
                                        <>
                                            <option value='en'>EN</option>
                                            <option value='fr'>FR</option>
                                        </>
                                    )}
                                    {pageLang === 'fr' && (
                                        <>
                                            <option value='fr'>FR</option>
                                            <option value='en'>EN</option>
                                        </>
                                    )}
                                </>
                            )
                        }
                    </select>
                </div>
                <BrowserRouter>
                    {/* <HeaderComponent /> */}
                    <Switch>
                        <Route exact path='/' render={(props) => <BusinessPlanPage {...props} />} />
                        <Route exact path='/quote' render={(props) => <PaymentPage {...props} />} />
                        <Route
                            exact
                            path='/quote/:id'
                            render={(props) => <PaymentPage {...props} />}
                        />
                        <Route
                            exact
                            path='/thankyou'
                            render={(props) => <AfterPaymentPage {...props} />}
                        />

                        <Route render={(props) => <p>Page not found.</p>} />
                        {/* <Route exact path="/afterPaymentComponent" render={props =><AfterPaymentComponent {...props} />} /> */}
                    </Switch>
                </BrowserRouter>
            </div>

            <footer>
                <FormattedMessage id='nav.copyright' />
            </footer>
        </IntlProvider>
    );
}

export default App;
