import React, {useContext, useState, useEffect} from 'react';
import AddonExtra from './addonExtra';
import AddonStandard from './addonStandard';
import AddonPlan from './addonPlan';
import AddonHeader from './addonHeader';
import AddonCustomQuote from './addonCustomQuote';
import {OnBoardContext} from '../../contexts/onboardContext';
import {FormattedMessage, useIntl} from 'react-intl';

const ITEMS = {
    'Cloud PBX - Essentials': 'plan.pbx_ess',
    'Cloud PBX - Enterprise': 'plan.pbx_ent',
    'Cloud PBX - Professional': 'plan.pbx_pro',
    'Croo Talk - Essentials': 'plan.talk_ess_x',
    'Croo Talk - Professional': 'plan.talk_pro_x',
    'Croo Talk - Enterprise': 'plan.talk_ent_x',
    'DID / Canada - USA': 'plan.did_nam',
    'Hard Drive / 10 GB': 'plan.hd_10',
    'Hard Drive / 100 GB': 'plan.hd_100',
    'Hard Drive / 1000 GB': 'plan.hd_1000',
    '1 (8XX) Numbers / Canada - USA': 'plan.1_num',
    'International Phone Number': 'plan.did_inter',
    'Text Messages': 'plan.text_msg',
};

const PlanManager = ({type, term, setOrderQuoteTotal, setAddonChange, addonChange}) => {
    const {
        onboardInfo,
        orderData,
        // addonChange,
        // setAddonChange,
        setQuoteInfo,
        pageLang,
        onBoardCurrency,
    } = useContext(OnBoardContext);

    const intl = useIntl();

    const [langUpdate, setLangUpdate] = useState(true);

    let quoteAccess = true;

    useEffect(() => {
        setLangUpdate(!langUpdate);
    }, [addonChange, pageLang]);

    useEffect(() => {
        setQuoteInfo(onboardInfo);
    }, [quoteAccess]);

    if (onboardInfo[2] === undefined) {
        //unitPrice = -1 : no price to display, should show 'Included' instead. if > 0 then it will show the price.
        //baseQuantity = 1 : default qty to display. If this property has not been set ,then no 'QTY' should be displayed on the UI.
        //editable = boolean : if this property is set, then it makes the QTY input editable or not on the UI. This property SHOULD exist only IF a baseQuantity property exists, meaning that there is a 'QTY' displayed on the UI
        //addonID = 1: index of the add-on, this property should exist at ALL times
        if (type === 'talk-ess-x') {
            return (
                <>
                    {/* placeholder has to be cast into string before we send it to the AddonStandard Component  */}
                    {/* The only way to make intl work for Add-ons:
                    1. Pass the addonName prop as its intl text
                    2. Use FormattedMessage directly inside the Addon component 
                    */}
                    {/* ESSENTIAL                     */}
                    <AddonPlan
                        addonName={'Croo Talk - Essentials'}
                        itemName={'plan.talk_ess_x'}
                        unitPrice={22}
                        editable={true}
                        baseQuantity={1}
                        addonID={0}
                        addonTerm={term}
                        currencyCode={onBoardCurrency}
                        setAddonChange={setAddonChange}
                        addonChange={addonChange}
                    />
                    <AddonStandard
                        addonName={'Cloud PBX - Essentials'}
                        itemName={'plan.pbx_ess'}
                        unitPrice={-1}
                        editable={false}
                        baseQuantity={1}
                        addonID={1}
                        dependID={0}
                        currencyCode={onBoardCurrency}
                        addonChange={addonChange}
                        setAddonChange={setAddonChange}
                    />
                    <AddonStandard
                        addonName={'DID / Canada - USA'}
                        itemName={'plan.did_nam'}
                        unitPrice={-1}
                        editable={false}
                        baseQuantity={1}
                        addonID={2}
                        dependID={0}
                        currencyCode={onBoardCurrency}
                        addonChange={addonChange}
                        setAddonChange={setAddonChange}
                    />
                    <AddonStandard
                        addonName={'Hard Drive / 100 GB'}
                        itemName={'plan.hd_100'}
                        unitPrice={-1}
                        editable={false}
                        baseQuantity={1}
                        addonID={3}
                        currencyCode={onBoardCurrency}
                    />
                    <AddonStandard
                        addonName={'Text Messages'}
                        itemName={'plan.text_msg'}
                        unitPrice={-1}
                        editable={false}
                        baseQuantity={1}
                        addonID={4}
                        currencyCode={onBoardCurrency}
                    />
                    <AddonExtra
                        addonName={'DID / Canada - USA'}
                        itemName={'plan.did_nam'}
                        unitPrice={4}
                        baseQuantity={1}
                        addonID={5}
                        enabled={false}
                        currencyCode={onBoardCurrency}
                        setOrderQuoteTotal={setOrderQuoteTotal}
                    />
                    <AddonExtra
                        addonName={'Hard Drive / 100 GB'}
                        itemName={'plan.hd_100'}
                        unitPrice={4}
                        baseQuantity={1}
                        addonID={6}
                        enabled={false}
                        currencyCode={onBoardCurrency}
                    />
                    <AddonExtra
                        addonName={'1 (8XX) Numbers / Canada - USA'}
                        itemName={'plan.1_num'}
                        unitPrice={4}
                        baseQuantity={1}
                        addonID={7}
                        enabled={false}
                        currencyCode={onBoardCurrency}
                    />
                    <AddonExtra
                        addonName={'Hard Drive / 1000 GB'}
                        itemName={'plan.hd_1000'}
                        unitPrice={120}
                        baseQuantity={1}
                        addonID={8}
                        enabled={false}
                        currencyCode={onBoardCurrency}
                    />
                    <AddonExtra
                        addonName={'International Phone Number'}
                        itemName={'plan.did_inter'}
                        unitPrice={40}
                        baseQuantity={1}
                        addonID={9}
                        enabled={false}
                        currencyCode={onBoardCurrency}
                    />
                </>
            );
        } else if (type === 'talk-pro-x') {
            return (
                <>
                    {/* <div>PROFESSIONAL</div> */}
                    <AddonPlan
                        addonName={'Croo Talk - Professional'}
                        itemName={'plan.talk_pro_x'}
                        unitPrice={22}
                        editable={true}
                        baseQuantity={1}
                        addonID={0}
                        addonTerm={term}
                        currencyCode={onBoardCurrency}
                        setAddonChange={setAddonChange}
                        addonChange={addonChange}
                    />
                    <AddonStandard
                        addonName={'Cloud PBX - Professional'}
                        itemName={'plan.pbx_pro'}
                        unitPrice={-1}
                        editable={false}
                        baseQuantity={1}
                        addonID={1}
                        dependID={0}
                        currencyCode={onBoardCurrency}
                        addonChange={addonChange}
                        setAddonChange={setAddonChange}
                    />
                    <AddonStandard
                        addonName={'DID / Canada - USA'}
                        itemName={'plan.did_nam'}
                        unitPrice={-1}
                        editable={false}
                        baseQuantity={1}
                        addonID={2}
                        dependID={0}
                        currencyCode={onBoardCurrency}
                        addonChange={addonChange}
                        setAddonChange={setAddonChange}
                    />
                    <AddonStandard
                        addonName={'Hard Drive / 100 GB'}
                        itemName={'plan.hd_100'}
                        unitPrice={-1}
                        editable={false}
                        baseQuantity={1}
                        addonID={3}
                        currencyCode={onBoardCurrency}
                    />
                    <AddonStandard
                        addonName={'Text Messages'}
                        itemName={'plan.text_msg'}
                        unitPrice={-1}
                        editable={false}
                        baseQuantity={1}
                        addonID={4}
                        currencyCode={onBoardCurrency}
                    />
                    <AddonExtra
                        addonName={'DID / Canada - USA'}
                        itemName={'plan.did_nam'}
                        unitPrice={4}
                        baseQuantity={1}
                        addonID={5}
                        enabled={false}
                        currencyCode={onBoardCurrency}
                    />
                    <AddonExtra
                        addonName={'1 (8XX) Numbers / Canada - USA'}
                        itemName={'plan.1_num'}
                        unitPrice={4}
                        baseQuantity={1}
                        addonID={6}
                        enabled={false}
                        currencyCode={onBoardCurrency}
                    />
                    <AddonExtra
                        addonName={'Hard Drive / 1000 GB'}
                        itemName={'plan.hd_1000'}
                        unitPrice={120}
                        baseQuantity={1}
                        addonID={7}
                        enabled={false}
                        currencyCode={onBoardCurrency}
                    />
                    <AddonExtra
                        addonName={'International Phone Number'}
                        itemName={'plan.did_inter'}
                        unitPrice={40}
                        baseQuantity={1}
                        addonID={8}
                        enabled={false}
                        currencyCode={onBoardCurrency}
                    />
                </>
            );
        } else if (type === 'talk-ent-x') {
            return (
                <>
                    {/* <div>ENTERPRISE</div>  */}
                    <AddonPlan
                        addonName={'Croo Talk - Enterprise'}
                        itemName={'plan.talk_ent_x'}
                        unitPrice={22}
                        editable={true}
                        baseQuantity={1}
                        addonID={0}
                        addonTerm={term}
                        currencyCode={onBoardCurrency}
                        setAddonChange={setAddonChange}
                        addonChange={addonChange}
                    />
                    <AddonStandard
                        addonName={'Cloud PBX - Enterprise'}
                        itemName={'plan.pbx_ent'}
                        unitPrice={-1}
                        editable={false}
                        baseQuantity={1}
                        addonID={1}
                        dependID={0}
                        currencyCode={onBoardCurrency}
                        addonChange={addonChange}
                        setAddonChange={setAddonChange}
                    />
                    <AddonStandard
                        addonName={'DID / Canada - USA'}
                        itemName={'plan.did_nam'}
                        unitPrice={-1}
                        editable={false}
                        baseQuantity={1}
                        addonID={2}
                        dependID={0}
                        currencyCode={onBoardCurrency}
                        addonChange={addonChange}
                        setAddonChange={setAddonChange}
                    />

                    {/* The item name and add-on name have to match, e.g: see below, otherwise the qty column won't show up for that add-on , also make sure all options are available, e.g. editable*/}
                    <AddonStandard
                        addonName={'Hard Drive / 100 GB'}
                        itemName={'plan.hd_100'}
                        unitPrice={-1}
                        editable={false}
                        baseQuantity={1}
                        addonID={3}
                        currencyCode={onBoardCurrency}
                    />
                    <AddonStandard
                        addonName={'Text Messages'}
                        itemName={'plan.text_msg'}
                        unitPrice={-1}
                        editable={false}
                        baseQuantity={1}
                        addonID={4}
                        currencyCode={onBoardCurrency}
                    />
                    <AddonExtra
                        addonName={'DID / Canada - USA'}
                        itemName={'plan.did_nam'}
                        unitPrice={4}
                        baseQuantity={1}
                        addonID={5}
                        enabled={false}
                        currencyCode={onBoardCurrency}
                    />
                    <AddonExtra
                        addonName={'1 (8XX) Numbers / Canada - USA'}
                        itemName={'plan.1_num'}
                        unitPrice={4}
                        baseQuantity={1}
                        addonID={6}
                        enabled={false}
                        currencyCode={onBoardCurrency}
                    />
                    <AddonExtra
                        addonName={'Hard Drive / 1000 GB'}
                        itemName={'plan.hd_1000'}
                        unitPrice={120}
                        baseQuantity={1}
                        addonID={7}
                        enabled={false}
                        currencyCode={onBoardCurrency}
                    />
                    <AddonExtra
                        addonName={'International Phone Number'}
                        itemName={'plan.did_inter'}
                        unitPrice={40}
                        baseQuantity={1}
                        addonID={8}
                        enabled={false}
                        currencyCode={onBoardCurrency}
                    />
                </>
            );
        } else {
            return (
                <>
                    <div>Add-on error. Please contact your administrator.</div>
                    <div>Type of Add-on: {type}</div>
                </>
            );
        }
    } else if (type === 'cust-quote') {
        quoteAccess = !quoteAccess;

        return (
            <>
                <AddonHeader type={type} />
                {onboardInfo[2]['order_values'].map((order, index) => (
                    <AddonCustomQuote
                        addonName={order['name']}
                        price={order['price'] * order['quantity']}
                        unitPrice={Number(order['price'])}
                        quantity={order['quantity']}
                        discount={order['discount']}
                        discountPercent={order['hs_discount_percentage']}
                        currencyCode={onBoardCurrency}
                        key={index}
                        itemType={order['type']}
                    />
                ))}
            </>
        );
    } else {
        //THIS IS WHERE SELF-ONBOARD GOES INTO
        console.log('qty- planManager: ', onboardInfo[2]['order_values']);

        return (
            <>
                <AddonHeader />
                {onboardInfo[2]['order_values'].map((item, index) => {
                    return item['type'] == '[Standard]' ? ( //check the type of add-on first, then display the item depending on the other properties
                        <AddonStandard
                            addonName={item['name']}
                            itemName={`${ITEMS[item['name'].replace('[Standard] ', '')]}`}
                            unitPrice={item['price']}
                            editable={item['editable']}
                            baseQuantity={item['quantity']}
                            addonID={index}
                            dependID={item['dependID']}
                            key={index}
                            currencyCode={onBoardCurrency}
                            addonChange={addonChange}
                            setAddonChange={setAddonChange}
                        />
                    ) : item['type'] == '[Extra]' ? (
                        <AddonExtra
                            addonName={item['name']}
                            itemName={`${ITEMS[item['name'].replace('[Extra] ', '')]}`}
                            unitPrice={item['price']}
                            baseQuantity={item['quantity']}
                            addonID={index}
                            key={index}
                            enabled={item['enabled']}
                            currencyCode={onBoardCurrency}
                            setOrderQuoteTotal={setOrderQuoteTotal}
                        />
                    ) : (
                        <AddonPlan
                            addonName={item['name']}
                            itemName={`${ITEMS[item['name'].replace(`${term} `, '')]}`}
                            unitPrice={item['price']}
                            editable={item['editable']}
                            baseQuantity={item['quantity']}
                            addonID={index}
                            addonTerm={term}
                            key={index}
                            enabled={item['enabled']}
                            currencyCode={onBoardCurrency}
                            setAddonChange={setAddonChange}
                            addonChange={addonChange}
                            setOrderQuoteTotal={setOrderQuoteTotal}
                        />
                    );
                })}
            </>
        );
    }
};

export default PlanManager;
