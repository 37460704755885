import React, {useState, useContext} from 'react';
import {FormattedMessage} from 'react-intl';
import PaymentHeader from '../components/payment/paymentHeader';
import ContactInfo from '../components/payment/contactInfo';
import OrderInfo from '../components/payment/orderInfo';
import BillingInfo from '../components/payment/billingInfo';
import QuoteBillingInfo from '../components/payment/quoteBillingInfo';
import AfterPaymentPage from '../pages/afterPaymentPage';
import {OnBoardContext} from '../contexts/onboardContext';

// const signUpAPIUrl = 'https://api.signup.croo.io/quotes/';
// const proxyurl = 'https://cors-anywhere.herokuapp.com/';
const PAYMENT_STAGES = ['contactInfo', 'orderInfo', 'billingInfo'];

const getPaymentStageKey = (currentStage) => {
    for (let i = 0; i < PAYMENT_STAGES.length; i++)
        if (PAYMENT_STAGES[i] === currentStage) return i;
    return undefined;
};

const advancePaymentStages = (currentStage, setPaymentStage) => {
    const currentStageKey = getPaymentStageKey(currentStage);
    if (currentStageKey !== undefined) {
        if (currentStageKey < 2) setPaymentStage(PAYMENT_STAGES[currentStageKey + 1]);
        // else change location
    } else {
        alert('An error has occurred ' + currentStageKey);
        setPaymentStage(PAYMENT_STAGES[0]);
    }
};

let quoteStageVisited = 0;

const PaymentPage = () => {
    const [plan, setPlan] = useState();
    const [contact, setContact] = useState();
    const [order, setOrder] = useState({
        totalOrder: 0,
        order_values: [],
    });

    const {onboardInfo, quoteInfo, paymentStage, setPaymentStage} = useContext(OnBoardContext);

    const getStageIndex = (stageName) => {
        //simplefunction that converts stage name from paymentStage into an index number
        switch (stageName) {
            case 'contactInfo':
                return 0;
            case 'orderInfo':
                return 1;
            case 'billingInfo':
                return 2;
            case 'quoteBillingInfo':
                return 2;
            case 'paymentPage':
                return 3;
            default:
                return undefined;
        }
    };
    const handleOnSubmit = (submittedObj) => {
        // do something with submittedObj
        if (submittedObj) {
            const dataOnboard = onboardInfo;
            // dataOnboard.push(submittedObj);

            dataOnboard[getStageIndex(paymentStage) + 1] = submittedObj;

            // setOnBoardInfo(dataOnboard);
        }
        advancePaymentStages(paymentStage, setPaymentStage);
    };

    const PaymentStage = () => {
        switch (paymentStage) {
            case 'contactInfo':
                return (
                    <ContactInfo
                        contact={contact}
                        onSubmit={handleOnSubmit}
                        paymentStage={paymentStage}
                        onStageChange={setPaymentStage}
                    />
                );
            case 'orderInfo':
                if (quoteStageVisited < 1) {
                    return (
                        <OrderInfo
                            order={order}
                            onSubmit={handleOnSubmit}
                            onStageChange={setPaymentStage}
                        />
                    );
                } else {
                    return (
                        <QuoteBillingInfo
                            order={order}
                            paymentStage={paymentStage}
                            onStageChange={setPaymentStage}
                            quoteInfo={quoteInfo}
                        />
                    );
                }
            case 'billingInfo':
                return (
                    <BillingInfo
                        order={order}
                        onSubmit={handleOnSubmit}
                        paymentStage={paymentStage}
                        onStageChange={setPaymentStage}
                        quoteInfo={quoteInfo}
                    />
                );
            case 'quoteBillingInfo':
                quoteStageVisited++;
                return (
                    <QuoteBillingInfo
                        order={order}
                        paymentStage={paymentStage}
                        onStageChange={setPaymentStage}
                        quoteInfo={quoteInfo}
                    />
                );
            case 'paymentPage':
                return <AfterPaymentPage />;
            default:
                return <div>Payment Stage Error</div>;
        }
    };

    // make sure that there is always a plan title to show whether the user comes from self-onboard or hubspot quote
    // this is the use case for a hubspot quote
    let planTitle = '';

    if (onboardInfo[0]?.['planCode'] === 'cust-quote') {
        onboardInfo[2]['order_values'].forEach((order) => {
            if (order?.['name']?.includes('Croo Talk')) {
                //change planTitle only if we find the type of plan used
                planTitle = `Croo Talk - ${order['name'].split(' - ')[1]}`;
            }
        });
    }

    return (
        <div>
            <div className='container'>
                <div className='head-text'>
                    <h1>
                        <i className='fas fa-shield-alt'></i>{' '}
                        <FormattedMessage id='payment.title' />
                    </h1>
                    <p>{plan?.name}</p>
                    <p></p>
                    {onboardInfo[0]?.['planCode'] === 'talk-ess-x' && (
                        <span className='plan-title'>
                            {' '}
                            <FormattedMessage id='plan.talk_ess_x' />{' '}
                        </span>
                    )}
                    {onboardInfo[0]?.['planCode'] === 'talk-pro-x' && (
                        <span className='plan-title'>
                            {' '}
                            <FormattedMessage id='plan.talk_pro_x' />{' '}
                        </span>
                    )}
                    {onboardInfo[0]?.['planCode'] === 'talk-ent-x' && (
                        <span className='plan-title'>
                            {' '}
                            <FormattedMessage id='plan.talk_ent_x' />{' '}
                        </span>
                    )}

                    {/* display planTitle only if user comes from hubspot quote */}
                    {planTitle !== '' && <span className='plan-title'> {planTitle} </span>}

                    {onboardInfo[0]?.['isMonthly'] ? (
                        <div>
                            <FormattedMessage id='plan.monthly' />
                        </div>
                    ) : (
                        <div>
                            <FormattedMessage id='plan.yearly' />
                        </div>
                    )}
                </div>
            </div>
            <div className='container'>
                <div
                    className='white-box-1'
                    style={{
                        maxWidth:
                            paymentStage === 'billingInfo' || paymentStage === 'orderInfo'
                                ? '1155px'
                                : '620px',
                    }}
                >
                    <PaymentHeader paymentStage={paymentStage} onStageChange={setPaymentStage} />
                    <PaymentStage />
                </div>
            </div>
        </div>
    );
};

export default PaymentPage;
