import React, {useContext} from 'react';
import {OnBoardContext} from '../contexts/onboardContext';
import '../../src/assets/css/afterPayment.css';
import {FormattedMessage} from 'react-intl';

const AfterPaymentPage = () => {
    const {onboardInfo} = useContext(OnBoardContext);

    const query = new URLSearchParams(window.location.search);

    let token = query.get('lang');

    return (
        <>
            <div className='container ty'>
                <div className='head-text m-50'>
                    <div className='thk-you'>
                        <FormattedMessage id='thank_you.thk-you' />
                    </div>
                    <h1>
                        <FormattedMessage id='thank_you.h1' />
                    </h1>
                    <p>
                        <FormattedMessage id='thank_you.p' />
                    </p>

                    {token === 'fr' && (
                        <div
                            className='calendly-inline-widget'
                            data-url='https://calendly.com/service-delivery/rencontre-introduction'
                            style={{minWidth: '320px', height: '630px'}}
                        ></div>
                    )}
                    {token === 'en' && (
                        <div
                            className='calendly-inline-widget'
                            data-url='https://calendly.com/service-delivery/introduction-meeting'
                            style={{minWidth: '320px', height: '630px'}}
                        ></div>
                    )}
                </div>
            </div>
        </>
    );
};

export default AfterPaymentPage;
