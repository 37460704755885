import React, {useState, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {OnBoardContext} from '../contexts/onboardContext';
import '../../src/assets/css/businessPlan.css';
import currency from '../currency/currency.json';
import {useLocation} from 'react-router-dom';
import {useHistory} from 'react-router-dom';

const NB_OF_USERS = ['1-4', '5-24', '25-49', '50-99', '100+'];

const PLANS = [
    {
        name: 'essentials',
        code: 'talk-ess-x',
        price: 100,

        plan: [
            {
                // '1-4' :{ price: 80 },
                '1-4': {price: 21},
                '5-24': {price: 20},
                '25-49': {price: 17},
                '50-99': {price: 15},
                100: {price: 'Get a quote'},
            },
            {
                // '1-4' :{ price: 100 },
                '1-4': {price: 25},
                '5-24': {price: 25},
                '25-49': {price: 22},
                '50-99': {price: 20},
                100: {price: 'Get a quote'},
            },
        ],
    },
    {
        name: 'professional',
        code: 'talk-pro-x',
        price: 100,

        plan: [
            {
                // '1-4' :{ price: 100 },
                '1-4': {price: 25},
                '5-24': {price: 25},
                '25-49': {price: 22},
                '50-99': {price: 20},
                100: {price: 'Get a quote'},
            },
            {
                '1-4': {price: 30},
                '5-24': {price: 30},
                '25-49': {price: 27},
                '50-99': {price: 25},
                100: {price: 'Get a quote'},
            },
        ],
    },
    {
        name: 'enterprise',
        code: 'talk-ent-x',
        price: 140,

        plan: [
            {
                '1-4': {price: 34},
                '5-24': {price: 35},
                '25-49': {price: 32},
                '50-99': {price: 30},
                100: {price: 'Get a quote'},
            },
            {
                '1-4': {price: 40},
                '5-24': {price: 40},
                '25-49': {price: 37},
                '50-99': {price: 35},
                100: {price: 'Get a quote'},
            },
        ],
    },

    {
        name: 'insurance',
        code: 'talk-ins-x',
        price: 140,

        plan: [
            {
                '1-4': {price: <FormattedMessage id='plan.get_quote' />},
                '5-24': {price: 'Get a quote'},
                '25-49': {price: 'Get a quote'},
                '50-99': {price: 'Get a quote'},
                100: {price: 'Get a quote'},
            },
            {
                '1-4': {price: <FormattedMessage id='plan.get_quote' />},
                '5-24': {price: 'Get a quote'},
                '25-49': {price: 'Get a quote'},
                '50-99': {price: 'Get a quote'},
                100: {price: 'Get a quote'},
            },
        ],
    },
];

const BusinessPlanPage = (props) => {
    const [isMonthly, setIsMonthly] = useState(false);
    const [nbOfUsers, setNbOfUsers] = useState('1-4');
    const [langChange, setLangChange] = useState(false);
    const location = useLocation();

    const {onboardInfo, setOnBoardInfo, setPageLang, onBoardCurrency, setOnboardCurrency} =
        useContext(OnBoardContext);

    const toggleMonthly = () => {
        setIsMonthly(!isMonthly);
    };

    const getIsActive2 = (amountOfUsers) => {
        if (amountOfUsers === nbOfUsers) return 'active-2';
        return '';
    };

    const getIsActive3 = (amountOfUsers) => {
        if (amountOfUsers === nbOfUsers) return 'active-3';
        return '';
    };

    const getParentCurrency = (countryCode) => {
        let result = 'USD';

        for (let index = 0; index < currency['countries']['country'].length; index++) {
            const country = currency['countries']['country'][index];

            if (country['countryCode'] === countryCode) {
                if (country?.['continentName'] === 'Europe') {
                    result = 'EUR';
                } else if (country?.['countryName'] === 'US') {
                    result = 'USD';
                } else if (country?.['countryName'] === 'Canada') {
                    result = 'CAD';
                } else {
                    result = 'USD';
                }
            }
        }

        return result;
    };

    const history = useHistory();
    // const removeQuery = (...queryNames) => {

    //     history.push('/quote/?q=talk-pro-x');
    //   };

    useEffect(() => {
        /*******
         * Find the country code of the user
         */

        const query = new URLSearchParams(window.location.search);

        const queryParams = new URLSearchParams(location.search);

        if (queryParams.has('n')) {
        }

        let token = query.get('currency');

        if (!token) {
            //FIRST ATTEMPT: use geolocation-db API to get the location of the user
            fetch(`https://geolocation-db.com/json/d720c370-f16d-11eb-ae06-4167033db18b`)
                .then((response) => response.json())
                .then((data) => {
                    // compare against the local country, change countryFound value if found, END OF FIRST ATTEMPT
                    let countryFound = false;
                    for (let index = 0; index < currency['countries']['country'].length; index++) {
                        const country = currency['countries']['country'][index];

                        if (country['countryCode'] === data?.['country_code']) {
                            countryFound = true;

                            if (
                                country['currencyCode'] !== 'USD' &&
                                country['currencyCode'] !== 'CAD' &&
                                country['currencyCode'] !== 'EUR'
                            ) {
                                let currencyToUse = getParentCurrency(country['countryCode']);
                                setOnboardCurrency(currencyToUse);
                            } else {
                                setOnboardCurrency(country['currencyCode']);
                            }
                        }
                    }
                    //SECOND ATTEMPT: country has not been found yet, use positionstack API to get the location of the user (based on long lat values)
                    if (!countryFound) {
                        fetch(
                            `http://api.positionstack.com/v1/reverse?access_key=a33c8fed3d4b18391670644365918fa9&query=${data['longitude']},${data['latitude']}`
                        )
                            .then((response) => response.json())
                            .then((data2) => {
                                // compare against the local country, change countryFound value if found, END OF SECOND ATTEMPT
                                for (
                                    let index = 0;
                                    index < currency['countries']['country'].length;
                                    index++
                                ) {
                                    const country = currency['countries']['country'][index];

                                    if (
                                        country['countryCode'] ===
                                        data2['data'][0]?.['country_code']
                                    ) {
                                        countryFound = true;

                                        if (
                                            country['currencyCode'] !== 'USD' &&
                                            country['currencyCode'] !== 'CAD' &&
                                            country['currencyCode'] !== 'EUR'
                                        ) {
                                            let currencyToUse = getParentCurrency(
                                                country['countryCode']
                                            );
                                            setOnboardCurrency(currencyToUse);
                                        } else {
                                            setOnboardCurrency(country['currencyCode']);
                                        }
                                    }
                                }
                                // if country of user still has not been found after 2nd attempt, give up
                                if (!countryFound) {
                                    setOnboardCurrency('USD');
                                }
                            });
                    }
                });
        } else {
            token = token.toUpperCase();

            if (token !== 'USD' && token !== 'CAD' && token !== 'EUR') {
                setOnboardCurrency('USD');
            } else {
                setOnboardCurrency(token);
            }
        }
    }, []);

    useEffect(() => {
        //monthly changed, might be needed in future
    }, [isMonthly]);

    //create your forceUpdate hook
    const useForceUpdate = () => {
        const [value, setValue] = useState(0); // integer state
        return () => setValue((value) => value + 1); // update the state to force render
    };

    const intl = useIntl();

    const handleCurrency = () => {
        setOnboardCurrency('USD');
    };

    return (
        <div className='demo'>
            <div className='container'>
                <div className='head-text'>
                    <h1>
                        <FormattedMessage id='plan.title' />
                    </h1>
                    <p>
                        <FormattedMessage id='plan.sub_title' />
                    </p>
                </div>
            </div>
            <div className='container'>
                <div className='white-box'>
                    <div className='row togg-btn-sec'>
                        <div className='col-md-12 col-lg-4'>
                            {/* Add fr style class ONLY if chosen language is french */}
                            <div
                                className={'btn-box' + (intl.locale === 'fr' ? ' fr' : '')}
                                onClick={toggleMonthly}
                            >
                                <h1 className={`${isMonthly ? '' : 'active-1'}`}>
                                    <FormattedMessage id='plan.pay_annually' />
                                </h1>
                                <h1 className={`${isMonthly ? 'active-1' : ''}`}>
                                    <FormattedMessage id='plan.pay_monthly' />
                                </h1>
                            </div>

                            {isMonthly ? (
                                <p className='tetx' style={{opacity: 0}}>
                                    ""
                                </p>
                            ) : (
                                <p className='tetx'>
                                    (<FormattedMessage id='plan.save' />)
                                </p>
                            )}
                        </div>
                        <div className='col-md-12 col-lg-8 num-of user'>
                            <h2>
                                <FormattedMessage id='plan.nbr_users' />
                            </h2>
                            <div className='main-num'>
                                <div className='num-range'>
                                    <ul>
                                        {NB_OF_USERS.map((amountOfUsers) => (
                                            <li
                                                key={amountOfUsers}
                                                onClick={() => {
                                                    setNbOfUsers(amountOfUsers);
                                                }}
                                            >
                                                <div className={getIsActive2(amountOfUsers)} />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className='num-range-1'>
                                    <ul>
                                        {NB_OF_USERS.map((amountOfUsers) => (
                                            <li
                                                className={getIsActive3(amountOfUsers)}
                                                key={amountOfUsers}
                                            >
                                                {amountOfUsers}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row my-row' style={{justifyContent: 'center'}}>
                        {PLANS.map((plan) => (
                            <div className='col-md-5 my-class' key={plan.code}>
                                <div
                                    className={`main-price${
                                        plan.name === 'professional' ? ' mp-1' : ''
                                    }`}
                                >
                                    <div className='prc-text'>
                                        <h2>
                                            <FormattedMessage
                                                id={
                                                    plan.name === 'professional'
                                                        ? 'plan.most_popular'
                                                        : `plan.${plan.name}`
                                                }
                                            />
                                        </h2>
                                        <h1>
                                            <FormattedMessage id={`plan.${plan.name}`} />
                                        </h1>
                                    </div>
                                    <div className='text-box'>
                                        {nbOfUsers !== '100+' ? ( //check first if number of users exceeds 100 before showing price plan UI
                                            <>
                                                <h1
                                                    className={
                                                        plan.code === 'talk-ins-x' ? 'hidden' : ''
                                                    }
                                                >
                                                    <FormattedMessage id='plan.from' />
                                                </h1>
                                                <span>
                                                    {isMonthly ? ( //check if annual or monthly has been selected, price shown will depend on it
                                                        <>
                                                            <span
                                                                className={
                                                                    plan.code === 'talk-ins-x'
                                                                        ? 'hidden'
                                                                        : ''
                                                                }
                                                            >
                                                                {
                                                                    currency['symbols'][
                                                                        onBoardCurrency
                                                                    ]['symbol']
                                                                }
                                                            </span>
                                                            <h2>
                                                                {plan['plan'][1][nbOfUsers].price}
                                                            </h2>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <span
                                                                className={
                                                                    plan.code === 'talk-ins-x'
                                                                        ? 'hidden'
                                                                        : ''
                                                                }
                                                            >
                                                                {
                                                                    currency['symbols'][
                                                                        onBoardCurrency
                                                                    ]['symbol']
                                                                }
                                                            </span>
                                                            <h2>
                                                                {plan['plan'][0][nbOfUsers].price}
                                                            </h2>
                                                        </>
                                                    )}
                                                </span>
                                                <p
                                                    className={
                                                        plan.code === 'talk-ins-x' ? 'hidden' : ''
                                                    }
                                                >
                                                    <FormattedMessage id='plan.monthly' />
                                                    <br />
                                                </p>
                                            </>
                                        ) : (
                                            <h2 className='get-quote'>
                                                {' '}
                                                <FormattedMessage id='plan.get_quote' />{' '}
                                            </h2>
                                        )}
                                    </div>
                                    <div
                                        className={
                                            'talk-sale ' + (plan.code === 'talk-ins-x' && 'hidden')
                                        }
                                    >
                                        <div className='btn-1'>
                                            <button>
                                                <FormattedMessage id='plan.talk_to_sales' />
                                            </button>
                                        </div>
                                        <div className='btn-2'>
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault();

                                                    const dataOnboard = onboardInfo;
                                                    //making sure that anything inside the array will be overriden
                                                    dataOnboard[0] = {
                                                        planCode: plan.code,
                                                        nbOfUsers: nbOfUsers,
                                                        isMonthly: isMonthly,
                                                    };

                                                    setOnBoardInfo(dataOnboard);
                                                }}
                                            >
                                                <Link to={`/quote/?q=${plan.code}`}>
                                                    <FormattedMessage id='plan.buy_now' />
                                                </Link>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BusinessPlanPage;
