import React, {useContext, useState, useEffect} from 'react';
import {OnBoardContext} from '../../contexts/onboardContext';
import {FormattedMessage} from 'react-intl';
import currency from '../../currency/currency.json';
const AddonExtra = ({
    addonName,
    itemName,
    unitPrice,
    baseQuantity,
    addonID,
    enabled,
    currencyCode,
    setOrderQuoteTotal,
}) => {
    const [checked, setChecked] = useState(enabled);
    const [addonPrice, setAddonPrice] = useState(unitPrice);
    const [scrollY, setScrollY] = useState(window.pageYOffset);

    addonName = addonName.replace('[Extra]', ''); //remove anything where it says [Extra] in the string because we will read it later

    const {onboardInfo, setOnBoardInfo, orderData, setOrderData, addonChange, setAddonChange} =
        useContext(OnBoardContext);

    const dataOnboard = onboardInfo;
    const orderInfo = orderData;

    useEffect(() => {
        setOrderData(orderInfo);
        setOnBoardInfo(dataOnboard);
    }, [orderInfo]);

    const [onboardInfoLocal, setOnboardInfoLocal] = useState(onboardInfo);

    const calculateTotal = () => {
        let total = 0;

        onboardInfo[2]['order_values'].forEach((order) => {
            if (order['price'] > 0) {
                //we update the total price ONLY when order is of type Extra and enabled OR  order is of type Standard
                if (
                    (order['type'] === '[Extra]' && order['enabled'] === true) ||
                    order['type'] === '[Standard]' ||
                    order['type'] === '[Monthly]' ||
                    order['type'] === '[Yearly]'
                ) {
                    total += order['price'] * order['quantity'];
                }
            }
        });

        const dataOnboard = onboardInfo;
        dataOnboard[2]['totalOrder'] = total;

        // setOnBoardInfo(dataOnboard);
        // setQuoteTotal(total);

        setOnboardInfoLocal(dataOnboard);
        // changeOrderQuoteTotal(total);
        //
        setOrderQuoteTotal(total);

        // setQuoteTotal(total);
    };

    const handleCheck = (e) => {
        e.preventDefault();
        setChecked(!checked);

        const dataOnboard = onboardInfo;
        //update the enabled property based on click  event of the check
        if (!checked) {
            dataOnboard[2]['order_values'][addonID]['enabled'] = true;

            setOnBoardInfo(dataOnboard);
        } else {
            dataOnboard[2]['order_values'][addonID]['enabled'] = false;

            setOnBoardInfo(dataOnboard);
        }

        //we have to redo the calculations as soon as the check event of this addon has been fired
        //NOTE: this only happends if the addon is of type '[Extra]'
        calculateTotal();

        setAddonChange(!addonChange);
    };

    const handleQtyChangeOriginal = (e) => {
        e.preventDefault();

        // setAddonChange(!addonChange);

        const newQuantity = Number(e.target.value);
        const newPrice = newQuantity * unitPrice;
        setAddonPrice(newPrice);

        const dataOnboard = onboardInfo;
        const orderInfo = orderData;

        let compString = `[Extra] ${addonName}`;

        orderInfo['order_values'].forEach((oneOrder) => {
            if (oneOrder['name'] === compString) {
                oneOrder['quantity'] = newQuantity;
            }
        });

        setOrderData(orderInfo);
        dataOnboard[2] = orderInfo;

        // setOnBoardInfo(dataOnboard);

        updateExtraOrder(addonID, newQuantity);

        // calculateTotal();

        window.scrollTo(0, 5000);

        // window.onscroll = function () { window.scrollTo(0, 0); };
    };

    const handleQtyChange = (e) => {
        e.preventDefault();

        const newQuantity = Number(e.target.value);
        const newPrice = newQuantity * unitPrice;
        setAddonPrice(newPrice);

        updateExtraOrder(addonID, newQuantity);
    };

    const addonOrder = {
        name: `[Extra] ${addonName}`,
        quantity: baseQuantity,
        price: unitPrice,
        type: '[Extra]',
        enabled: false,
    };

    //add the order (add-on Extra) ONLY when it does not exist yet,  do a split & join because we need the perfect exact string to compare
    //we also have to manifest the changes into onboardInfo
    if (
        orderInfo['order_values'].find(
            (oneOrder) =>
                oneOrder['name'].split(' ').join('') === addonOrder['name'].split(' ').join('')
        ) === undefined
    ) {
        orderInfo['order_values'].push(addonOrder);
        dataOnboard[2] = orderInfo;
    }

    //any price/quantity change will be handled in this function
    //check of any changes (called inside handleQtyChange()), and then let the context onBoardInfo know of those updates
    const updateExtraOrder = (orderID, newQuantity) => {
        dataOnboard[2]['order_values'][orderID]['quantity'] = newQuantity;
        setOnBoardInfo(dataOnboard);

        calculateTotal();
    };

    return (
        <div className='add-on-con'>
            <div className='add-on-name'>
                <input type='checkbox' onClick={handleCheck} defaultChecked={checked} />

                <FormattedMessage id={itemName.trim()}>
                    {(placeholder) => <span>Extra {placeholder}</span>}
                </FormattedMessage>
            </div>
            <div className={checked ? 'add-on-side' : 'add-on-side extra'}>
                <div className='add-on-qty'>
                    <input type='text' defaultValue={baseQuantity} onChange={handleQtyChange} />
                </div>

                {
                    //if for some reason the quantity property does not exist, fall back to display addonPrice instead
                    onboardInfo[2]['order_values'][addonID]?.quantity ? (
                        <div className='unit-prc-view'>
                            {' '}
                            <span>
                                <span className='prc-value'>
                                    {currency['symbols'][currencyCode]['symbol']}
                                    {unitPrice.toFixed(2)}
                                </span>{' '}
                                <span className='prc-code'>
                                    {currency['symbols'][currencyCode]['code']}
                                </span>
                            </span>
                        </div>
                    ) : (
                        <div className='unit-prc-view'>
                            {' '}
                            <span>
                                <span className='prc-value'>
                                    {currency['symbols'][currencyCode]['symbol']}
                                    {unitPrice.toFixed(2)}
                                </span>{' '}
                                <span className='prc-code'>
                                    {currency['symbols'][currencyCode]['code']}
                                </span>
                            </span>
                        </div>
                    )
                }

                {
                    //if for some reason the quantity property does not exist, fall back to display addonPrice instead
                    onboardInfo[2]['order_values'][addonID]?.quantity ? (
                        // ?
                        //     (
                        //         <div className="add-on-prc">{currency['symbols'][currencyCode]['symbol']}{(onboardInfo[2]['order_values'][addonID]['quantity']*unitPrice).toFixed(2)} <span>{currency['symbols'][currencyCode]['code']}</span></div>

                        //     )
                        <div className='add-on-prc'>
                            {currency['symbols'][currencyCode]['symbol']}
                            {(
                                onboardInfoLocal[2]['order_values'][addonID]['quantity'] * unitPrice
                            ).toFixed(2)}{' '}
                            <span>{currency['symbols'][currencyCode]['code']}</span>
                        </div>
                    ) : (
                        <div className='add-on-prc'>
                            {currency['symbols'][currencyCode]['symbol']}
                            {addonPrice.toFixed(2)}{' '}
                            <span>{currency['symbols'][currencyCode]['code']}</span>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default AddonExtra;
