import React, {useReducer, useContext, useEffect, useState, useRef} from 'react';
import pay1 from '../../assets/image/pay-1.png';
import pay2 from '../../assets/image/pay-2.png';
import pay3 from '../../assets/image/pay-3.png';
import {billingErrorReducer, billingReducer} from '../../util/payment/billing';
import {Formik, useField, Field} from 'formik';
import AddonViewOnly from '../../components/addons/addonViewOnly';
import AddonCustomQuote from '../../components/addons/addonCustomQuote';
import AddonHeaderView from '../../components/addons/addonHeaderView';
import {FormattedMessage} from 'react-intl';
import ReactTooltip from 'react-tooltip';

// import "../../assets/css/billingInfo.css";
import '../../assets/css/quoteBillingInfo.css';

import {OnBoardContext} from '../../contexts/onboardContext';

import {useIntl} from 'react-intl';
import {useHistory} from 'react-router-dom';

import tax_us from '../../tax/us.json';
import tax_ca from '../../tax/ca.json';
import tax from '../../tax/tax.json';
import errors from '../../errors/errors';

import currency from '../../currency/currency.json';
import countries from '../../countries/countries.json';

import loading from '../../assets/image/loading.gif';
import loading_reverse from '../../assets/image/loading_reverse.gif';

import {Spring, animated, config} from 'react-spring';

//import LoadingOverlay from "react-loading-overlay";

import styled, {css} from 'styled-components';

const ITEMS = {
    'Cloud PBX - Essentials': 'plan.pbx_ess',
    'Cloud PBX - Enterprise': 'plan.pbx_ent',
    'Cloud PBX - Professional': 'plan.pbx_pro',
    'Croo Talk - Essentials': 'plan.talk_ess_x',
    'Croo Talk - Professional': 'plan.talk_pro_x',
    'Croo Talk - Enterprise': 'plan.talk_ent_x',
    'DID / Canada - USA': 'plan.did_nam',
    'Hard Drive / 10 GB': 'plan.hd_10',
    'Hard Drive / 100 GB': 'plan.hd_100',
    'Hard Drive / 1000 GB': 'plan.hd_1000',
    '1 (8XX) Numbers / Canada - USA': 'plan.1_num',
    'International Phone Number': 'plan.did_inter',
};

const QuoteBillingInfo = ({order, onSubmit, onStageChange, quoteInfo}) => {
    const [billingState, billingDispatch] = useReducer(billingReducer, {}, () => ({}));

    // const [changeInfo, setChangeInfo] = useState(false);
    let changeInfo = false;
    const [error, setError] = useState('');
    const [errorMessage, setErrorMessage] = useState([]);
    const [termsChecked, setTermsChecked] = useState(false);
    const [reordered, setReordered] = useState(false);

    const intl = useIntl();
    const history = useHistory();

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const [submitStatus, setSubmitStatus] = useState('idle');

    const [paymentType, setPaymentType] = useState('CC');

    const {
        onboardInfo,
        setOnBoardInfo,
        emptyFields,
        setEmptyFields,
        currentStage,
        setCurrentStage,
        nextStage,
        setNextStage,
        prevStage,
        setPrevStage,
        emailLang,
        quoteTotal,
        setQuoteTotal,
        // quoteInfo,
        setQuoteInfo,
        onboardStatus,
        setOnboardStatus,
        onboardQuoteStatus,
        setOnboardQuoteStatus,
        legacyQuote,
        pageLang,
        // the currency to use for quote billing page, NOTE: this is only used by custom hubspot quote, and not by self-onboard
        // see contactInfo.jsx [line 228] for init
        onBoardCurrency,
    } = useContext(OnBoardContext);

    const errorHandler = errors;

    const [billingErrorState, billingValidationDispatch] = useReducer(
        billingErrorReducer,
        {},
        () => ({})
    );

    const [taxesList, setTaxesList] = useState([]);

    const [taxTotal, setTaxTotal] = useState(0);

    const [width, setWindowWidth] = useState(0);

    const [countrySelected, setCountrySelected] = useState('');
    const [otherCountry, setOtherCountry] = useState('');
    const [provinceSelected, setProvinceSelected] = useState('Select...');

    const selectEl = useRef(null);
    const formRef = useRef(); //ref to be used as a setter and getter of Formik values

    const [provinceValue, setProvinceValue] = useState('Select Value...'); //this will be the state to use for the Province select tag value

    //this is the local currency state to be used for this page, not ideal to use onBoardCurrency directly since changing its value will rerender the whole application given that it is a state from the OnboardContext
    //For now this is not needed by quoteBillingInfo because we take the hubspot quote currency directly, which is onBoardCurrency
    const [currencyPage, setCurrencyPage] = useState(onBoardCurrency);

    const [hideItemList, sethideItemList] = useState(false);
    const [rotationUnit, setRotationUnit] = useState(-1);
    const [overlayLoaded, setOverlayLoaded] = useState(true);

    const DarkBackground = styled.div`
        display: none; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 999; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0, 0, 0); /* Fallback color */
        background-color: rgba(206, 54, 121, 1); /* Fuchsia w/ opacity */

        ${(props) =>
            props.disappear &&
            css`
                display: block; /* show */
            `}
    `;

    // const StyledOverlayLoader = styled(LoadingOverlay)`
    //     top: 50%;
    //`;

    const TurningArrow = () => {
        const [pressed, setPressed] = useState(false);

        return (
            <Spring native from={{scale: 2}} to={{scale: pressed ? 1.8 : 2}} config={config.wobbly}>
                {({scale}) => (
                    <animated.button
                        className='right-a'
                        style={{
                            transform: scale.to((scale) => `scale(${scale})`),
                        }}
                        onMouseDown={() => setPressed(true)}
                        onClick={() => {
                            setPressed(false);
                            if (rotationUnit === 0) {
                                setRotationUnit(180);
                                sethideItemList(true);
                            } else {
                                setRotationUnit(0);
                                sethideItemList(false);
                            }
                        }}
                        onMouseLeave={() => setPressed(false)}
                    >
                        <animated.i
                            style={{
                                transform: scale.to((scale) => `rotate(${rotationUnit}deg)`),
                            }}
                            className='fa fa-chevron-right fa-2x'
                            aria-hidden='true'
                        />
                    </animated.button>
                )}
            </Spring>
        );
    };

    //get the page width dimensions, will be needed for responsive layouts (line )
    useEffect(() => {
        updateDimensions();

        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    const updateDimensions = () => {
        const width = window.innerWidth;

        //
        setWindowWidth(width);
    };

    useEffect(() => {
        setOnBoardInfo(quoteInfo); //  change onBoardInfo ONLY when we are sure that there is a data to be set
        //
    }, [changeInfo]);

    useEffect(() => {
        // const quoteID = window.location.pathname.split('/')[2];

        //

        // // fetch(`http://127.0.0.1:8000/quotes/public/${quoteID}`)
        // fetch(`https://iqibge86aa.execute-api.us-east-2.amazonaws.com/api/quotes/public/${quoteID}`)
        // // fetch(`https://iqibge86aa.execute-api.us-east-2.amazonaws.com/api/quotes/public/${quoteID}`)
        // .then(response => response.json())
        // .then((data) => {

        //

        //     const dataOnboard = onboardInfo;
        //

        //     dataOnboard[0] = {'planCode': 'cust-quote'};

        //     dataOnboard[2] = {"order_values": []};

        //     let total = 0;
        //         if(data[0]?.[2] !== undefined){
        //             for (let index = 0; index < data[0][2]['items'].length; index++) {
        //
        //                 dataOnboard[2]['order_values'].push(data[0][2]['items'][index]['properties']) ;
        //                 total=data[1]['hs_quote_amount'];

        //                 let frequency = data[0][2]['items'][index]['properties']['recurringbillingfrequency'];

        //                 //determine the billing frequency of the Plan
        //                 if( frequency !== null){
        //                     switch(frequency) {
        //                         case 'annually' :
        //                             dataOnboard[0].isMonthly = false;
        //                             break;
        //                         case 'monthly' :
        //                             dataOnboard[0].isMonthly = true;
        //                             break;
        //                     }

        //                 }

        //             }
        //         }

        //
        //
        //     setQuoteTotal(total);

        //     setOnBoardInfo(dataOnboard);
        //     setQuoteInfo(dataOnboard);

        //     setOnboardStatus('idle');

        //     // setChangeInfo(!changeInfo);
        //     changeInfo = !changeInfo;

        //

        // });

        setOnboardStatus('idle');
    }, []);

    useEffect(() => {
        if (onboardStatus === 'idle') {
            setRotationUnit(0);
        }
    }, [onboardStatus]);

    useEffect(() => {
        if (submitStatus === 'waiting') {
            setOverlayLoaded(!overlayLoaded);
        } else {
            setOverlayLoaded(false);
        }
    }, [submitStatus]);

    const getErrorClassName = (name) => {
        if (billingErrorState[name] !== undefined) return billingErrorState[name] ? '' : 'error-1';
        return '';
    };

    const handleOnChange = (e) => {
        const {name, value} = e.target;
        billingDispatch({payload: {name, value}});
        billingValidationDispatch({payload: {name, value}});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let valid = true;
        Object.keys(billingErrorState).forEach((item) => {
            valid = valid && billingErrorState[item];
        });
        if (valid) onSubmit(billingState);
        else alert('Please enter valid information.');

        onStageChange('paymentPage'); //will change this later on
    };

    const handleCheck = (e) => {
        //
        setTermsChecked(!termsChecked);
    };

    const getAbbreviation = (province) => {
        let result = 'None';

        //

        for (let index = 0; index < tax_ca.length; index++) {
            //
            if (tax_ca[index]['name'] === province) {
                result = tax_ca[index]['abbreviation'];

                //
                return result;
            }
        }

        for (let index = 0; index < tax_us.length; index++) {
            //
            if (tax_us[index]['name'] === province) {
                result = tax_us[index]['abbreviation'];

                //
                return result;
            }
        }

        return result;
    };

    const getProvinceName = (provinceAbbreviation) => {
        let result = 'None';

        //

        //this is a forced behavior for default province based on the country selected
        if (provinceAbbreviation === 'Select Value...') {
            if (countrySelected === 'CA') {
                result = 'Alberta';
            }
            if (countrySelected === 'US') {
                result = 'Alabama';
            }
        }

        for (let index = 0; index < tax_ca.length; index++) {
            //
            if (tax_ca[index]['abbreviation'] === provinceAbbreviation) {
                result = tax_ca[index]['name'];

                //
                return result;
            }
        }

        for (let index = 0; index < tax_us.length; index++) {
            //
            if (tax_us[index]['abbreviation'] === provinceAbbreviation) {
                result = tax_us[index]['name'];

                //
                return result;
            }
        }

        return result;
    };

    const getCountryName = (countryAbbreviation) => {
        let result = 'None';

        //

        if (countryAbbreviation === 'CA') {
            result = 'Canada';
            return result;
        }
        if (countryAbbreviation === 'US') {
            result = 'United States';
            return result;
        }

        return result;
    };

    const getCurrency = (countryCode) => {
        //

        for (const currencyEl in currency) {
            if (currencyEl['countryCode'] === countryCode) {
                //
            }
        }
    };

    const MySelect = ({label, ...props}) => {
        // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
        // which we can spread on <input> and alse replace ErrorMessage entirely.
        const [field, meta] = useField(props);
        return (
            <>
                <select {...field} {...props}></select>
                {meta.touched && meta.error && <div className='input-feedback'>{meta.error}</div>}
            </>
        );
    };

    const CountrySelect = ({label, ...props}) => {
        // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
        // which we can spread on <input> and alse replace ErrorMessage entirely.
        const [field, meta] = useField(props);

        // const [countrySelected, setCountrySelected] = useState(props[0]);

        //
        //

        //Handle Country Change

        const handleCountryChange = (e) => {
            e.preventDefault();

            resetSelect();
            let countryChosen = e.target.value;

            //

            setCountrySelected(e.target.value);

            if (countryChosen === 'CA') {
                //

                overrideSelect(getAbbreviation('Alberta'));

                //force to show the Taxes with the default CA City on the UI
                let defaultCACity = 'Alberta';
                let defaultCACityAbbr = getAbbreviation(defaultCACity);
                let result = [];
                result = tax['city'][defaultCACityAbbr];

                calculateTax(result, defaultCACityAbbr);

                setCurrencyPage('CAD'); //change currency accordingly, we change currencyPage instead of onBoardCurrency to avoid rerendering of the entire app

                setOtherCountry('');
            }

            if (countryChosen === 'US') {
                //

                overrideSelect(getAbbreviation('Alabama'));
                //reset the Taxes when US has been chosen as a country, regardless of the shown province
                setTaxesList([]);
                updateOrderTotal([]);

                setCurrencyPage('USD'); //change currency accordingly, we change currencyPage instead of onBoardCurrency to avoid rerendering of the entire app

                setOtherCountry('');
            }

            if (countryChosen === 'Other') {
                //

                //
                resetCountry2();

                const currencyFound = getCurrency(otherCountry);

                //
                //reset the Taxes when US has been chosen as a country, regardless of the shown province
                setTaxesList([]);
                updateOrderTotal([]);

                //find out if we could use the currency found (if it's EUR, CAD OR USD )
                if (currencyFound === 'EUR') {
                    setCurrencyPage('EUR');
                } else if (currencyFound === 'CAD') {
                    setCurrencyPage('CAD');
                } else {
                    setCurrencyPage('USD'); //default currency, since the currencyFound is foreign
                }
            }
        };

        return (
            <>
                {/* <select {...field} {...props}  value={countrySelected}  onChange={handleCountryChange}></select> */}
                <select
                    {...field}
                    {...props}
                    value={countrySelected}
                    onChange={handleCountryChange}
                ></select>
                {/* triple verification: we also need to check if countrySelected is not empty */}
                {meta.touched && meta.error && countrySelected === '' && (
                    <div className='input-feedback'>{meta.error}</div>
                )}
            </>
        );
    };

    const ProvinceSelect = ({label, ...props}) => {
        // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
        // which we can spread on <input> and alse replace ErrorMessage entirely.
        const [field, meta, helpers] = useField(props);

        const {setValue} = helpers;
        const {value} = meta;
        // const testCall = () => {
        //

        // }

        return (
            <>
                {/* <select {...field} {...props}  onChange={handleCountryChange}></select> */}
                {/* <select {...field} {...props} value={provinceSelected} onChange={handleProvinceChange}></select> */}
                {/* <select {...field} {...props} ></select> */}
                {/* <select   {...field} {...props}   ref={selectEl}  value={provinceSelected} onChange={handleProvinceChange}></select> */}
                <select {...field} {...props} ref={selectEl}></select>
                {meta.touched && meta.error && <div className='input-feedback'>{meta.error}</div>}
            </>
        );
    };

    const updateOrderTotal = (taxList) => {
        //
        let newTotal = 0;
        for (let index = 0; index < taxList.length; index++) {
            const taxElement = taxList[index];

            //
            newTotal += parseFloat(taxElement['unitPrice']);
        }

        //

        //
        // setQuoteTotal(newTotal);
        setTaxTotal(parseFloat(newTotal.toFixed(2)));

        //taxhandle
        // let tempOnboard = onboardInfo;

        // let newTotalandTax = parseFloat(tempOnboard[2].totalOrder) + parseFloat(newTotal) ;

        // tempOnboard[2].totalOrder = newTotalandTax;

        //
        // setOnBoardInfo(tempOnboard);
    };
    // https://stackoverflow.com/questions/10015027/javascript-tofixed-not-rounding

    const toFixed = (num, precision) => {
        return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
    };

    function round(num, precision) {
        var base = 10 ** precision;
        return (Math.round(num * base) / base).toFixed(precision);
    }

    const calculateTax = (taxList, provinceAbbr) => {
        let taxArray = [];
        let taxTotal = 0;
        let taxElementTotal = 0;
        let taxGrandTotal = 0;

        let totalOrder = 0; //we have to take onboardInfo's totalOrder inside of quoteTotal because onboardInfo is more up to date

        if (onboardInfo[2].totalOrder > 0) {
            totalOrder = onboardInfo[2].totalOrder;
        } else {
            totalOrder = quoteTotal;
        }

        //
        //
        //

        for (let index = 0; index < taxList.length; index++) {
            const taxElement = taxList[index];

            //
            //

            if (provinceAbbr === 'ON') {
                // this is a special case for Ontario, with its HST tax different from the other provinces with 13% instead of 15%
                //
                // taxElementTotal = ((totalOrder * 13 ) / 100).toFixed(2);
                taxElementTotal = ((totalOrder * 13) / 100).toFixed(2);
            } else {
                //
                // taxElementTotal = ((totalOrder * tax['type'][taxElement]['rate'] ) / 100).toFixed(3);
                // taxElementTotal = ((totalOrder * tax['type'][taxElement]['rate'] ) / 100).toFixed(2);

                taxElementTotal = toFixed((totalOrder * tax['type'][taxElement]['rate']) / 100, 2);

                // taxElementTotal = (Math.ceil(totalOrder * tax['type'][taxElement]['rate'] ) / 100).toFixed(2);

                // taxElementTotal = taxElementTotal.toFixed(2);
            }

            //
            //
            //

            taxArray.push({name: taxElement, unitPrice: taxElementTotal});
            // taxArray.push({"name" : taxElement ,"unitPrice": taxElementTotal});
        }

        //

        setTaxesList(taxArray);

        updateOrderTotal(taxArray);

        // document.getElementById('test-tax').innerText = getProvinceName(provinceAbbr) ;

        // let abbr = getProvinceName(provinceAbbr);

        // document.getElementById('test-taxid').innerHTML = `
        //     <option value=${provinceAbbr}> ${abbr} </option>

        // ` ;
        // taxesListTest = taxArray;
    };

    const handleOptionChange = (e) => {
        let provinceAbbr = e.target.value;

        //

        let result = [];
        result = tax['city'][provinceAbbr];

        //
        //

        // document.getElementById('provinceSelect').value = provinceAbbr;

        let provinceSelectEl = document.getElementById('provinceSelect');
        //
        provinceSelectEl.value = provinceAbbr;

        //update the Province value to the one chosen
        setProvinceValue(provinceAbbr);

        if (result !== undefined) {
            calculateTax(result, provinceAbbr);
        } else {
            //
            setTaxesList([]);
            setTaxTotal(0);
            //
        }

        overrideSelect(provinceAbbr);
    };

    const overrideSelect = (provinceAbbr) => {
        //
        document.getElementById('test-tax').innerText = getProvinceName(provinceAbbr);

        let abbr = getProvinceName(provinceAbbr);

        if (document.getElementById('test-taxid')) {
            document.getElementById('test-taxid').innerHTML = `
                <option value=${provinceAbbr}> ${abbr} </option>
            
            
            `;
        } else {
            //
            setProvinceSelected(abbr);
        }
    };

    const resetSelect = () => {
        if (document.getElementById('provinceSelect')) {
            document.getElementById('provinceSelect').selectedIndex = -1;
        }
        //
    };

    const resetCountry2 = () => {
        //
        //

        formRef.current.setFieldValue('country2', ''); // we empty the input of the dom element with ID=country2, we can't use vanilla js since we don't know whether the element exists or not
    };

    const reorderAddonView = () => {
        let planTitleID = 0;

        onboardInfo[2]['order_values'].forEach((order, index) => {
            if (!order['name'].includes('[Standard]') && !order['name'].includes('[Extra]')) {
                planTitleID = index;
            }
        });

        let tempOnboard = onboardInfo;
        let temp = tempOnboard[2]['order_values'][0];
        tempOnboard[2]['order_values'][0] = tempOnboard[2]['order_values'][planTitleID];
        tempOnboard[2]['order_values'][planTitleID] = temp;

        tempOnboard[2]['order_values'].forEach((order, index) => {
            let extraOrder = {};

            if (order['name'].includes('[Extra]')) {
                extraOrder = order;
                tempOnboard[2]['order_values'].splice(index, 1);
                tempOnboard[2]['order_values'].push(extraOrder);
            }
        });

        setOnBoardInfo(tempOnboard);
    };

    // if(reordered === false){
    //     reorderAddonView();
    //     setReordered(true);
    //

    //
    // }
    // else{
    //
    //

    // }

    const handleExpireChange = (expValue) => {};
    // very good filtering for what we need for the credit card expiration date
    // not perfect but can handle common use cases
    // source: https://codepen.io/stoXmod/pen/bGePmdd
    const handleKeyExp = (e) => {
        var inputChar = String.fromCharCode(e.keyCode);
        var code = e.keyCode;
        var allowedKeys = [8];
        if (allowedKeys.indexOf(code) !== -1) {
            return;
        }

        e.target.value = e.target.value
            .replace(
                /^([1-9]\/|[2-9])$/g,
                '0$1/' // 3 > 03/
            )
            .replace(
                /^(0[1-9]|1[0-2])$/g,
                '$1/' // 11 > 11/
            )
            .replace(
                /^([0-1])([3-9])$/g,
                '0$1/$2' // 13 > 01/3
            )
            .replace(
                /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
                '$1/$2' // 141 > 01/41
            )
            .replace(
                /^([0]+)\/|[0]+$/g,
                '0' // 0/ > 0 and 00 > 0
            )
            .replace(
                /[^\d\/]|^[\/]*$/g,
                '' // To allow only digits and `/`
            )
            .replace(
                /\/\//g,
                '/' // Prevent entering more than 1 `/`
            );
    };

    const handleCCKeyExp = (e) => {
        const mask = (value, limit, separator) => {
                var output = [];
                for (let i = 0; i < value.length; i++) {
                    if (i !== 0 && i % limit === 0) {
                        output.push(separator);
                    }

                    output.push(value[i]);
                }

                return output.join('');
            },
            unmask = (value) => {
                let unmaskedValue = value.replace(/[^\d]/g, '');

                return unmaskedValue;
            },
            checkSeparator = (position, interval) => {
                return Math.floor(position / (interval + 1));
            };

        let ccNumberPattern = /^\d{0,16}$/g,
            ccNumberSeparator = ' ',
            ccNumberInputOldValue,
            ccNumberInputOldCursor;

        let el = e.target,
            newValue = unmask(el.value),
            newCursorPosition;

        ccNumberInputOldValue = el.value;
        ccNumberInputOldCursor = el.selectionEnd;

        if (newValue.match(ccNumberPattern)) {
            newValue = mask(newValue, 4, ccNumberSeparator);

            newCursorPosition =
                ccNumberInputOldCursor -
                checkSeparator(ccNumberInputOldCursor, 4) +
                checkSeparator(
                    ccNumberInputOldCursor + (newValue.length - ccNumberInputOldValue.length),
                    4
                ) +
                // (unmask(newValue).length - unmask(ccNumberInputOldValue).length);
                (unmask(newValue).length - unmask(ccNumberInputOldValue).length) +
                1; //add +1 at the end, otherwise the cursor will always be one character behind the full card number

            el.value = newValue !== '' ? newValue : '';

            formRef.current.setFieldValue('cardNumber', newValue);
        } else {
            formRef.current.setFieldValue('cardNumber', ccNumberInputOldValue);

            el.value = ccNumberInputOldValue;
            newCursorPosition = ccNumberInputOldCursor;
        }

        // el.setSelectionRange(newCursorPosition, newCursorPosition);
        el.setSelectionRange(newCursorPosition, newCursorPosition + 1);
    };

    return (
        <>
            {errorMessage?.error && (
                <div className='err-msg'>
                    <i className='fa fa-exclamation-triangle'></i>
                    <span>{errorMessage['error']}</span>
                </div>
            )}
            {/*<DarkBackground disappear={overlayLoaded}>
                
    <StyledOverlayLoader
        active={true}
        spinner={true}
        classNamePrefix='OverlayLoader_'
    >
    </StyledOverlayLoader>
                
</DarkBackground> */}
            <div className='billing-box'>
                <div className={'arrow-box ' + (hideItemList ? 'onb-hidden ' : '')}>
                    <div className='row'>
                        <div className='col-md-0 col-lg-6 pl-0 my-pr' />
                        <div
                            className='col-md-6 col-lg-6 pr-0 my-pl'
                            style={{textAlign: !hideItemList ? 'right' : 'center'}}
                        >
                            {onboardStatus === 'idle' && <TurningArrow />}

                            {/* the pencil icon has to be  */}

                            {/* {
                            (onboardInfo[0]['planCode'] !== 'cust-quote') ? (
                                <span onClick={(e) => onStageChange('orderInfo')}>
                                    <i className="fa fa-pencil" aria-hidden="true" />
                                </span>
                            ) : (
                                <span>
                                    <i className="fa fa-pencil" aria-hidden="true" style={{visibility : 'none'}}/>
                                </span>                                
                            )
                        } */}
                        </div>
                    </div>
                </div>

                {
                    // onboardStatus == "idle" && rotationUnit === 0 &&  (
                    onboardStatus === 'idle' && (
                        // onboardStatus == "idle" ? (

                        <Formik
                            initialValues={{
                                cardNumber: '',
                                expDate: '',
                                cardCVV: '',
                                addressLine1: '',
                                addressLine2: '',
                                country: countrySelected,
                                country2: '',
                                city: '',
                                provinceState: '',
                                postalCode: '',
                                paymentMethod: '',
                                picked: 'CC',
                                accountType: '',
                                accountName: '',
                                accountNumber: '',
                                confirmAccountNumber: '',
                                routingNumber: '',
                            }}
                            validate={(values) => {
                                const errors = {};
                                //IF OPTION CHOSEN IS CREDIT CARD

                                if (values.picked === 'CC') {
                                    if (!values.cardCVV) {
                                        errors.cardCVV = intl.formatMessage({id: 'error.required'});
                                    } else if (!/^[0-9]{3,4}$/.test(values.cardCVV)) {
                                        errors.cardCVV = intl.formatMessage({id: 'error.card_cvv'});
                                    }
                                    let tempCardNumber = values.cardNumber;

                                    let formattedCardNumber = tempCardNumber.replaceAll(' ', ''); //replace all spaces first
                                    formattedCardNumber = formattedCardNumber.replaceAll('-', ''); //replace all dashes as well

                                    if (!values.cardNumber) {
                                        errors.cardNumber = intl.formatMessage({
                                            id: 'error.required',
                                        });
                                    } else if (
                                        !/^4[0-9]{12}(?:[0-9]{3})?$/.test(formattedCardNumber) &&
                                        !/^3[47][0-9]{13}$/.test(formattedCardNumber) &&
                                        !/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
                                            formattedCardNumber
                                        )
                                    ) {
                                        // else if (!/^4[0-9]{12}(?:[0-9]{3})?$/.test(values.cardNumber) && !/^3[47][0-9]{13}$/.test(values.cardNumber) && !/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(values.cardNumber) )
                                        errors.cardNumber = intl.formatMessage({
                                            id: 'error.card_number',
                                        });
                                    }

                                    if (!values.expDate) {
                                        errors.expDate = intl.formatMessage({id: 'error.required'});
                                    } else if (!/^\d{2}\/\d{2}$/.test(values.expDate)) {
                                        errors.expDate = intl.formatMessage({
                                            id: 'error.card_expdate',
                                        });

                                        let expDate = values.expDate;

                                        handleExpireChange(expDate);
                                    }
                                }

                                if (values.picked === 'BA') {
                                    if (!values.accountName) {
                                        errors.accountName = intl.formatMessage({
                                            id: 'error.required',
                                        });
                                    }
                                    if (!values.accountNumber) {
                                        errors.accountNumber = intl.formatMessage({
                                            id: 'error.required',
                                        });
                                    }
                                    if (!values.routingNumber) {
                                        errors.routingNumber = intl.formatMessage({
                                            id: 'error.required',
                                        });
                                    }
                                    if (!values.confirmAccountNumber) {
                                        errors.confirmAccountNumber = intl.formatMessage({
                                            id: 'error.required',
                                        });
                                    } else {
                                        if (values.confirmAccountNumber !== values.accountNumber) {
                                            errors.confirmAccountNumber = intl.formatMessage({
                                                id: 'error.acc_nber_nomatch',
                                            });
                                        }
                                    }

                                    if (!values.accountType) {
                                        errors.accountType = intl.formatMessage({
                                            id: 'error.required',
                                        });
                                    }
                                }
                                //
                                //
                                if (values.addressLine1.length === 0) {
                                    errors.addressLine1 = intl.formatMessage({
                                        id: 'error.required',
                                    });
                                }

                                if (values.city.length === 0) {
                                    errors.city = intl.formatMessage({id: 'error.required'});
                                }

                                //if countrySelect has this value, then that means either the user hasn't interacted even once, or the user selected an unusable value, aka ''
                                if (countrySelected === '') {
                                    errors.country = 'Required';
                                } else {
                                    values.country = getCountryName(countrySelected);
                                    values.provinceState = getProvinceName(provinceValue);
                                }

                                // // if (values.provinceState.length === 0 && provinceSelected === '') {
                                // //     errors.provinceState = 'Required';
                                // // }

                                // if (!values.provinceState && provinceSelected === '') {
                                //     errors.provinceState = 'Required';
                                // }

                                if (values.postalCode.length === 0) {
                                    errors.postalCode = intl.formatMessage({id: 'error.required'});
                                }
                                // else if(!/^[0-9]{5}(?:-[0-9]{4})?$/.test(values.postalCode) && !/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(values.postalCode)){
                                //     errors.postalCode = 'Invalid Postal Code';
                                // }

                                // if (!values.paymentMethod) {
                                //     errors.paymentMethod = 'Required'
                                // }

                                if (countrySelected === 'Other') {
                                    values.provinceState = 'None';

                                    if (!values.country2) {
                                        errors.country2 = intl.formatMessage({
                                            id: 'error.required',
                                        });
                                    } else {
                                        let country = values.country2;

                                        // let result = countries.filter(country => country['name'] == country);
                                        let result = '';
                                        for (let index = 0; index < countries.length; index++) {
                                            const countryEl = countries[index];

                                            if (
                                                countryEl['name'].toLowerCase() ===
                                                country.toLowerCase()
                                            ) {
                                                result = countryEl['alpha-2'];

                                                setOtherCountry(result);
                                            }
                                        }

                                        result = getCurrency(result);

                                        //find out if we could use the currency found (if it's EUR, CAD OR USD )
                                        if (result === 'EUR') {
                                            setCurrencyPage('EUR');
                                        } else if (result === 'CAD') {
                                            setCurrencyPage('CAD');
                                        } else {
                                            setCurrencyPage('USD'); //change currency accordingly, we change currencyPage instead of onBoardCurrency to avoid rerendering of the entire app
                                        }
                                    }
                                }

                                if (values.picked.length === 0) {
                                    errors.picked = intl.formatMessage({id: 'error.required'});
                                }

                                return errors;
                            }}
                            onSubmit={async (values) => {
                                await new Promise((resolve) => setTimeout(resolve, 500));

                                const tempOnboard = onboardInfo;
                                // tempOnboard[1]['contact_email_lang'] = emailLang;

                                tempOnboard[1] = {contact_email_lang: 'en'};
                                tempOnboard[1]['contact_email_lang'] = emailLang;

                                // swap company information and credit card information
                                let temp = tempOnboard[3];
                                // tempOnboard[3] = values;
                                // tempOnboard.push(values);
                                tempOnboard[3] = values;
                                tempOnboard[7] = temp;

                                tempOnboard[0]['lang'] = pageLang;

                                tempOnboard[6]['quote_status']['hs_currency'] = onBoardCurrency;

                                setOnBoardInfo(tempOnboard);
                                setErrorMessage({});

                                let errorFound = false;

                                setSubmitStatus('waiting');

                                //fetch to add the data needed (contact) to send to the BE
                                // fetch('https://sezxrw50k4.execute-api.us-east-1.amazonaws.com/api', {
                                // fetch(`https://iqibge86aa.execute-api.us-east-2.amazonaws.com/api/subscriptions/create`, {

                                // fetch(`http://127.0.0.1:8000/subscriptions/create`, {

                                // alert(JSON.stringify(tempOnboard, null, 2));

                                // fetch(`http://127.0.0.1:8000/subscriptions/create`,
                                // fetch(`https://iqibge86aa.execute-api.us-east-2.amazonaws.com/api/subscriptions/create`,
                                fetch(`${process.env.REACT_APP_SERVER_URL}/subscriptions/create`, {
                                    // fetch(`http://127.0.0.1:8000/subscriptions/create`, {

                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify(onboardInfo),
                                })
                                    .then((response) => response.json())
                                    .then((json) => {
                                        // alert(JSON.stringify(values, null, 2));

                                        //

                                        if ('error' in json) {
                                            //iterate over our errorHandler, if the error has been found, display it according the client browser's language
                                            for (
                                                let index = 0;
                                                index < errorHandler.length;
                                                index++
                                            ) {
                                                // if(errorHandler[index]['en'].includes(json['error'].split('. Recurly')[0])){
                                                if (
                                                    errorHandler[index]['en'].includes(
                                                        json['error']
                                                            .split('. Recurly')[0]
                                                            .replace('.', '')
                                                    )
                                                ) {
                                                    setErrorMessage({
                                                        error: errorHandler[index][intl.locale],
                                                    });
                                                    errorFound = true;
                                                }
                                            }

                                            //still set the error, translation not found but error is still valid
                                            if (errorFound === false) {
                                                setErrorMessage(json);
                                                setError(json['error']);
                                            }

                                            setSubmitStatus('success');
                                        }
                                        // else if('InternalServerError' in json){
                                        else if (
                                            Object.values(json).indexOf('InternalServerError') > -1
                                        ) {
                                            let error_msg =
                                                'Internal Server Error. Please contact your administrator';

                                            for (
                                                let index = 0;
                                                index < errorHandler.length;
                                                index++
                                            ) {
                                                if (errorHandler[index]['en'].includes(error_msg)) {
                                                    setErrorMessage({
                                                        error: errorHandler[index][intl.locale],
                                                    });
                                                    setError(error_msg);
                                                }
                                            }

                                            setSubmitStatus('success');
                                        } else {
                                            setSubmitStatus('success');

                                            // history.push(`/thankyou/?lang=${pageLang}`);
                                            // history.go();
                                        }
                                    });
                                // alert(JSON.stringify(values, null, 2));
                            }}
                            innerRef={formRef} //this will be the ref to use that allows us to have access to Formik values outside of the Formik component
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                            }) => (
                                // <form onSubmit={handleSubmit}>
                                <form onSubmit={handleSubmit}>
                                    <div
                                        className={`row ${
                                            hideItemList ? 'row-billing-center' : ''
                                        }`}
                                    >
                                        <div
                                            className={`col-md-12 col-lg-6 pl-0 my-pr ${
                                                legacyQuote ? 'legacy' : ''
                                            }`}
                                        >
                                            <div
                                                className={`pay-detail ${
                                                    hideItemList ? 'row-no-bottom' : ''
                                                }`}
                                            >
                                                {/* <div className="pay-method">
                                    <label><FormattedMessage id="billing.payment_method" /></label>
                                    <div className="select-box">
                                        <div className="down-arrow" />

                                        <MySelect label="paymentMethod" name="paymentMethod">
                                            
                                                <FormattedMessage id="billing.payment_select" >
                                                    {placeholder=>  
                                                        
                                                        <option value="">{placeholder}</option>
                                                    }
                                                </FormattedMessage>

                                            
                                            <option value="MasterCard">
                                                Master Card
                                            </option>
                                            <option value="Visa">Visa</option>
                                            <option value="Amex">Amex</option>
                                        </MySelect>
                                    </div>
                                </div> */}

                                                <div className='col pl-0'>
                                                    <div
                                                        role='group'
                                                        aria-labelledby='my-radio-group'
                                                        className='payment-type'
                                                    >
                                                        <label
                                                            className={
                                                                values.picked === 'CC'
                                                                    ? 'payment-picked'
                                                                    : ''
                                                            }
                                                        >
                                                            <Field
                                                                type='radio'
                                                                name='picked'
                                                                value='CC'
                                                            />
                                                            <span>
                                                                <FormattedMessage id='bank.payment_cc' />
                                                            </span>
                                                        </label>
                                                        <label
                                                            className={
                                                                values.picked === 'BA'
                                                                    ? 'payment-picked'
                                                                    : ''
                                                            }
                                                        >
                                                            <Field
                                                                type='radio'
                                                                name='picked'
                                                                value='BA'
                                                            />
                                                            <span>
                                                                <FormattedMessage id='bank.payment_ba' />
                                                            </span>
                                                        </label>
                                                        {errors.picked && touched.picked && (
                                                            <div className='input-feedback'>
                                                                {errors.picked}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className='pay-form'>
                                                    {formRef.current?.values.picked === 'BA' && (
                                                        <div className='row bank-account'>
                                                            <div className='col pl-0'>
                                                                <label>
                                                                    <FormattedMessage id='bank.name' />{' '}
                                                                    <span className='req-star'>
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <input
                                                                    id='accountName'
                                                                    placeholder='John Smith'
                                                                    type='text'
                                                                    value={values.accountName}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className={
                                                                        errors.accountName &&
                                                                        touched.accountName
                                                                            ? 'text-input error'
                                                                            : 'text-input'
                                                                    }
                                                                />
                                                                {errors.accountName &&
                                                                    touched.accountName && (
                                                                        <div className='input-feedback'>
                                                                            {errors.accountName}
                                                                        </div>
                                                                    )}
                                                            </div>
                                                            <div className='col pl-0'>
                                                                <label>
                                                                    <FormattedMessage id='bank.routing_nber' />{' '}
                                                                    <span className='req-star'>
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <input
                                                                    id='routingNumber'
                                                                    placeholder=''
                                                                    type='text'
                                                                    value={values.routingNumber}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className={
                                                                        errors.routingNumber &&
                                                                        touched.routingNumber
                                                                            ? 'text-input error'
                                                                            : 'text-input'
                                                                    }
                                                                />
                                                                {errors.routingNumber &&
                                                                    touched.routingNumber && (
                                                                        <div className='input-feedback'>
                                                                            {errors.routingNumber}
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {formRef.current?.values.picked === 'BA' && (
                                                        <div className='row bank-account'>
                                                            <div className='col pl-0'>
                                                                <label>
                                                                    <FormattedMessage id='bank.account_nber' />{' '}
                                                                    <span className='req-star'>
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <input
                                                                    id='accountNumber'
                                                                    placeholder=''
                                                                    type='text'
                                                                    value={values.accountNumber}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className={
                                                                        errors.accountNumber &&
                                                                        touched.accountNumber
                                                                            ? 'text-input error'
                                                                            : 'text-input'
                                                                    }
                                                                />
                                                                {errors.accountNumber &&
                                                                    touched.accountNumber && (
                                                                        <div className='input-feedback'>
                                                                            {errors.accountNumber}
                                                                        </div>
                                                                    )}
                                                            </div>
                                                            <div className='col pl-0'>
                                                                <label>
                                                                    <FormattedMessage id='bank.account_nber2' />{' '}
                                                                    <span className='req-star'>
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <input
                                                                    id='confirmAccountNumber'
                                                                    placeholder=''
                                                                    type='text'
                                                                    value={
                                                                        values.confirmAccountNumber
                                                                    }
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className={
                                                                        errors.confirmAccountNumber &&
                                                                        touched.confirmAccountNumber
                                                                            ? 'text-input error'
                                                                            : 'text-input'
                                                                    }
                                                                />
                                                                {errors.confirmAccountNumber &&
                                                                    touched.confirmAccountNumber && (
                                                                        <div className='input-feedback'>
                                                                            {
                                                                                errors.confirmAccountNumber
                                                                            }
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {formRef.current?.values.picked === 'BA' && (
                                                        <div className='row bank-account'>
                                                            <div className='col pl-0'>
                                                                <label>
                                                                    Account Type
                                                                    <span className='req-star'>
                                                                        *
                                                                    </span>
                                                                </label>

                                                                <div
                                                                    role='group'
                                                                    aria-labelledby='my-radio-group'
                                                                    className='account-type'
                                                                >
                                                                    <label>
                                                                        <Field
                                                                            type='radio'
                                                                            name='accountType'
                                                                            value='Checking'
                                                                        />
                                                                        <FormattedMessage id='bank.account_checking' />
                                                                    </label>
                                                                    <label>
                                                                        <Field
                                                                            type='radio'
                                                                            name='accountType'
                                                                            value='Savings'
                                                                        />
                                                                        <FormattedMessage id='bank.account_savings' />
                                                                    </label>
                                                                    {/* <div>Picked: {values.picked}</div> */}
                                                                </div>
                                                                {errors.accountType &&
                                                                    touched.accountType && (
                                                                        <div className='input-feedback'>
                                                                            {errors.accountType}
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {formRef.current?.values.picked === 'CC' && (
                                                        <div className='pay-card'>
                                                            <div className='pay-card-img'>
                                                                <img src={pay1} alt='visa' />
                                                                <img src={pay2} alt='mastercard' />
                                                                <img src={pay3} alt='amex' />
                                                            </div>
                                                        </div>
                                                    )}
                                                    {formRef.current?.values.picked === 'CC' && (
                                                        <div className='row'>
                                                            <div className='col pl-0 my-pad'>
                                                                <div className='card-num payment'>
                                                                    <label>
                                                                        <FormattedMessage id='billing.card_number' />{' '}
                                                                        <span className='req-star'>
                                                                            *
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        id='cardNumber'
                                                                        placeholder='4111 1111 1111 1111'
                                                                        type='text'
                                                                        value={values.cardNumber}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={
                                                                            errors.cardNumber &&
                                                                            touched.cardNumber
                                                                                ? 'text-input error'
                                                                                : 'text-input'
                                                                        }
                                                                        onKeyUp={handleCCKeyExp}
                                                                    />
                                                                    {errors.cardNumber &&
                                                                        touched.cardNumber && (
                                                                            <div className='input-feedback'>
                                                                                {errors.cardNumber}
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {formRef.current?.values.picked === 'CC' && (
                                                        <div className='row'>
                                                            <div className='col-md-4 pl-0 my-pad card-num payment'>
                                                                <label>
                                                                    <FormattedMessage id='billing.expiration' />{' '}
                                                                    <span className='req-star'>
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <input
                                                                    id='expDate'
                                                                    placeholder='MM / YY'
                                                                    type='text'
                                                                    value={values.expDate}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className={
                                                                        errors.expDate &&
                                                                        touched.expDate
                                                                            ? 'text-input error'
                                                                            : 'text-input'
                                                                    }
                                                                    onKeyUp={handleKeyExp}
                                                                />
                                                                {errors.expDate &&
                                                                    touched.expDate && (
                                                                        <div className='input-feedback'>
                                                                            {errors.expDate}
                                                                        </div>
                                                                    )}
                                                            </div>

                                                            <div className='col-md-3 pl-0 my-pad card-num cvv payment'>
                                                                <label>
                                                                    CVV
                                                                    <span className='cvv-txt'>
                                                                        <FormattedMessage id='billing.card_backCVV'>
                                                                            {(placeholder) => (
                                                                                <div
                                                                                    className='qua'
                                                                                    href='#'
                                                                                    data-tip={
                                                                                        placeholder
                                                                                    }
                                                                                >
                                                                                    {' '}
                                                                                    ?{' '}
                                                                                </div>
                                                                            )}
                                                                        </FormattedMessage>
                                                                    </span>
                                                                    <span className='req-star'>
                                                                        *
                                                                    </span>
                                                                    {/* <Tooltip placement="top" isOpen={tooltipOpen} target="cvv-toggle" toggle={toggle} id="toolTip" style={{top : '10px'}}>
                                                                    The CVV is the last three digits on the back of your card
                                                                </Tooltip>  */}
                                                                </label>

                                                                <ReactTooltip />

                                                                <input
                                                                    id='cardCVV'
                                                                    placeholder='123'
                                                                    type='text'
                                                                    value={values.cardCVV}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    className={
                                                                        errors.cardCVV &&
                                                                        touched.cardCVV
                                                                            ? 'text-input error'
                                                                            : 'text-input'
                                                                    }
                                                                />
                                                                {errors.cardCVV &&
                                                                    touched.cardCVV && (
                                                                        <div className='input-feedback'>
                                                                            {errors.cardCVV}
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    )}

                                                    <div className='row'>
                                                        <div className='col st-ad pl-0 my-pad-1 card-num payment'>
                                                            <label>
                                                                <FormattedMessage id='billing.address_line1' />{' '}
                                                                <span className='req-star'>*</span>
                                                            </label>
                                                            <input
                                                                id='addressLine1'
                                                                type='text'
                                                                value={values.addressLine1}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={
                                                                    errors.addressLine1 &&
                                                                    touched.addressLine1
                                                                        ? 'text-input error'
                                                                        : 'text-input'
                                                                }
                                                            />
                                                            {errors.addressLine1 &&
                                                                touched.addressLine1 && (
                                                                    <div className='input-feedback'>
                                                                        {errors.addressLine1}
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>

                                                    <div className='row'>
                                                        <div className='col st-ad pl-0 my-pad-1 card-num payment'>
                                                            <label>
                                                                <FormattedMessage id='billing.address_line2' />{' '}
                                                            </label>
                                                            <input
                                                                id='addressLine2'
                                                                type='text'
                                                                value={values.addressLine2}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={
                                                                    errors.addressLine2 &&
                                                                    touched.addressLine2
                                                                        ? 'text-input error'
                                                                        : 'text-input'
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='row'>
                                                        <div className='Country col-l st-ad pl-0 my-pad-1 card-num'>
                                                            <label>
                                                                <FormattedMessage id='billing.country' />{' '}
                                                                <span className='req-star'>*</span>
                                                            </label>
                                                            <div className='select-box'>
                                                                <div className='down-arrow'></div>
                                                                <CountrySelect
                                                                    label='Country'
                                                                    name='country'
                                                                    className='sel-country'
                                                                >
                                                                    <FormattedMessage id='billing.country_select'>
                                                                        {(placeholder) => (
                                                                            <option value=''>
                                                                                {placeholder}...
                                                                            </option>
                                                                        )}
                                                                    </FormattedMessage>

                                                                    <option value='CA'>
                                                                        Canada
                                                                    </option>
                                                                    <option value='US'>US</option>
                                                                    <option value='Other'>
                                                                        Other
                                                                    </option>
                                                                </CountrySelect>
                                                            </div>
                                                        </div>

                                                        {countrySelected === 'Other' && (
                                                            <>
                                                                {/* <div className="Country col-l st-ad pl-0 my-pad-1 country-2" style={{visibility: countrySelected === 'Other' ? 'visible': 'hidden'}}> */}
                                                                <div className='Country col-l st-ad pl-0 my-pad-1 country-2'>
                                                                    <label>
                                                                        <span
                                                                            style={{
                                                                                visibility:
                                                                                    'hidden',
                                                                            }}
                                                                        >
                                                                            Country
                                                                        </span>
                                                                    </label>
                                                                    <input
                                                                        id='country2'
                                                                        type='text'
                                                                        value={values.country2}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        className={
                                                                            errors.country2 &&
                                                                            touched.country2
                                                                                ? 'text-input error'
                                                                                : 'text-input'
                                                                        }
                                                                    />
                                                                    {errors.country2 &&
                                                                        touched.country2 && (
                                                                            <div className='input-feedback'>
                                                                                {errors.country2}
                                                                            </div>
                                                                        )}
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>

                                                    <div className='row'>
                                                        {countrySelected !== 'Other' && (
                                                            <div className='col st-ad pl-0 my-pad-1 city-name payment'>
                                                                <label>
                                                                    <FormattedMessage id='billing.province_state' />{' '}
                                                                    <span className='req-star'>
                                                                        *
                                                                    </span>
                                                                </label>

                                                                <div className='select-box'>
                                                                    <div className='down-arrow'></div>
                                                                    {/* set the value of Province to be used (value = {provinceValue}) */}
                                                                    <ProvinceSelect
                                                                        label='Province'
                                                                        name='provinceState'
                                                                        className='sel-province'
                                                                        id='provinceSelect'
                                                                        onChange={
                                                                            handleOptionChange
                                                                        }
                                                                        value={provinceValue}
                                                                        data-test={provinceValue}
                                                                    >
                                                                        {countrySelected === '' && (
                                                                            <FormattedMessage id='billing.country_select'>
                                                                                {(placeholder) => (
                                                                                    <option value=''>
                                                                                        {
                                                                                            placeholder
                                                                                        }
                                                                                        ...
                                                                                    </option>
                                                                                )}
                                                                            </FormattedMessage>
                                                                        )}
                                                                        {countrySelected === 'CA' &&
                                                                            tax_ca.map(
                                                                                (
                                                                                    province,
                                                                                    index
                                                                                ) => {
                                                                                    return (
                                                                                        <option
                                                                                            value={
                                                                                                province[
                                                                                                    'abbreviation'
                                                                                                ]
                                                                                            }
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                province[
                                                                                                    'name'
                                                                                                ]
                                                                                            }
                                                                                        </option>
                                                                                    );
                                                                                }
                                                                            )}

                                                                        {countrySelected === 'US' &&
                                                                            tax_us.map(
                                                                                (
                                                                                    province,
                                                                                    index
                                                                                ) => {
                                                                                    return (
                                                                                        <option
                                                                                            value={
                                                                                                province[
                                                                                                    'abbreviation'
                                                                                                ]
                                                                                            }
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                province[
                                                                                                    'name'
                                                                                                ]
                                                                                            }
                                                                                        </option>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        {/* <option value="CA">Alberta</option>
                                                                <option value="US">Quebec</option> */}
                                                                        {/* </MySelect> */}
                                                                    </ProvinceSelect>

                                                                    <FormattedMessage id='billing.country_select'>
                                                                        {(placeholder) => (
                                                                            <select id='test-taxid'>
                                                                                <option>
                                                                                    {placeholder}...
                                                                                </option>
                                                                            </select>
                                                                        )}
                                                                    </FormattedMessage>

                                                                    {/* <select id="test-taxid"><option>Select here test...</option></select> */}
                                                                </div>
                                                            </div>
                                                        )}

                                                        <div className='col pr-0  ad my-pad-1 city-name payment'>
                                                            <label>
                                                                <FormattedMessage id='billing.city_select' />{' '}
                                                                <span className='req-star'>*</span>
                                                            </label>
                                                            <input
                                                                id='city'
                                                                type='text'
                                                                value={values.city}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={
                                                                    errors.city && touched.city
                                                                        ? 'text-input error'
                                                                        : 'text-input'
                                                                }
                                                            />
                                                            {errors.city && touched.city && (
                                                                <div className='input-feedback'>
                                                                    {errors.city}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className='row'>
                                                        <div className='col st-ad pl-0 my-pad-1 card-num payment'>
                                                            <label>
                                                                <FormattedMessage id='billing.postal_code' />{' '}
                                                                <span className='req-star'>*</span>
                                                            </label>
                                                            <input
                                                                id='postalCode'
                                                                type='text'
                                                                value={values.postalCode}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={
                                                                    errors.postalCode &&
                                                                    touched.postalCode
                                                                        ? 'text-input error'
                                                                        : 'text-input'
                                                                }
                                                            />
                                                            {errors.postalCode &&
                                                                touched.postalCode && (
                                                                    <div className='input-feedback'>
                                                                        {errors.postalCode}
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Change the styling of the element on responsive, add class depending of width state */}
                                        <div
                                            className={
                                                (width > 990 ? 'col-md-12' : 'repos col-md-11') +
                                                ' col-lg-6 pr-0 my-pl ' +
                                                (legacyQuote ? 'legacyFlex ' : '') +
                                                (hideItemList ? 'row-billing-hide ' : '') +
                                                'item-list'
                                            }
                                            style={{textAlign: 'left'}}
                                        >
                                            {!hideItemList && <AddonHeaderView />}

                                            {onboardInfo[2]['order_values'].map((order, index) =>
                                                // only show the Addon that matches one the following conditions:
                                                // 1. order is of type [Standard]
                                                // 2. order is of type [Extra] and has the property 'enabled' to true
                                                order['type'] === '[Standard]' ? (
                                                    <AddonViewOnly
                                                        addonName={order['name']}
                                                        price={order['price'] * order['quantity']}
                                                        quantity={order['quantity']}
                                                        discount={order['discount']}
                                                        discountPercent={
                                                            order['hs_discount_percentage']
                                                        }
                                                        currencyCode={onBoardCurrency}
                                                        key={index}
                                                    />
                                                ) : (
                                                    order['type'] === '[Extra]' &&
                                                    order['enabled'] === true && (
                                                        // order['type'] === '[Extra]' && order['enabled'] === true ? (
                                                        <AddonViewOnly
                                                            addonName={order['name']}
                                                            price={
                                                                order['price'] * order['quantity']
                                                            }
                                                            quantity={order['quantity']}
                                                            discount={order['discount']}
                                                            discountPercent={
                                                                order['hs_discount_percentage']
                                                            }
                                                            currencyCode={onBoardCurrency}
                                                            key={index}
                                                        />
                                                    )

                                                    // : (
                                                    //     <div> Add-on view error. Please contact your administrator.</div>
                                                    // )
                                                )
                                            )}
                                            {/* {
                                ((quoteInfo[2]['order_values'].length === 0)) ? (
                                    <div>Onboarding info is empty. Please contact Administrator.</div>
                                ) : (
                                    quoteInfo[2]['order_values'].map((order, index) => (
                                        <AddonViewOnly addonName={order['name']} price={order['price']*order['quantity']} quantity={order['quantity'] } key={index}/>
                                    ))
                                    
                                )
                            } */}

                                            {
                                                // onboardStatus == "idle" ? (
                                                onboardStatus === 'idle' && rotationUnit === 0
                                                    ? quoteInfo[2]['order_values'].map(
                                                          (order, index) => (
                                                              <AddonViewOnly
                                                                  addonName={order['name']}
                                                                  price={
                                                                      order['price'] *
                                                                      order['quantity']
                                                                  }
                                                                  quantity={order['quantity']}
                                                                  discount={order['discount']}
                                                                  discountPercent={
                                                                      order[
                                                                          'hs_discount_percentage'
                                                                      ]
                                                                  }
                                                                  currencyCode={onBoardCurrency}
                                                                  key={index}
                                                              />
                                                          )
                                                      )
                                                    : //only show the load.quote_calc message when onboardStatus is on loading
                                                      onboardStatus === 'loading' && (
                                                          <div>
                                                              <FormattedMessage id='load.quote_calc' />
                                                          </div>
                                                      )
                                            }

                                            {legacyQuote && (
                                                <div>
                                                    <FormattedMessage id='load.legacy_msg' />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {!legacyQuote && (
                                        <div className='flx'>
                                            <div className='col-md-12 col-lg-6 pr-0 my-pl or-2'>
                                                <div className='btn-total-1'>
                                                    <div className='total'>
                                                        <h1>
                                                            <FormattedMessage id='order.sub_total' />
                                                            :
                                                        </h1>
                                                        {/* <div className="price">$0<span></span></div></div></div> */}
                                                        <div className='price'>
                                                            {
                                                                currency['symbols'][
                                                                    onBoardCurrency
                                                                ]['symbol']
                                                            }
                                                            {onboardInfo[2]?.totalOrder ? (
                                                                <span className='onb-subtotal'>
                                                                    {parseFloat(
                                                                        onboardInfo[2].totalOrder
                                                                    ).toFixed(2)}
                                                                </span>
                                                            ) : (
                                                                // : <span>{order.totalOrder}</span>
                                                                // : <span className="onb-subtotal">quoteTotal{quoteTotal}</span>
                                                                <span className='onb-subtotal'>
                                                                    {parseFloat(quoteTotal).toFixed(
                                                                        2
                                                                    )}
                                                                </span>
                                                            )}

                                                            <span>
                                                                {
                                                                    currency['symbols'][
                                                                        onBoardCurrency
                                                                    ]['code']
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span
                                                    id='test-tax'
                                                    style={{display: 'none'}}
                                                ></span>
                                                {taxesList.length > 0 && (
                                                    <div className='btn-total-2'>
                                                        <div className='total'>
                                                            <h1>Taxes:</h1>
                                                            <div className='price'>
                                                                <span>
                                                                    <span></span>
                                                                </span>
                                                                <span
                                                                    id='test-tax'
                                                                    style={{opacity: 0}}
                                                                ></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {/* Get the list of taxes associated with this province/state */}
                                                {taxesList.map((taxElement, index) => {
                                                    // taxesListTest.map((taxElement, index) => {

                                                    return (
                                                        <div className='btn-total-2' key={index}>
                                                            <div className='total'>
                                                                {/* <h1>{taxElement['name']}</h1> */}
                                                                <h1
                                                                    id='taxName'
                                                                    title={
                                                                        tax['type'][
                                                                            taxElement['name']
                                                                        ]['code']
                                                                    }
                                                                >
                                                                    {taxElement['name']}{' '}
                                                                    <span className='tax-code'>
                                                                        {' '}
                                                                        [
                                                                        {
                                                                            tax['type'][
                                                                                taxElement['name']
                                                                            ]['code']
                                                                        }
                                                                        ]
                                                                    </span>
                                                                    :{' '}
                                                                </h1>
                                                                <div className='price'>
                                                                    <span>
                                                                        <span>
                                                                            {
                                                                                currency['symbols'][
                                                                                    onBoardCurrency
                                                                                ]['symbol']
                                                                            }
                                                                        </span>
                                                                        {taxElement['unitPrice']}
                                                                    </span>
                                                                    <span>
                                                                        {
                                                                            currency['symbols'][
                                                                                onBoardCurrency
                                                                            ]['code']
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}

                                                <div className='btn-total-2'>
                                                    <div className='total'>
                                                        <h1>
                                                            <FormattedMessage id='billing.order_total' />
                                                        </h1>
                                                        <div className='price'>
                                                            {
                                                                onboardInfo[2]?.totalOrder ? (
                                                                    <span className='onb-total'>
                                                                        {' '}
                                                                        {
                                                                            currency['symbols'][
                                                                                onBoardCurrency
                                                                            ]['symbol']
                                                                        }
                                                                        {parseFloat(
                                                                            parseFloat(
                                                                                onboardInfo[2]
                                                                                    .totalOrder
                                                                            ) + parseFloat(taxTotal)
                                                                        ).toFixed(2)}
                                                                    </span>
                                                                ) : (
                                                                    <span className='onb-total'>
                                                                        {' '}
                                                                        {
                                                                            currency['symbols'][
                                                                                onBoardCurrency
                                                                            ]['symbol']
                                                                        }
                                                                        {parseFloat(
                                                                            parseFloat(quoteTotal) +
                                                                                taxTotal
                                                                        ).toFixed(2)}
                                                                    </span>
                                                                ) //had to parseFloat quoteTotal before adding it up with taxTotal otherwise it will be string concanated
                                                                // tax total : {taxTotal} quotetotal : {quoteTotal}
                                                            }
                                                            <span>
                                                                {
                                                                    currency['symbols'][
                                                                        onBoardCurrency
                                                                    ]['code']
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* do not remove: dummy column for keeping bootstrap formatting from breaking */}
                                            <div className='col-md-12 col-lg-6 pl-0 my-pr or-1'>
                                                <div></div>
                                                <div></div>
                                            </div>
                                        </div>
                                    )}

                                    {/* Terms and conditions do not apply to legacy quotes */}
                                    {!legacyQuote && (
                                        <div className='col-md-12 col-lg-6 pl-0 my-pr or-1'>
                                            <div className='trial-msg'>
                                                {/* <p><FormattedMessage id="billing.trial_method" /> </p> */}
                                                <p>
                                                    <FormattedMessage id='billing.invoice_message' />{' '}
                                                </p>
                                            </div>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    onClick={handleCheck}
                                                ></input>
                                                <span> </span>
                                                <FormattedMessage id='payment.terms1' />

                                                <FormattedMessage id='payment.terms_link'>
                                                    {(placeholder) => (
                                                        <a
                                                            href={placeholder}
                                                            target='_blank'
                                                            rel='noopener noreferrer'
                                                        >
                                                            <FormattedMessage id='payment.terms2' />
                                                        </a>
                                                    )}
                                                </FormattedMessage>
                                            </div>

                                            <div className='pay-con'>
                                                <button
                                                    type='submit'
                                                    disabled={termsChecked ? '' : 'disabled'}
                                                >
                                                    {submitStatus === 'waiting' ? (
                                                        <div width='145px'>
                                                            <img
                                                                src={loading_reverse}
                                                                height='40px'
                                                                alt='loading subscribe'
                                                            />
                                                        </div>
                                                    ) : (
                                                        <FormattedMessage id='billing.subscribe' />
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </form>
                            )}
                        </Formik>
                    )
                }

                {onboardStatus === 'loading' && (
                    <div>
                        <img src={loading} alt='loading onboard' />
                    </div>
                )}
            </div>
        </>
    );
};

export default QuoteBillingInfo;
