import React, {useContext} from 'react';
import {FormattedMessage} from 'react-intl';
import {OnBoardContext} from '../../contexts/onboardContext';
import '../../assets/css/contactInfoView.css';
import {Formik} from 'formik';
import * as Yup from 'yup';
import Moment from 'moment';

const ContactInfoView = ({onStageChange}) => {
    //will be used in the future
    const {onboardInfo, setOnBoardInfo} = useContext(OnBoardContext);

    const hasChild = (obj) => {
        return !!Object.keys(obj).length;
    };

    const checkNested = (obj, level, ...rest) => {
        if (obj === undefined) return false;
        if (rest.length == 0 && obj.hasOwnProperty(level)) return true;
        return checkNested(obj[level], ...rest);
    };
    const isEmpty = (obj) => {
        if (obj === undefined) return false;
        return Object.keys(obj).length === 0;
    };

    let form = <></>;

    let formik_form = (
        <Formik
            initialValues={{firstname: '', lastname: '', email: '', company: '', phone: ''}}
            validationSchema={Yup.object({
                firstname: Yup.string()
                    .max(15, <FormattedMessage id='error.char15' />)
                    .required(<FormattedMessage id='error.required' />),
                lastname: Yup.string()
                    .max(20, <FormattedMessage id='error.char20' />)
                    .required(<FormattedMessage id='error.required' />),
                email: Yup.string()
                    .email(<FormattedMessage id='error.email' />)
                    .required(<FormattedMessage id='error.required' />),
                company: Yup.string().required(<FormattedMessage id='error.required' />),
                phone: Yup.string()
                    .min(10, <FormattedMessage id='error.char10' />)
                    .required(<FormattedMessage id='error.required' />),
            })}
            onSubmit={(values, {setSubmitting}) => {
                setTimeout(() => {
                    // alert(JSON.stringify(values, null, 2));
                    setSubmitting(false);

                    const dataOnboard = onboardInfo;
                    dataOnboard[4]['contacts'].push(values);
                    // dataOnboard[3]['company'] = {'name' : dataOnboard[4]['contacts']['companyName']};
                    // delete dataOnboard[4]['contacts']['companyName'];

                    setOnBoardInfo(dataOnboard);

                    onStageChange('quoteBillingInfo');
                }, 400);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
            }) => (
                <form onSubmit={handleSubmit} id='form1' className='quote-form'>
                    <label htmlFor='firstname'>
                        <FormattedMessage id='payment.first_name' />:
                    </label>
                    <input
                        id='firstname'
                        placeholder='John'
                        type='text'
                        value={values.firstname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                            errors.firstname && touched.firstname
                                ? 'text-input error'
                                : 'text-input'
                        }
                    />

                    {touched.firstname && errors.firstname ? (
                        <div className='err-form'>{errors.firstname}</div>
                    ) : null}

                    <label htmlFor='lastname'>
                        <FormattedMessage id='payment.last_name' />:
                    </label>

                    <input
                        id='lastname'
                        placeholder='Smith'
                        type='text'
                        value={values.lastname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                            errors.lastname && touched.lastname ? 'text-input error' : 'text-input'
                        }
                    />
                    {touched.lastname && errors.lastname ? (
                        <div className='err-form'>{errors.lastname}</div>
                    ) : null}

                    <label htmlFor='email'>
                        <FormattedMessage id='payment.email' />:
                    </label>
                    <input
                        id='email'
                        placeholder='test@email.com'
                        type='text'
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={
                            errors.email && touched.email ? 'text-input error' : 'text-input'
                        }
                    />
                    {touched.email && errors.email ? (
                        <div className='err-form'>{errors.email}</div>
                    ) : null}
                    <label htmlFor='company'>
                        <FormattedMessage id='payment.company' />:
                    </label>
                    <FormattedMessage id='placeholder.company_name'>
                        {(placeholder) => (
                            <input
                                id='company'
                                placeholder={String(placeholder)}
                                type='text'
                                value={values.company}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={
                                    errors.company && touched.company
                                        ? 'text-input error'
                                        : 'text-input'
                                }
                            />
                        )}
                    </FormattedMessage>
                    {touched.company && errors.company ? (
                        <div className='err-form'>{errors.company}</div>
                    ) : null}

                    <label htmlFor='phone'>
                        <FormattedMessage id='payment.phone' />:
                    </label>

                    <FormattedMessage id='placeholder.phone'>
                        {(placeholder) => (
                            <input
                                id='phone'
                                placeholder={String(placeholder)}
                                type='text'
                                value={values.phone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={
                                    errors.phone && touched.phone
                                        ? 'text-input error'
                                        : 'text-input'
                                }
                            />
                        )}
                    </FormattedMessage>
                    {touched.phone && errors.phone ? (
                        <div className='err-form'>{errors.phone}</div>
                    ) : null}
                    {/* <button type="submit">Submit</button> */}
                </form>
            )}
        </Formik>
    );

    let testForm = (
        <Formik
            initialValues={{firstname: '', lastname: '', email: '', company: '', phone: ''}}
            validationSchema={Yup.object({
                firstname: Yup.string()
                    .max(15, <FormattedMessage id='error.char15' />)
                    .required(<FormattedMessage id='error.required' />),
                lastname: Yup.string()
                    .max(20, <FormattedMessage id='error.char20' />)
                    .required(<FormattedMessage id='error.required' />),
                email: Yup.string()
                    .email(<FormattedMessage id='error.email' />)
                    .required(<FormattedMessage id='error.required' />),
                company: Yup.string().required(<FormattedMessage id='error.required' />),
                phone: Yup.string()
                    .min(10, <FormattedMessage id='error.char10' />)
                    .required(<FormattedMessage id='error.required' />),
            })}
            onSubmit={(values, {setSubmitting}) => {
                setTimeout(() => {
                    // alert(JSON.stringify(values, null, 2));
                    setSubmitting(false);

                    const dataOnboard = onboardInfo;
                    dataOnboard[4]['contacts'].push(values);
                    // dataOnboard[3]['company'] = {'name' : dataOnboard[4]['contacts']['companyName']};
                    // delete dataOnboard[4]['contacts']['companyName'];

                    setOnBoardInfo(dataOnboard);

                    onStageChange('quoteBillingInfo');
                }, 400);
            }}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
            }) => (
                <form onSubmit={handleSubmit} id='form1' className='quote-form'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='check-input'>
                                <label htmlFor='firstname'>
                                    <FormattedMessage id='payment.first_name' />:
                                </label>
                                <input
                                    id='firstname'
                                    placeholder='John'
                                    type='text'
                                    value={values.firstname}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                        errors.firstname && touched.firstname
                                            ? 'text-input error'
                                            : 'text-input'
                                    }
                                />

                                {touched.firstname && errors.firstname ? (
                                    <div className='err-form'>{errors.firstname}</div>
                                ) : null}
                            </div>

                            <div className='check-input'>
                                <label htmlFor='lastname'>
                                    <FormattedMessage id='payment.last_name' />:
                                </label>

                                <input
                                    id='lastname'
                                    placeholder='Smith'
                                    type='text'
                                    value={values.lastname}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                        errors.lastname && touched.lastname
                                            ? 'text-input error'
                                            : 'text-input'
                                    }
                                />
                                {touched.lastname && errors.lastname ? (
                                    <div className='err-form'>{errors.lastname}</div>
                                ) : null}
                            </div>

                            <div className='check-input'>
                                <label htmlFor='email'>
                                    <FormattedMessage id='payment.email' />:
                                </label>
                                <input
                                    id='email'
                                    placeholder='test@email.com'
                                    type='text'
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                        errors.email && touched.email
                                            ? 'text-input error'
                                            : 'text-input'
                                    }
                                />
                                {touched.email && errors.email ? (
                                    <div className='err-form'>{errors.email}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='check-input'>
                                <label htmlFor='company'>
                                    <FormattedMessage id='payment.company' />:
                                </label>
                                <FormattedMessage id='placeholder.company_name'>
                                    {(placeholder) => (
                                        <input
                                            id='company'
                                            placeholder={String(placeholder)}
                                            type='text'
                                            value={values.company}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={
                                                errors.company && touched.company
                                                    ? 'text-input error'
                                                    : 'text-input'
                                            }
                                        />
                                    )}
                                </FormattedMessage>
                                {touched.company && errors.company ? (
                                    <div className='err-form'>{errors.company}</div>
                                ) : null}
                            </div>
                            <div className='check-input'>
                                <label htmlFor='phone'>
                                    <FormattedMessage id='payment.phone' />:
                                </label>

                                <FormattedMessage id='placeholder.phone'>
                                    {(placeholder) => (
                                        <input
                                            id='phone'
                                            placeholder={String(placeholder)}
                                            type='text'
                                            value={values.phone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={
                                                errors.phone && touched.phone
                                                    ? 'text-input error'
                                                    : 'text-input'
                                            }
                                        />
                                    )}
                                </FormattedMessage>
                                {touched.phone && errors.phone ? (
                                    <div className='err-form'>{errors.phone}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    {/* <button type="submit">Submit</button> */}
                </form>
            )}
        </Formik>
    );

    let companyInfo = (
        <div className='column-info'>
            <ul className='company-info'>
                <li style={{fontWeight: 'bold'}}>{onboardInfo[3]['company']['name']}</li>
                {/* <li style={{fontWeight: "bold"}} >{onboardInfo[4]['contacts'][0]['company']}</li> */}
                {
                    // double check first if the company exists
                    onboardInfo[3]['company'] !== null && (
                        <li>
                            {onboardInfo[3]['company']['address']}

                            {onboardInfo[3]['company']['city'] && (
                                <span>, {onboardInfo[3]['company']['city']}</span>
                            )}
                            {onboardInfo[3]['company']['state'] && (
                                <span>, {onboardInfo[3]['company']['state']}</span>
                            )}
                            {onboardInfo[3]['company']['country'] && (
                                <span>, {onboardInfo[3]['company']['country']}</span>
                            )}
                        </li>
                    )
                }
                <li>{onboardInfo[3]['company']['zip']}</li>
            </ul>
        </div>
    );

    //Defensive rendering:
    //1. make sure that contacts from onBoardInfo exists
    //2. make sure that at least one contact exists
    //3. make sure that there is a firstname available to use
    if (onboardInfo[4] !== undefined) {
        if (!isEmpty(onboardInfo[4]['contacts'][0])) {
            // if('firstname' in onboardInfo[4]['contacts'][0]){
            if (checkNested(onboardInfo[4]['contacts'][0], 'firstname')) {
                form = (
                    <>
                        <div style={{textAlign: 'left'}} className='contact-info'>
                            {/* <label>Company: </label> */}
                            {companyInfo}

                            <div className='column-info'>
                                {/* {
                            onboardInfo[4]['contacts'][0]['phone'] !== null &&
                            <>
                              <label></label>
                              <input type="text" defaultValue={`${onboardInfo[4]['contacts'][0]['phone']} `} style={{}} readOnly/>                      
                              <br/>
                            </>

                          } */}

                                <ul className='company-info'>
                                    <li style={{fontWeight: 'bold'}}>
                                        <span>
                                            {onboardInfo[4]['contacts'][0]['firstname']}{' '}
                                            {onboardInfo[4]['contacts'][0]['lastname']}
                                        </span>
                                    </li>
                                    {onboardInfo[4]['contacts'][0]['jobtitle'] && (
                                        <li>
                                            <span>{onboardInfo[4]['contacts'][0]['jobtitle']}</span>
                                        </li>
                                    )}
                                    {onboardInfo[4]['contacts'][0]['email'] && (
                                        <li>
                                            <span>{onboardInfo[4]['contacts'][0]['email']}</span>
                                        </li>
                                    )}
                                    {onboardInfo[4]['contacts'][0]['phone'] && (
                                        <li>
                                            <span>{onboardInfo[4]['contacts'][0]['phone']}</span>
                                        </li>
                                    )}
                                </ul>
                            </div>

                            <div className='column-info'>
                                {/* <label></label>
                          <input type="text" defaultValue={`Reference : ${onboardInfo[6]['quote_status']['hs_object_id']}`} style={{fontWeight: "bold"}} readOnly/>
                          <br/>
                          <label></label>
                          <input type="text" defaultValue={`Quote created: ${Moment.utc(onboardInfo[6]['quote_status']['hs_createdate']).format('MMMM DD, YYYY')}`} style={{}} readOnly/>
                          <br/>
                          <label></label>
                          <input type="text" defaultValue={`Quote expires: ${Moment.utc(onboardInfo[6]['quote_status']['hs_expiration_date']).format('MMMM DD, YYYY')}`} style={{}} readOnly/>
                          <br/>
                          <label></label>
                          <input type="text" defaultValue={`Quote created by: ${onboardInfo[5]['owner']['firstname']} ${onboardInfo[5]['owner']['lastname']}`} style={{}} readOnly/>
                          <br/>
                          <label></label>
                          <input type="text" defaultValue={`${onboardInfo[5]['owner']['jobtitle']}`} style={{}} readOnly/>
                          <br/>
                          <label></label>
                          <input type="text" defaultValue={`${onboardInfo[5]['owner']['email']}`} style={{}} readOnly/>
                          <br/>
                          <label></label>
                          <input type="text" defaultValue={`${onboardInfo[5]['owner']['phone']}`} style={{}} readOnly/>
                          <br/>            */}

                                <ul className='company-info'>
                                    <li style={{fontWeight: 'bold'}}>
                                        <span>
                                            Reference:{' '}
                                            {onboardInfo[6]['quote_status']['hs_object_id']}
                                        </span>
                                    </li>
                                    {onboardInfo[6]['quote_status']['hs_createdate'] && (
                                        <li>
                                            <span>
                                                Quote created:{' '}
                                                {Moment.utc(
                                                    onboardInfo[6]['quote_status']['hs_createdate']
                                                ).format('MMMM DD, YYYY')}
                                            </span>
                                        </li>
                                    )}
                                    {onboardInfo[6]['quote_status']['hs_expiration_date'] && (
                                        <li>
                                            <span>
                                                Quote expires:{' '}
                                                {Moment.utc(
                                                    onboardInfo[6]['quote_status'][
                                                        'hs_expiration_date'
                                                    ]
                                                ).format('MMMM DD, YYYY')}
                                            </span>
                                        </li>
                                    )}
                                    {onboardInfo[5]['owner']['jobtitle'] && (
                                        <li>
                                            {' '}
                                            <span>
                                                Quote created by:{' '}
                                                {onboardInfo[5]['owner']['firstname']}{' '}
                                                {onboardInfo[5]['owner']['lastname']}
                                            </span>
                                        </li>
                                    )}
                                    <li>{onboardInfo[5]['owner']['jobtitle']}</li>
                                    {onboardInfo[5]['owner']['email'] && (
                                        <li>
                                            <span>{onboardInfo[5]['owner']['email']}</span>
                                        </li>
                                    )}
                                    {onboardInfo[5]['owner']['phone'] && (
                                        <li>
                                            <span>{onboardInfo[5]['owner']['phone']}</span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </>
                );
            } else {
                form = (
                    <>
                        <div style={{textAlign: 'left'}} className='contact-info'>
                            {/* <label>Company: </label> */}

                            {onboardInfo[3]['company'].length > 0 && (
                                <div className='column-info'>
                                    <label></label>
                                    <input
                                        type='text'
                                        defaultValue={`${onboardInfo[3]['company']['name']}`}
                                        style={{fontWeight: 'bold'}}
                                        readOnly
                                    />
                                    <br />
                                    <label></label>
                                    <input
                                        type='text'
                                        defaultValue={`${onboardInfo[3]['company']['address']}`}
                                        style={{}}
                                        readOnly
                                    />
                                    <br />
                                    <label></label>
                                    <input
                                        type='text'
                                        defaultValue={`${onboardInfo[3]['company']['city']} ${onboardInfo[3]['company']['state']} ${onboardInfo[3]['company']['country']}`}
                                        style={{}}
                                        readOnly
                                    />
                                    {/* <div>{`${onboardInfo[3]['company']['city']} ${onboardInfo[3]['company']['state']} ${onboardInfo[3]['company']['country']}`}  </div> */}
                                    <br />
                                    <label></label>
                                    <input
                                        type='text'
                                        defaultValue={`${onboardInfo[3]['company']['zip']} `}
                                        style={{}}
                                        readOnly
                                    />
                                    <br />
                                </div>
                            )}

                            <div className='column-info'>
                                <label></label>
                                <input
                                    type='text'
                                    defaultValue={`Reference : ${onboardInfo[6]['quote_status']['hs_object_id']}`}
                                    style={{fontWeight: 'bold'}}
                                    readOnly
                                />
                                <br />
                                <label></label>
                                <input
                                    type='text'
                                    defaultValue={`Quote created: ${Moment.utc(
                                        onboardInfo[6]['quote_status']['hs_createdate']
                                    ).format('MMMM DD, YYYY')}`}
                                    style={{}}
                                    readOnly
                                />
                                <br />
                                <label></label>
                                <input
                                    type='text'
                                    defaultValue={`Quote expires: ${Moment.utc(
                                        onboardInfo[6]['quote_status']['hs_expiration_date']
                                    ).format('MMMM DD, YYYY')}`}
                                    style={{}}
                                    readOnly
                                />
                                <br />
                                <label></label>
                                <input
                                    type='text'
                                    defaultValue={`Quote created by: ${onboardInfo[5]['owner']['firstname']} ${onboardInfo[5]['owner']['lastname']}`}
                                    style={{}}
                                    readOnly
                                />
                                <br />
                                <label></label>
                                <input
                                    type='text'
                                    defaultValue={`${onboardInfo[5]['owner']['jobtitle']}`}
                                    style={{}}
                                    readOnly
                                />
                                <br />
                                <label></label>
                                <input
                                    type='text'
                                    defaultValue={`${onboardInfo[5]['owner']['email']}`}
                                    style={{}}
                                    readOnly
                                />
                                <br />
                                <label></label>
                                <input
                                    type='text'
                                    defaultValue={`${onboardInfo[5]['owner']['phone']}`}
                                    style={{}}
                                    readOnly
                                />
                                <br />
                            </div>
                        </div>
                        {formik_form}
                    </>
                );
            }
        } else {
            //
        }
    } else {
        //
    }

    return <>{form}</>;
};

export default ContactInfoView;
