import React from 'react';
import {FormattedMessage} from 'react-intl';

const AddonHeader = ({type}) => {
    return (
        <div className={`add-on-con header ${type === 'cust-quote' ? 'quote' : ''}`}>
            <div className={`add-on-name ${type === 'cust-quote' ? 'quote' : ''}`}>Item</div>
            <div className={`add-on-side ${type === 'cust-quote' ? 'quote' : ''}`}>
                <div className={`add-on-qty ${type === 'cust-quote' ? 'quote' : ''}`}>
                    {/* Quantity */}
                    <FormattedMessage id={`billing.item_qty`} />
                </div>
                <div className={`add-on-test ${type === 'cust-quote' ? 'quote' : ''}`}>
                    <FormattedMessage id='billing.item_unitPrice' />
                </div>
                <div className={`add-on-prc head ${type === 'cust-quote' ? 'quote' : ''}`}>
                    {/* Item total */}
                    <FormattedMessage id={`billing.item_total`} />
                </div>
            </div>
        </div>
    );
};

export default AddonHeader;
