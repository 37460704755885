import React, {useContext} from 'react';
import '../../assets/css/addonViewOnly.css';
import {FormattedMessage} from 'react-intl';
import currency from '../../currency/currency.json';
import {OnBoardContext} from '../../contexts/onboardContext';

const AddonCustomQuote = ({
    addonName,
    quantity,
    price,
    unitPrice,
    discount,
    discountPercent,
    currencyCode,
    itemType,
}) => {
    const {onboardInfo} = useContext(OnBoardContext);

    let itemDiscount;

    if (discount) {
        itemDiscount = (
            <div className='disc'>
                <FormattedMessage id='order.discount' /> ${parseInt(discount).toFixed(2)}{' '}
                <FormattedMessage id='order.per_item' />
            </div>
        );
    } else if (discountPercent) {
        itemDiscount = (
            <div className='disc'>
                <FormattedMessage id='order.discount' /> {discountPercent}%{' '}
            </div>
        );
    } else {
        itemDiscount = <></>;
    }

    const applyDiscount = (originalPrice, discountValue, discountType, quantity) => {
        let newPrice = 0;
        if (discountType === '%') {
            newPrice = originalPrice - (originalPrice * discountValue) / 100;
        }

        if (discountType === '-') {
            newPrice = originalPrice - discountValue * quantity;
        }

        return newPrice;
    };

    const processBrackets = (addonString) => {
        let result = '';

        if (addonString.includes('[Standard]')) {
            result = String(addonString).split('[Standard] ');
            return result[1];
        } else if (addonString.includes('[Monthly]')) {
            result = String(addonString).split('[Monthly] ');
            return result[1];
        } else if (addonString.includes('[Yearly]')) {
            result = String(addonString).split('[Yearly] ');
            return result[1];
        } else if (addonString.includes('[Annuel]')) {
            result = String(addonString).split('[Annuel] ');
            return result[1];
        } else if (addonString.includes('[Mensuel]')) {
            result = String(addonString).split('[Mensuel] ');
            return result[1];
        } else if (addonString.includes('[Extra]')) {
            result = String(addonString).replace('[Extra] ', 'Extra - ');
            return result;
        } else {
            return addonString; // rectified to return addonString, the origin name of the addon, if the text does not contain any brackets (this return is needed by custom quotes, since some names do not contain brackets)
        }
    };

    // if(addonName.includes('Croo Talk')){
    //     addonName.replace('Croo Talk', 'Cloud PBX');
    // }

    // if (!addonName.includes('Croo Talk') && onboardInfo[0].planCode === 'cust-quote') {
    if (!addonName.includes('Croo Talk - ') && onboardInfo[0].planCode === 'cust-quote') {
        //display the item ONLY if it's not the croo talk plan name itself
        return (
            <div className='add-on-con view'>
                <div className='name-view'>{processBrackets(addonName)}</div>
                {price > 0 ? (
                    <>
                        <div className='qty-view'>
                            <span></span>
                            <input type='text' disabled defaultValue={quantity} />
                        </div>
                        <div className='unit-prc-view'>
                            {' '}
                            <span>
                                <span className='prc-value'>
                                    {currency['symbols'][currencyCode]['symbol']}
                                    {unitPrice.toFixed(2)}
                                </span>{' '}
                                <span className='prc-code'>
                                    {currency['symbols'][currencyCode]['code']}
                                </span>
                            </span>
                        </div>
                        {discountPercent && (
                            <div className='prc-view'>
                                {' '}
                                <span>
                                    {' '}
                                    <span>{currency['symbols'][currencyCode]['symbol']}</span>
                                    <span className='prc-value'>
                                        {applyDiscount(
                                            price,
                                            discountPercent,
                                            '%',
                                            quantity
                                        ).toFixed(2)}
                                    </span>{' '}
                                    <span className='prc-code'>
                                        {currency['symbols'][currencyCode]['code']}
                                    </span>
                                </span>
                            </div>
                        )}
                        {discount && (
                            <div className='prc-view'>
                                {' '}
                                <span>
                                    {' '}
                                    <span>{currency['symbols'][currencyCode]['symbol']}</span>
                                    <span className='prc-value'>
                                        {applyDiscount(price, discount, '-', quantity).toFixed(2)}
                                    </span>{' '}
                                    <span className='prc-code'>
                                        {currency['symbols'][currencyCode]['code']}
                                    </span>
                                </span>
                            </div>
                        )}
                        {!discount && !discountPercent && (
                            <div className='prc-view'>
                                <span>
                                    <span className='prc-value'>
                                        {currency['symbols'][currencyCode]['symbol']}
                                        {price.toFixed(2)}
                                    </span>{' '}
                                    <span className='prc-code'>
                                        {currency['symbols'][currencyCode]['code']}
                                    </span>
                                </span>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className='qty-view'>
                            <span></span>
                            <input type='text' disabled defaultValue={quantity} />
                        </div>
                        <div className='unit-prc-view'>
                            <span>
                                <span className='prc-value'>
                                    {currency['symbols'][currencyCode]['symbol']}
                                    {unitPrice.toFixed(2)}
                                </span>{' '}
                                <span className='prc-code'>
                                    {currency['symbols'][currencyCode]['code']}
                                </span>
                            </span>
                        </div>
                        <div className='prc-view'>
                            <span>
                                <span className='prc-value'>
                                    {currency['symbols'][currencyCode]['symbol']}
                                    {price.toFixed(2)}
                                </span>{' '}
                                <span className='prc-code'>
                                    {currency['symbols'][currencyCode]['code']}
                                </span>
                            </span>
                        </div>
                    </>
                )}
                {itemDiscount}
            </div>
        );
    } else {
        return null;
    }
};

export default AddonCustomQuote;
