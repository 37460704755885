import React, {useState} from 'react';

// import { useHistory } from "react-router-dom";

const CHECK_EMPTY = {
    contact_firstname: true,
    contact_lastname: true,
    contact_email: true,
    contact_company: true,
    contact_phonenumber: true,
};

const STAGES = ['contactInfo', 'orderInfo', 'billingInfo'];

export const OnBoardContext = React.createContext(null);

export const OnBoardProvider = ({children}) => {
    const [onboardInfo, setOnBoardInfo] = React.useState(new Array(4));
    const [quoteInfo, setQuoteInfo] = React.useState([
        {planCode: 'cust-quote'},
        {},
        {order_values: []},
    ]);
    const [emptyFields, setEmptyFields] = useState(CHECK_EMPTY);
    const [currentStage, setCurrentStage] = useState(0);
    const [nextStage, setNextStage] = useState(1);
    const [prevStage, setPrevStage] = useState(-1);
    const [emailLang, setEmailLang] = useState('en-CA');
    const [orderData, setOrderData] = useState({
        totalOrder: 0,
        order_values: [],
    });
    const [addonChange, setAddonChange] = useState(false);
    const [quoteTotal, setQuoteTotal] = useState(0);
    const [onboardStatus, setOnboardStatus] = React.useState('loading');
    const [onboardQuoteStatus, setOnboardQuoteStatus] = React.useState('loading');
    const [legacyQuote, setLegacyQuote] = useState(true);

    const [paymentStage, setPaymentStage] = useState(STAGES[0]);
    const [pageLang, setPageLang] = useState('en');
    const [taxesList, setTaxesList] = useState([]);

    const [onBoardCurrency, setOnboardCurrency] = useState('CAD');

    const [quoteNotFound, setQuoteNotFound] = useState(false);
    const [quoteExpired, setQuoteExpired] = useState(false);

    const [needContact, setNeedContact] = useState(false);

    return (
        <OnBoardContext.Provider
            value={{
                onboardInfo,
                setOnBoardInfo,
                emptyFields,
                setEmptyFields,
                currentStage,
                setCurrentStage,
                nextStage,
                setNextStage,
                prevStage,
                setPrevStage,
                emailLang,
                setEmailLang,
                orderData,
                setOrderData,
                addonChange,
                setAddonChange,
                quoteTotal,
                setQuoteTotal,
                quoteInfo,
                setQuoteInfo,
                onboardStatus,
                setOnboardStatus,
                onboardQuoteStatus,
                setOnboardQuoteStatus,
                legacyQuote,
                setLegacyQuote,
                paymentStage,
                setPaymentStage,
                pageLang,
                setPageLang,
                taxesList,
                setTaxesList,
                onBoardCurrency,
                setOnboardCurrency,
                quoteNotFound,
                setQuoteNotFound,
                quoteExpired,
                setQuoteExpired,
                needContact,
                setNeedContact,
            }}
        >
            {children}
        </OnBoardContext.Provider>
    );
};
