import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {OnBoardProvider} from './contexts/onboardContext';

//BACK BUTTON LISTENER:
//will check if the user attempts to use the back and forward button in the browser
//behavior will put them to the first page to prevent messing up with the app's state
const BackButtonListener = ({children}) => {
    const [pressed, setPressed] = React.useState(false);

    // const history = useHistory();
    React.useEffect(() => {
        window.onpopstate = (e) => {
            setPressed(true);

            document.location.href = '/';
        };
    });

    

    return (
        // <h3>Back button: {pressed.toString()}</h3>
        null
    );
};

window.onload = function () {
    
};

ReactDOM.render(
    <React.StrictMode>
        <OnBoardProvider>
            <BackButtonListener />
            <App />
        </OnBoardProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
