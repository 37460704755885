import React, {useContext, useState, useEffect} from 'react';
import {OnBoardContext} from '../../contexts/onboardContext';
import {FormattedMessage} from 'react-intl';
import currency from '../../currency/currency.json';

const AddonStandard = ({
    addonName,
    itemName,
    unitPrice,
    editable,
    baseQuantity,
    addonID,
    dependID,
    currencyCode,
    addonChange,
    setAddonChange,
}) => {
    const [addonPrice, setAddonPrice] = useState(unitPrice);
    let changeDepend = false;

    addonName = addonName.replace('[Standard]', ''); //remove anything where it says [Standard] in the string because we will read it later

    const {
        onboardInfo,
        setOnBoardInfo,
        orderData,
        setOrderData,
        // addonChange,
        // setAddonChange,
    } = useContext(OnBoardContext);

    const dataOnboard = onboardInfo;
    const orderInfo = orderData;

    useEffect(() => {
        setOrderData(orderInfo);
        setOnBoardInfo(dataOnboard);
    }, [orderInfo]);

    useEffect(() => {
        changeDepend = !changeDepend;
    }, [addonChange]);

    const calculateTotal = () => {
        let total = 0;

        onboardInfo[2]['order_values'].forEach((order) => {
            if (order['price'] > 0) {
                //we update the total price ONLY when order is of type Extra and enabled OR  order is of type Standard
                if (
                    (order['type'] === '[Extra]' && order['enabled'] === true) ||
                    order['type'] === '[Standard]' ||
                    order['type'] === '[Monthly]' ||
                    order['type'] === '[Yearly]'
                ) {
                    total += order['price'] * order['quantity'];
                }
            }
        });

        const dataOnboard = onboardInfo;
        dataOnboard[2]['totalOrder'] = total;

        setOnBoardInfo(dataOnboard);
    };

    const handleQtyChange = (e) => {
        e.preventDefault();

        //quantity has been changed
        const newQuantity = Number(e.target.value);
        const newPrice = newQuantity * unitPrice;

        setAddonPrice(newPrice);

        updateStandardOrder(addonID, newQuantity);

        calculateTotal();

        setAddonChange(!addonChange);
    };

    const addonOrder = {
        // "name" : addonName,
        name: `[Standard] ${addonName}`,
        quantity: baseQuantity,
        price: unitPrice,
        type: '[Standard]',
        editable: editable,
        dependID: dependID,
    };

    //make sure that the same add-on object does not get added more than once, do a split & join because we need the perfect exact string to compare
    if (
        orderInfo['order_values'].find(
            (oneOrder) =>
                oneOrder['name'].split(' ').join('') === addonOrder['name'].split(' ').join('')
        ) === undefined
    ) {
        orderInfo['order_values'].push(addonOrder);
        //
        // setOrderData(orderInfo);

        dataOnboard[2] = orderInfo;
        // setOnBoardInfo(dataOnboard);
    }

    //any price/quantity change will be handled in this function
    //check of any changes (called inside handleQtyChange()), and then let the context onBoardInfo know of those updates
    const updateStandardOrder = (orderID, newQuantity) => {
        dataOnboard[2] = orderInfo;
        dataOnboard[2]['order_values'][orderID]['quantity'] = newQuantity;

        setOnBoardInfo(dataOnboard);
    };

    let plan_ess = false;

    if (addonName.trim() === 'plan.pbx_ess') {
        plan_ess = true;
    } else {
    }

    return (
        <div className='add-on-con' style={{display: addonName.includes('PBX') ? 'none' : null}}>
            {/* IMPORTANT: make sure to use trim() method before using the id on FormattedMessage (there are 'invisible' spaces for some reason) */}
            <FormattedMessage id={itemName.trim()}>
                {(placeholder) => (
                    <div className='add-on-name'>
                        <span className='brackets'>[Standard] </span> {placeholder}
                    </div>
                )}
            </FormattedMessage>
            {/* <div className="add-on-name"><span>[Standard] </span> {intl.formatMessage({ id: `${String(addonName)}` })}</div>  */}
            <div className={'add-on-side'}>
                {unitPrice > 0 && (
                    <div className={editable ? 'add-on-qty' : 'add-on-qty disabled'}>
                        <input type='text' defaultValue={baseQuantity} onChange={handleQtyChange} />
                        {/* <span><FormattedMessage id="order.qty" /></span><input type='text' defaultValue= {baseQuantity} onChange={handleQtyChange} /> */}
                    </div>
                )}

                {unitPrice < 0 && editable === false && (
                    <div className={editable ? 'add-on-qty' : 'add-on-qty disabled'}>
                        {
                            //check if this addon has a 'dependID', another addon that its quantity depends on. If so, update order_values quantity
                            //NOTE: this type of addon can only be of type '[Standard]'
                            dependID !== undefined ? (
                                <>
                                    <input
                                        type='text'
                                        defaultValue={
                                            onboardInfo[2]['order_values'][dependID]['quantity']
                                        }
                                        onChange={handleQtyChange}
                                    />
                                </>
                            ) : (
                                <>
                                    <input
                                        type='text'
                                        defaultValue={baseQuantity}
                                        onChange={handleQtyChange}
                                    />
                                </>
                            )
                        }
                    </div>
                )}

                {/* display a dummy qty input if the add-on is of type included  */}
                {typeof baseQuantity === 'undefined' && (
                    <div className='add-on-qty disabled dummy'>
                        <span></span>
                        <input type='text' defaultValue='X' />
                    </div>
                )}

                {unitPrice > 0 ? (
                    <div className='unit-prc-view'>
                        {' '}
                        <span>
                            <span className='prc-value'>
                                {currency['symbols'][currencyCode]['symbol']}
                                {unitPrice.toFixed(2)}
                            </span>{' '}
                            <span className='prc-code'>
                                {currency['symbols'][currencyCode]['code']}
                            </span>
                        </span>
                    </div>
                ) : (
                    <div className='add-on-prc noqty1'>
                        <FormattedMessage id='order.included' />
                    </div>
                )}

                {unitPrice > 0 ? (
                    //recalculate the addonPrice based on context onboardInfo quantity
                    <div className='add-on-prc'>
                        {currency['symbols'][currencyCode]['symbol']}
                        {(onboardInfo[2]['order_values'][addonID]['quantity'] * unitPrice).toFixed(
                            2
                        )}{' '}
                        <span>{currency['symbols'][currencyCode]['code']}</span>
                    </div>
                ) : (
                    <div className='add-on-prc noqty2'>
                        <FormattedMessage id='order.included' />
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddonStandard;
