const validEmailRegex = RegExp(
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);

// export const CONTACT_ERROR_MESSAGES = {
//     contact_firstname: 'First name cannot be empty.',
//     contact_lastname: 'Last name cannot be empty.',
//     contact_email: 'Email is incorrect or empty.',
//     contact_company: 'Company cannot be empty.',
//     contact_phonenumber: 'Phone number cannot be empty.',
// };

const validateContactItem = (name, value) => {
    // 
    switch (name) {
        case 'contact_email':
            if (value.length > 0) return validEmailRegex.test(value);
            return false;
        case 'contact_firstname':

        case 'contact_lastname':
        case 'contact_phonenumber':
        case 'contact_company':
            return value.length > 0;
        default:
            return true;
    }
};

const validateContact = (contact) => {
    const errors = {
        // contact_firstname:false,
        // contact_lastname:false,
        // contact_email:false,
        // contact_company:false,
        // contact_phonenumber:false,
    };
    Object.keys(contact).forEach((item) => {
        errors[item] = validateContactItem(item, contact[item]);
    });
    return errors;
};

export const getFormContactInfo = (target) => {
    const contactInfo = {};
    const {
        contact_firstname,
        contact_lastname,
        contact_email,
        contact_company,
        contact_phonenumber,
    } = target;
    const items = [
        contact_firstname,
        contact_lastname,
        contact_email,
        contact_company,
        contact_phonenumber,
    ];
    items.forEach((item) => {
        contactInfo[item.name] = item.value;
    });
    return contactInfo;
};

export function getInitialContactErrorState(contact) {
    if (contact) return validateContact(contact);
    // return {
    //     contact_firstname:false,
    //     contact_lastname:false,
    //     contact_email:false,
    //     contact_company:false,
    //     contact_phonenumber:false,
    // };
    return {
        contact_firstname: undefined,
        contact_lastname: undefined,
        contact_email: undefined,
        contact_company: undefined,
        contact_phonenumber: undefined,
    };

    // return {
    //     contact_firstname:true,
    //     contact_lastname:true,
    //     contact_email:true,
    //     contact_company:true,
    //     contact_phonenumber:true,
    // };
}

export function contactErrorReducer(prevContactErrorState, action) {
    const newContactErrorState = {...prevContactErrorState};
    const {name, value} = action.payload;
    newContactErrorState[name] = validateContactItem(name, value);
    
    return newContactErrorState;
}
