import React, {useReducer, useState, useContext, useEffect, useRef} from 'react';
import {FormattedMessage} from 'react-intl';
import {
    contactErrorReducer,
    getInitialContactErrorState,
    getFormContactInfo,
} from '../../util/payment/contact';
import {OnBoardContext} from '../../contexts/onboardContext';
import {useHistory} from 'react-router-dom';
import {useIntl} from 'react-intl';
import loading from '../../assets/image/loading.gif';
import moment from 'moment';
import {Formik, useField} from 'formik';
import * as Yup from 'yup';

const TOUCHED_FIELDS = {
    contact_firstname: false,
    contact_lastname: false,
    contact_email: false,
    contact_company: false,
    contact_phonenumber: false,
};

let renderAmount = 0;

let planArr = ['talk-ess-x', 'talk-pro-x', 'talk-ent-x'];

const ContactInfo = ({contact, onSubmit, paymentStage, onStageChange}) => {
    const [disabled, setDisabled] = useState(false);
    const [touchedFields, setTouchedFields] = useState(TOUCHED_FIELDS);

    const [submitted, setSubmitted] = useState(false);

    const [emailLangLocal, setEmailLangLocal] = useState('en-CA'); //the state that will take email language, will be used by emailLang from context

    const lastNameRef = useRef();
    const emailLangRef = useRef();
    const phoneRef = useRef();
    const companyRef = useRef();

    const formRef = useRef(); //ref to be used as a setter and getter of Formik values

    const [emailAddr, setEmailAddr] = useState('');

    const [contactHub, setContactHub] = useState({
        contact_email: '',
        contact_firstname: '',
        contact_lastname: '',
        contact_language: '',
        contact_phonenumber: '',
        contact_company: '',
    });

    const [contactErrorState, contactValidationDispatch] = useReducer(
        contactErrorReducer,
        contact,
        getInitialContactErrorState
    );

    const intl = useIntl();

    //will be used in the future
    const {
        onboardInfo,
        setOnBoardInfo,
        emptyFields,
        setEmptyFields,
        setEmailLang,
        setQuoteTotal,
        setQuoteInfo,
        orderData,
        setOrderData,
        setOnboardQuoteStatus,
        onboardQuoteStatus,
        setLegacyQuote,
        setOnboardCurrency,
        quoteNotFound,
        setQuoteNotFound,
        quoteFound,
        pageLang,
        setPageLang,
        quoteExpired,
        setQuoteExpired,
        needContact,
        setNeedContact,
    } = useContext(OnBoardContext);

    const getValidation = (name) => {
        if (contactErrorState[name] !== undefined) {
            return contactErrorState[name];
        } else {
        }

        return true;
    };

    const isEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    };

    const calculateTotal = () => {
        let total = 0;

        for (let index = 0; index < orderData['order_values'].length; index++) {
            const order = orderData['order_values'][index];

            if (order['price'] > 0) {
                total += order['price'];
            }
        }
    };

    const query = new URLSearchParams(window.location.search);

    let token = query.get('q');
    let monthly = query.get('monthly');
    let qty = query.get('qty');
    const lang = query.get('lang');
    const src = query.get('src');

    const href = window.location.href;

    let result = 0;
    if (href.split('quote/')[1][0] === '?') {
        result = 1;
    } else {
        result = 2;
    }

    if (monthly !== 'true' && monthly !== 'false') {
        monthly = 'true';
    }

    if (token && !planArr.some((plan) => token.includes(plan))) {
        token = 'talk-ess-x';
    }

    // if(qty ){

    //     console.log('token- unknown plan');
    //     token='talk-ess-x';
    // }

    const paramContainer = [];

    paramContainer.push(qty);
    paramContainer.push(lang);
    paramContainer.push(src);

    const checkExisting = () => {
        for (let index = 0; index < paramContainer.length; index++) {
            const param = paramContainer[index];

            if (param == null) {
                return false;
            }
        }

        return true;
    };

    const testAddonView = (dataOrders) => {
        let data = dataOrders;

        let arrExtra = [];

        let arrNoExtra = data[2]['order_values'];

        //take care of the extra items, put them all at the bottom of the array
        for (let index = 0; index < data[2]['order_values'].length; index++) {
            const order = data[2]['order_values'][index];
            if (order['name'].includes('[Extra]')) {
                arrExtra.push(order);

                arrNoExtra[index] = null;
            }
        }

        let newData = data;
        //add to the addon view and remove any extra nulls
        newData[2]['order_values'] = arrNoExtra.filter((n) => n).concat(arrExtra);

        //take care of the pbx array, put it after the first element
        for (let index = 0; index < newData[2]['order_values'].length; index++) {
            const order = newData[2]['order_values'][index];

            if (order['name'].includes('PBX')) {
                swap(newData[2]['order_values'], index, 0); //swapping of first item and PBX item, between index of the PBX item and index of 0 (which is the first place)
            }
        }

        function swap(arr, from, to) {
            arr.splice(from, 1, arr.splice(to, 1, arr[from])[0]);
        }

        setOnBoardInfo(newData);
    };

    const fillContact = () => {
        let tempHub = contactHub;

        if (onboardInfo[4]?.contacts?.length > 0) {
            tempHub['contact_company'] = onboardInfo[4]?.contacts[0]['company'];
            tempHub['contact_firstname'] = onboardInfo[4]?.contacts[0]['firstname'];
            tempHub['contact_lastname'] = onboardInfo[4]?.contacts[0]['lastname'];

            // setContactHub(tempHub);

            formRef.current.setFieldValue('firstname', onboardInfo[4]?.contacts[0]['firstname']);
            formRef.current.setFieldValue('lastname', onboardInfo[4]?.contacts[0]['lastname']);
            //company has to be set differently
            formRef.current.setFieldValue('company', onboardInfo[3]?.company?.name);
            formRef.current.setFieldValue('phone', onboardInfo[4]?.contacts[0]['phone']);
            formRef.current.setFieldValue('email', onboardInfo[4]?.contacts[0]['email']);
        }
    };

    let OSName = 'Unknown OS';
    if (navigator.appVersion.indexOf('Win') != -1) OSName = 'Windows';
    if (navigator.appVersion.indexOf('Mac') != -1) OSName = 'MacOS';
    if (navigator.appVersion.indexOf('X11') != -1) OSName = 'UNIX';
    if (navigator.appVersion.indexOf('Linux') != -1) OSName = 'Linux';

    // check first if pageLang has been changed (we get the quote language), we display the app according to the language of the quote
    // NOTE: it does not apply to SELF-ONBOARDING, which is why q has to be checked if exists or not
    useEffect(() => {
        const lang = query.get('lang');
        const q = query.get('q');

        // if (!lang && !q) {
        //     const domain = `${window.location.protocol}//${window.location.host}${window.location.pathname}?lang=${pageLang}`;
        //     window.location.href = domain;
        // }
    }, [pageLang]);

    useEffect(() => {
        if (
            onboardInfo[0]?.planCode === 'cust-quote' &&
            onboardQuoteStatus === 'idle' &&
            quoteExpired === false
        ) {
            //call the fillContact function only when onboardInfo is ready
            fillContact();
        }
    }, [onboardInfo]);

    //This will make sure that a user won't go astray. If they want to access to a custom quote page without going through the business plan page,
    //they will be redirected to the business plan page
    const history = useHistory();

    const quoteID = window.location.pathname.split('/')[2];

    renderAmount++;

    console.log('server URL is: ', process.env.REACT_APP_SERVER_URL);
    //This is for the custom quote based on hubspot public ID
    // if(onboardInfo[0] === undefined && renderAmount < 3 && !token && !monthly && !qty && !lang && !src){//  this is a hubspot quote (onboardInfo[0]) and no query params except token exist
    if (onboardInfo[0] === undefined && renderAmount < 3 && quoteID && !quoteID.includes('?')) {
        //  this is a hubspot quote (onboardInfo[0]) and no query params except token exist

        // fetch(`https://iqibge86aa.execute-api.us-east-2.amazonaws.com/api/quotes/public/${quoteID}`)
        // fetch(`http://127.0.0.1:8000/quotes/public/${quoteID}`)
        fetch(`${process.env.REACT_APP_SERVER_URL}/quotes/public/${quoteID}`)
            // fetch(`http://127.0.0.1:8003/quotes/public/${quoteID}`)
            // fetch(`https://iqibge86aa.execute-api.us-east-2.amazonaws.com/api/quotes/public/${quoteID}`)
            .then((response) => response.json())
            .then((data) => {
                if (data.length < 1) {
                    setQuoteNotFound(true);
                }

                const dataOnboard = onboardInfo;

                dataOnboard[0] = {planCode: 'cust-quote'};

                dataOnboard[2] = {order_values: []};

                dataOnboard[3] = {company: {}};

                dataOnboard[4] = {contacts: []};

                dataOnboard[5] = {owner: {}};

                dataOnboard[6] = {quote_status: {}};

                // dataOnboard[4]["contacts"].emailLang = 'en-CA';

                let total = 0;
                if (data.length > 0) {
                    if (data[0][2] !== undefined) {
                        for (let index = 0; index < data[0][2]['items'].length; index++) {
                            dataOnboard[2]['order_values'].push(
                                data[0][2]['items'][index]['properties']
                            );

                            total = data[1]['hs_quote_amount'];

                            let frequency =
                                data[0][2]['items'][index]['properties'][
                                    'recurringbillingfrequency'
                                ];

                            //determine the billing frequency of the Plan
                            if (frequency !== null) {
                                switch (frequency) {
                                    case 'annually':
                                        dataOnboard[0].isMonthly = false;
                                        break;
                                    case 'monthly':
                                        dataOnboard[0].isMonthly = true;
                                        break;
                                    default:
                                        break;
                                }
                            }

                            //this will differentiate a legacy quote and a current quote, legacy quotes do not have SKUs defined
                            if (data[0][2]['items'][index]['properties'].hasOwnProperty('hs_sku')) {
                                if (data[0][2]['items'][index]['properties']['hs_sku'] !== null) {
                                    setLegacyQuote(false);
                                }
                            }
                        }
                    }

                    if (data[2] !== undefined) {
                        dataOnboard[6]['quote_status'] = data[2]['hs_quote_status'];

                        // the currency to use for billing page, NOTE: this is only used by custom hubspot quote, and not by self-onboard
                        // see quoteBillingInfoUsage.jsx [line 1426] for usage
                        setOnboardCurrency(data[2]['hs_quote_status']['hs_currency']);

                        setPageLang(data[2]['hs_quote_status']['hs_language']);

                        if (data[2]['hs_quote_status']['hs_expiration_date'] !== undefined) {
                            let expireDate = data[2]['hs_quote_status']['hs_expiration_date'];
                            let isExpired = false;
                            if (moment(expireDate).isAfter()) {
                                isExpired = false;
                            } else {
                                isExpired = true;
                            }

                            setQuoteExpired(isExpired);
                        }
                    }
                }

                if (data.length > 0) {
                    //check if there are any existing company
                    if (!isEmpty(data[0][0]['company'])) {
                        dataOnboard[3]['company'] = data[0][0]['company']['properties'];
                    }
                }
                if (data.length > 0) {
                    //check if there is any existing owner
                    if (!isEmpty(data[0][1]['owner'])) {
                        dataOnboard[5]['owner'] = data[0][1]['owner']['properties'];
                    }
                }

                if (data.length > 0) {
                    //check if there are any existing contacts
                    if (!isEmpty(data[0][4]['contacts'][0])) {
                        for (let index = 0; index < data[0][4]['contacts'].length; index++) {
                            dataOnboard[4]['contacts'].push(
                                JSON.parse(JSON.stringify(data[0][4]['contacts'][index].properties))
                            );
                        }
                    } else {
                        setNeedContact(true);
                    }
                }

                setQuoteTotal(total);

                dataOnboard[2]['totalOrder'] = total;

                setOnBoardInfo(dataOnboard);
                setQuoteInfo(dataOnboard); //setquoteInfo

                setOrderData(dataOnboard[2]);

                testAddonView(dataOnboard);

                setOnboardQuoteStatus('idle');
            });
    }
    if (
        onboardInfo[0] === undefined &&
        renderAmount < 3 &&
        token &&
        monthly &&
        qty &&
        lang &&
        src
    ) {
        //  this is a hubspot quote (onboardInfo[0] is undefined) and query params along with token exist

        setOnBoardInfo([
            {
                planCode: token,
                isMonthly: JSON.parse(monthly.toLowerCase()), //had to be parsed because the result is a string
                nbOfUsers: '1-4',
            },
            null,
        ]);

        setOnboardQuoteStatus('idle');
    }

    //This is for self-onboarding, not a hubspot quote, onboardInfo[0] is defined
    if (onboardInfo[0] !== null) {

        //if only token exists: use that token and default monthly to true, renderAmount to break any infinite rendering
        if (!monthly && !qty && !lang && !src && token && renderAmount < 3) {
            // calculateTotal();

            setOnBoardInfo([
                {
                    planCode: token,
                    isMonthly: true, //had to be parsed because the result is a string
                    nbOfUsers: '1-4',
                },
                null,
            ]);

            setOnboardQuoteStatus('idle');
        }
        //if only token and monthly exists: use that token and that monthly
        else if (monthly && !qty && !lang && !src && token && renderAmount < 3) {
            //

            setOnBoardInfo([
                {
                    planCode: token,
                    isMonthly: JSON.parse(monthly.toLowerCase()), //had to be parsed because the result is a string
                    nbOfUsers: '1-4',
                },
                null,
            ]);

            setOnboardQuoteStatus('idle');
        }
        //if only token and monthly exists: use that token and that monthly
        else if (!checkExisting() && monthly && token && renderAmount < 3) {
            setOnBoardInfo([
                {
                    planCode: token,
                    isMonthly: JSON.parse(monthly.toLowerCase()), //had to be parsed because the result is a string
                    nbOfUsers: '1-4',
                },
                null,
            ]);

            setOnboardQuoteStatus('idle');
        } else if (token && monthly && qty && lang && !src && renderAmount < 3) {
            setOnBoardInfo([
                {
                    planCode: token,
                    isMonthly: JSON.parse(monthly.toLowerCase()), //had to be parsed because the result is a string
                    nbOfUsers: '1-4',
                },
                null,
            ]);

            setOnboardQuoteStatus('idle');
        }
        //if only token and monthly exists: use that token and that monthly
        else if (qty && token && renderAmount < 3) {
            setOnBoardInfo([
                {
                    planCode: token,
                    isMonthly: true, //had to be parsed because the result is a string
                    nbOfUsers: '1-4',
                },
                null,
            ]);

            setOnboardQuoteStatus('idle');
        }
        //if qty
        else if (!token && !monthly && renderAmount < 3) {
            setOnBoardInfo([
                {
                    planCode: 'talk-ess-x',
                    isMonthly: true, //had to be parsed because the result is a string
                    nbOfUsers: '1-4',
                },
                null,
            ]);

            setOnboardQuoteStatus('idle');
        } else if (!token && !monthly && qty && lang && !src && renderAmount < 3) {
            setOnBoardInfo([
                {
                    planCode: 'talk-ess-x',
                    isMonthly: true, //had to be parsed because the result is a string
                    nbOfUsers: '1-4',
                },
                null,
            ]);

            setOnboardQuoteStatus('idle');
        } else if (!token && !monthly && qty && !lang && !src && renderAmount < 3) {
            setOnBoardInfo([
                {
                    planCode: 'talk-ess-x',
                    isMonthly: true, //had to be parsed because the result is a string
                    nbOfUsers: '1-4',
                },
                null,
            ]);

            setOnboardQuoteStatus('idle');
        } else if (!token && monthly && !qty && !lang && !src && renderAmount < 3) {

            setOnBoardInfo([
                {
                    planCode: 'talk-ess-x',
                    isMonthly: JSON.parse(monthly.toLowerCase()), //had to be parsed because the result is a string
                    nbOfUsers: '1-4',
                },
                null,
            ]);

            setOnboardQuoteStatus('idle');
        }

        // else if (monthly != 'true' && monthly != 'false' && monthly && renderAmount < 3) {
        //     console.log('monthly- inside monthly condition');

        //     setOnBoardInfo([
        //         {
        //             planCode: 'talk-ess-x',
        //             isMonthly: JSON.parse(monthly.toLowerCase()), //had to be parsed because the result is a string
        //             nbOfUsers: '1-4',
        //         },
        //         null,
        //     ]);

        //     setOnboardQuoteStatus('idle');
        // }
        else if ((monthly !== 'true' || monthly !== 'false') && renderAmount < 3) {
            setOnBoardInfo([
                {
                    planCode: 'talk-ess-x',
                    isMonthly: true, //had to be parsed because the result is a string
                    nbOfUsers: '1-4',
                },
                null,
            ]);

            setOnboardQuoteStatus('idle');
        }

        // else if (planArr.some(plan => token.includes(plan)) && renderAmount < 3) {

        //     console.log('plan- inside plan array', token);
        //     // setOnBoardInfo([
        //     //     {
        //     //         planCode: 'talk-ess-x',
        //     //         isMonthly: true, //had to be parsed because the result is a string
        //     //         nbOfUsers: '1-4',
        //     //     },
        //     //     null,
        //     // ]);

        //     // setOnboardQuoteStatus('idle');

        // }
        else if (token && lang && !monthly && renderAmount < 3) {
            // history.push("/");
            // history.go();

            setOnBoardInfo([
                {
                    planCode: token,
                    isMonthly: true, //had to be parsed because the result is a string
                    nbOfUsers: '1-4',
                },
                null,
            ]);

            setOnboardQuoteStatus('idle');
        }

        // else{

        //     console.log('monthly- inside else ');
        //     console.log('monthly- value: ', monthly);
        //     // if (monthly !== 'true'  || monthly !== 'false') {
        //     //     console.log('monthly- not true nor false');

        //     // }
        // }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setSubmitted(true);
        let valid = true;
        //contactErrorState elements verification
        Object.keys(contactErrorState).forEach((item) => {
            valid = valid && contactErrorState[item];
        });

        if ((valid && Object.keys(contactErrorState).length > 0) || emailAddr.length > 0) {
            setEmailLang(emailLangLocal);
            onSubmit(getFormContactInfo(e.target));

            let tempData = onboardInfo;

            tempData[1].contact_email_lang = emailLangLocal;

            setOnBoardInfo(tempData);
            // onStageChange("orderInfo");
        } else {
            if (intl.locale === 'en') {
                alert('Please enter your information correctly');
            } else if (intl.locale === 'fr') {
                alert('Veuillez saisir correctement vos informations');
            } else {
                alert('Please enter your information correctly');
            }
        }
    };

    const handleOnChange = (e) => {
        const {name, value} = e.target;

        const fields = emptyFields;

        if (e.target.value.trim().length === 0) {
            //make sure to trim input value first, a space will be considered a character
            fields[e.target.getAttribute('name')] = true; // this field is empty
        } else {
            fields[e.target.getAttribute('name')] = false;
        }

        setEmptyFields(fields);
        setTouchedFields(fields); //handleChanged has been called on this field, therefore that field has been 'touched'

        contactValidationDispatch({
            payload: {name, value},
        });
    };

    const handleOnSelectChange = (e) => {
        setEmailLangLocal(e.target.value);
    };

    let quoteForm = (
        <Formik
            initialValues={{
                firstname: contactHub['contact_firstname'],
                lastname: contactHub['contact_lastname'],
                email: contactHub['contact_email'],
                company: contactHub['contact_company'],
                phone: contactHub['contact_phonenumber'],
                languagemail: contactHub['contact_language'],
            }}
            validationSchema={Yup.object({
                firstname: Yup.string()
                    .max(15, <FormattedMessage id='error.char15' />)
                    .required(<FormattedMessage id='error.required' />)
                    .nullable(),
                lastname: Yup.string()
                    .max(20, <FormattedMessage id='error.char20' />)
                    .required(<FormattedMessage id='error.required' />)
                    .nullable(),
                email: Yup.string()
                    .email(<FormattedMessage id='error.email' />)
                    .required(<FormattedMessage id='error.required' />),
                company: Yup.string()
                    .required(<FormattedMessage id='error.required' />)
                    .nullable(),
                phone: Yup.string()
                    .min(10, <FormattedMessage id='error.char10' />)
                    .required(<FormattedMessage id='error.required' />)
                    .nullable(),
            })}
            onSubmit={(values, {setSubmitting}) => {
                setTimeout(() => {
                    setSubmitting(false);

                    const dataOnboard = onboardInfo;
                    dataOnboard[4]['contacts'].unshift(values); //use unshift instead of push because the contact to be added has to be the first one

                    //rename the company name to the one from the form instead of the quote
                    dataOnboard[3]['company']['name'] = values['company'];

                    setOnBoardInfo(dataOnboard);

                    onStageChange('orderInfo');
                }, 400);
            }}
            innerRef={formRef}
        >
            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
            }) => (
                <form onSubmit={handleSubmit} id='form1' className='quote-form'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <div className='check-input'>
                                <label htmlFor='firstname'>
                                    <FormattedMessage id='payment.first_name' />
                                    <span>*</span>
                                </label>
                                <input
                                    id='firstname'
                                    type='text'
                                    value={values.firstname}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                        errors.firstname && touched.firstname
                                            ? 'text-input error'
                                            : 'text-input'
                                    }
                                />

                                {touched.firstname && errors.firstname ? (
                                    <div className='err-form'>{errors.firstname}</div>
                                ) : null}
                            </div>

                            <div className='check-input'>
                                <label htmlFor='email'>
                                    <FormattedMessage id='payment.email' />
                                    <span>*</span>
                                </label>
                                <input
                                    id='email'
                                    type='text'
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                        errors.email && touched.email
                                            ? 'text-input error'
                                            : 'text-input'
                                    }
                                />
                                {touched.email && errors.email ? (
                                    <div className='err-form'>{errors.email}</div>
                                ) : null}
                            </div>
                            <div className='check-input'>
                                <label htmlFor='phone'>
                                    <FormattedMessage id='payment.phone' />
                                    <span>*</span>
                                </label>

                                <FormattedMessage id='placeholder.phone'>
                                    {(placeholder) => (
                                        <input
                                            id='phone'
                                            // placeholder={String(placeholder)}
                                            type='text'
                                            value={values.phone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={
                                                errors.phone && touched.phone
                                                    ? 'text-input error'
                                                    : 'text-input'
                                            }
                                        />
                                    )}
                                </FormattedMessage>
                                {touched.phone && errors.phone ? (
                                    <div className='err-form'>{errors.phone}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='check-input'>
                                <label htmlFor='lastname'>
                                    <FormattedMessage id='payment.last_name' />
                                    <span>*</span>
                                </label>

                                <input
                                    id='lastname'
                                    // placeholder="Smith"
                                    type='text'
                                    value={values.lastname}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={
                                        errors.lastname && touched.lastname
                                            ? 'text-input error'
                                            : 'text-input'
                                    }
                                />
                                {touched.lastname && errors.lastname ? (
                                    <div className='err-form'>{errors.lastname}</div>
                                ) : null}
                            </div>

                            <div className='check-input'>
                                <label>
                                    <FormattedMessage id='payment.email_language' />
                                    <span>*</span>
                                </label>

                                <div className='select-box'>
                                    <i className='fa fa-sort' aria-hidden='true' />
                                    <EmailLangSelect
                                        name='contact_language'
                                        tabIndex='4'
                                        id='email_language'
                                    >
                                        <option value='en-CA'>English</option>
                                        <option value='fr-CA'> Fran&#231;ais </option>
                                    </EmailLangSelect>
                                </div>
                            </div>

                            <div className='check-input'>
                                <label htmlFor='company'>
                                    <FormattedMessage id='payment.company' />
                                    <span>*</span>
                                </label>
                                <FormattedMessage id='placeholder.company_name'>
                                    {(placeholder) => (
                                        <input
                                            id='company'
                                            // placeholder={String(placeholder)}
                                            type='text'
                                            value={values.company}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            className={
                                                errors.company && touched.company
                                                    ? 'text-input error'
                                                    : 'text-input'
                                            }
                                        />
                                    )}
                                </FormattedMessage>
                                {touched.company && errors.company ? (
                                    <div className='err-form'>{errors.company}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className='check-con'>
                        <button type='submit'>Continue</button>
                    </div>
                </form>
            )}
        </Formik>
    );

    let onboardForm = (
        <div className='check-form'>
            <form onSubmit={handleSubmit} noValidate>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='check-input'>
                            <label>
                                <FormattedMessage id='payment.first_name' /> <span>*</span>
                            </label>
                            <input
                                type='text'
                                defaultValue={
                                    contact ? contact.contact_firstname : ''
                                    // contact ? contact.contact_firstname : contactHub['contact_firstname']
                                }
                                name='contact_firstname'
                                onChange={handleOnChange}
                                tabIndex='1' //makes sure that tab key press follows the visual order of inputs
                            />

                            {(getValidation('contact_firstname') &&
                                touchedFields['contact_firstname'] === false) || ( //to add a 'touch' state into the condition
                                <div className='error'>
                                    {/* {
                                                    CONTACT_ERROR_MESSAGES[
                                                        'contact_firstname'
                                                    ]
                                                } */}
                                    <FormattedMessage id='contact.first_name' />
                                </div>
                            )}
                        </div>
                        <div className='check-input'>
                            <label>
                                <FormattedMessage id='payment.email' /> <span>*</span>
                            </label>
                            <input
                                id='contact_mail'
                                type='text'
                                name='contact_email'
                                onChange={handleOnChange}
                                tabIndex='3'
                                // ref = {emailRef}
                                defaultValue={
                                    contact ? contact.contact_email : contactHub['contact_email']
                                }
                                // value = {emailRef}
                            />
                            {getValidation('contact_email') || (
                                <div className='error'>
                                    {/* {CONTACT_ERROR_MESSAGES['contact_email']} */}
                                    <FormattedMessage id='contact.email' />
                                </div>
                            )}
                        </div>
                        <div className='check-input'>
                            <label>
                                <FormattedMessage id='payment.phone' /> <span>*</span>
                            </label>
                            <input
                                type='text'
                                defaultValue={
                                    contact
                                        ? contact.contact_phonenumber
                                        : contactHub['contact_phonenumber']
                                }
                                name='contact_phonenumber'
                                onChange={handleOnChange}
                                tabIndex='5'
                                inputRef={phoneRef}
                            />
                            {getValidation('contact_phonenumber') || (
                                <div className='error'>
                                    {/* {
                                                CONTACT_ERROR_MESSAGES[
                                                    'contact_phonenumber'
                                                ]
                                            } */}
                                    <FormattedMessage id='contact.phone' />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='check-input'>
                            <label>
                                <FormattedMessage id='payment.last_name' /> <span>*</span>
                            </label>
                            <input
                                type='text'
                                defaultValue={
                                    contact
                                        ? contact.contact_lastname
                                        : contactHub['contact_lastname']
                                }
                                name='contact_lastname'
                                onChange={handleOnChange}
                                tabIndex='2'
                                inputRef={lastNameRef}
                            />
                            {getValidation('contact_lastname') || (
                                <div className='error'>
                                    {/* {CONTACT_ERROR_MESSAGES['contact_lastname']} */}
                                    <FormattedMessage id='contact.last_name' />
                                </div>
                            )}
                        </div>
                        <div className='check-input'>
                            <label>
                                <FormattedMessage id='payment.email_language' />
                                <span>*</span>
                            </label>

                            <div className='select-box'>
                                <i className='fa fa-sort' aria-hidden='true' />
                                <select
                                    name='contact_language'
                                    defaultValue={
                                        contact
                                            ? contact.contact_language
                                            : contactHub['contact_language']
                                    }
                                    onChange={handleOnSelectChange}
                                    tabIndex='4'
                                    inputRef={emailLangRef}
                                >
                                    <option value='en-CA'>English</option>
                                    <option value='fr-CA'> Fran&#231;ais </option>
                                </select>
                            </div>
                        </div>
                        <div className='check-input'>
                            <label>
                                <FormattedMessage id='payment.company' /> <span>*</span>
                            </label>
                            <input
                                type='text'
                                defaultValue={
                                    contact
                                        ? contact.contact_company
                                        : contactHub['contact_company']
                                }
                                name='contact_company'
                                onChange={handleOnChange}
                                tabIndex='6'
                                inputRef={companyRef}
                            />
                            {getValidation('contact_company') || (
                                <div className='error'>
                                    {/* {CONTACT_ERROR_MESSAGES['contact_company']} */}
                                    <FormattedMessage id='contact.company' />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className='check-con'>
                    <button type='submit' disabled={disabled ? 'disabled' : null} tabIndex='7'>
                        <FormattedMessage id='payment.continue' />
                    </button>
                </div>
            </form>
        </div>
    );

    const EmailLangSelect = ({label, ...props}) => {
        // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
        // which we can spread on <input> and alse replace ErrorMessage entirely.
        const [field, meta] = useField(props);

        const handleEmailLangChange = (e) => {
            e.preventDefault();

            let tempHub = contactHub;

            tempHub['contact_email'] = e.target.value;

            setContactHub(tempHub);
        };

        return (
            <>
                <select {...field} {...props} onChange={handleEmailLangChange}></select>
                {meta.touched && meta.error && <div className='input-feedback'>{meta.error}</div>}
            </>
        );
    };

    return (
        <>
            {/* { 
                onboardInfo[0] !== undefined ? (
                    onboardInfo[0]?.planCode === 'cust-quote' ? (
                        <>
                            <div className='billing-msg' style={{marginTop: '3em'}}>
                                <p>
                                    <FormattedMessage id='contact.billing_message' />
                                </p>
                            </div>
                            {quoteForm}
                        </>
                    ) : (
                        <>
                            <div className='billing-msg' style={{marginTop: '3em'}}>
                                <p>
                                    <FormattedMessage id='contact.billing_message' />
                                </p>
                            </div>
                            {onboardForm}
                        </>
                    )
                ) : (
                    <div>
                        <img src={loading} alt='quote loading' />
                    </div>
                )
            }  */}

            {onboardInfo[0]?.planCode === 'cust-quote' &&
            onboardQuoteStatus === 'idle' &&
            quoteNotFound === false ? (
                quoteExpired === false ? (
                    <>
                        <div className='billing-msg' style={{marginTop: '3em'}}>
                            <p>
                                <FormattedMessage id='contact.billing_message' />
                            </p>
                        </div>
                        {quoteForm}
                    </>
                ) : (
                    <p style={{marginTop: '3em'}}>
                        <FormattedMessage id='order.quote_expired' />{' '}
                        <FormattedMessage id='order.contact_sale' />
                    </p>
                )
            ) : (
                // display the loading ONLY if we know that this is of type self-serve
                result === 2 &&
                quoteNotFound === false && (
                    <div>
                        <img src={loading} alt='quote loading' />
                    </div>
                )
            )}

            {onboardInfo[0]?.planCode !== 'cust-quote' && result === 1 && quoteNotFound === false && (
                <>
                    <div className='billing-msg' style={{marginTop: '3em'}}>
                        <p>
                            <FormattedMessage id='contact.billing_message' />
                        </p>
                    </div>
                    {onboardForm}
                </>
            )}

            {quoteNotFound === true && (
                <div className='quote-error'>
                    <FormattedMessage id='page.quote_error' />
                </div>
            )}
        </>
    );
};

export default ContactInfo;
