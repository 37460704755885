import React, {useContext, useState, useEffect} from 'react';
import {OnBoardContext} from '../../contexts/onboardContext';
import {FormattedMessage} from 'react-intl';
import currency from '../../currency/currency.json';

const AddonPlan = ({
    addonName,
    itemName,
    unitPrice,
    editable,
    baseQuantity,
    addonID,
    addonTerm,
    currencyCode,
    setOrderQuoteTotal,
    setAddonChange,
    addonChange,
}) => {
    const [checked, setChecked] = useState(false);
    const [addonPrice, setAddonPrice] = useState(unitPrice);
    let disabled = false;

    const query = new URLSearchParams(window.location.search);
    const qty = query.get('qty');

    if (qty) {
        disabled = true;
    }

    addonName = addonName.replace(addonTerm, ''); //remove anything where it says [Standard] in the string because we will read it later

    console.log('qty- addonPlan', qty);

    const {
        onboardInfo,
        setOnBoardInfo,
        orderData,
        setOrderData,
        // addonChange,
        // setAddonChange
    } = useContext(OnBoardContext);

    const dataOnboard = onboardInfo;
    let orderInfo = orderData;

    useEffect(() => {
        setOrderData(orderInfo);
        setOnBoardInfo(dataOnboard);
    }, [orderInfo]);

    const [onboardInfoLocal, setOnboardInfoLocal] = useState(onboardInfo);

    const calculateTotal = () => {
        let total = 0;

        onboardInfo[2]['order_values'].forEach((order) => {
            if (order['price'] > 0) {
                //we update the total price ONLY when order is of type Extra and enabled OR  order is of type Standard
                if (
                    (order['type'] === '[Extra]' && order['enabled'] === true) ||
                    order['type'] === '[Standard]' ||
                    order['type'] === addonTerm
                ) {
                    // total+=addonPrice;
                    total += order['price'] * order['quantity'];
                }

                // if((order['type'] === '[Monthly]' || order['type'] === '[Yearly]' && qty)){
                //
                //     total+=order['price'] * order['quantity'];

                //
                // }
            }
        });

        const dataOnboard = onboardInfo;
        dataOnboard[2]['totalOrder'] = total;

        // setOnBoardInfo(dataOnboard);
        setOnboardInfoLocal(dataOnboard);

        setOrderQuoteTotal(total);
    };

    const handleQtyChange = (e) => {
        e.preventDefault();

        //quantity has been changed
        //if the quantity set by the user is 0, we do not accept it
        if (e.target.value !== '0') {
            const newQuantity = Number(e.target.value);
            const newPrice = newQuantity * unitPrice;

            setAddonPrice(newPrice);

            updateStandardOrder(addonID, newQuantity);

            // calculateTotal();

            //notify the dependant addon that this plan quantity (or any other properties) has changed
            setAddonChange(!addonChange);
        }
    };

    const addonOrder = {
        name: `${addonTerm} ${addonName}`,
        quantity: baseQuantity,
        price: unitPrice,
        type: addonTerm,
        editable: editable,
    };

    //make sure that the same add-on object does not get added more than once, do a split & join because we need the perfect exact string to compare
    if (
        orderInfo['order_values'].find(
            (oneOrder) =>
                oneOrder['name'].split(' ').join('') === addonOrder['name'].split(' ').join('')
        ) === undefined
    ) {
        orderInfo['order_values'].push(addonOrder);
        dataOnboard[2] = orderInfo;
    }

    //any price/quantity change will be handled in this function
    //check of any changes (called inside handleQtyChange()), and then let the context onBoardInfo know of those updates
    const updateStandardOrder = (orderID, newQuantity) => {
        dataOnboard[2] = orderInfo;
        dataOnboard[2]['order_values'][orderID]['quantity'] = newQuantity;

        for (let index = 0; index < dataOnboard[2]['order_values'].length; index++) {
            if ('dependID' in dataOnboard[2]['order_values'][index]) {
                //special instructions for add-ons with a dependID: copy the quantity of the depended add-on
                if (dataOnboard[2]['order_values'][index]['dependID'] !== undefined) {
                    dataOnboard[2]['order_values'][index]['quantity'] = newQuantity;
                }
            }
        }

        // setOnBoardInfo(dataOnboard);
        setOnboardInfoLocal(dataOnboard);

        calculateTotal();
    };

    //we need this in order for the right translated to show up for Plans based on the user choice
    //NOTE: this should only be for addonPlan and not addonStandard or addonExtra
    let itemID = '';

    switch (addonTerm) {
        case '[Monthly]':
            itemID = 'item.monthly';
            break;
        case '[Yearly]':
            itemID = 'item.yearly';
            break;
        default:
            itemID = '';
    }

    return (
        <div className='add-on-con'>
            {/* IMPORTANT: make sure to use trim() method before using the id on FormattedMessage (there are 'invisible' spaces for some reason) */}
            <FormattedMessage id={itemName.trim()}>
                {(placeholder) => (
                    <div className='add-on-name'>
                        <span>
                            {
                                <FormattedMessage id={itemID}>
                                    {(itemID) => (
                                        <div className='add-on-name'>
                                            <span className='brackets'>{itemID} </span>{' '}
                                            {String(placeholder).replace('Croo Talk', 'Cloud PBX')}
                                        </div>
                                    )}
                                </FormattedMessage>
                            }
                        </span>
                    </div>
                )}
            </FormattedMessage>

            <div className={'add-on-side'}>
                {unitPrice > 0 && (
                    <div className={editable && !disabled ? 'add-on-qty' : 'add-on-qty disabled'}>
                        {/* <div className={editable ? 'add-on-qty' : "add-on-qty disabled"}> */}
                        {/* EDITABLE 1 */}
                        <input type='text' defaultValue={baseQuantity} onChange={handleQtyChange} />
                    </div>
                )}

                {unitPrice < 0 && editable === false && (
                    <div className={editable ? 'add-on-qty' : 'add-on-qty disabled'}>
                        {/* EDITABLE 2 */}
                        {
                            <>
                                <input
                                    type='text'
                                    defaultValue={baseQuantity}
                                    onChange={handleQtyChange}
                                    id='qty-input'
                                />
                            </>
                        }
                    </div>
                )}

                {/* display a dummy qty input if the add-on is of type included  */}
                {typeof baseQuantity === 'undefined' && (
                    <div className='add-on-qty disabled dummy'>
                        <input type='text' defaultValue='X' />
                    </div>
                )}

                {unitPrice > 0 ? (
                    <div className='unit-prc-view'>
                        {' '}
                        <span>
                            <span className='prc-value'>
                                {currency['symbols'][currencyCode]['symbol']}
                                {unitPrice.toFixed(2)}
                            </span>{' '}
                            <span className='prc-code'>
                                {currency['symbols'][currencyCode]['code']}
                            </span>
                        </span>
                    </div>
                ) : (
                    <div className='add-on-prc noqty'>
                        <FormattedMessage id='order.included' />
                    </div>
                )}

                {unitPrice > 0 ? (
                    //recalculate the addonPrice based on context onboardInfo quantity
                    <div className='add-on-prc'>
                        {currency['symbols'][currencyCode]['symbol']}
                        {(onboardInfo[2]['order_values'][addonID]['quantity'] * unitPrice).toFixed(
                            2
                        )}{' '}
                        <span>{currency['symbols'][currencyCode]['code']}</span>
                    </div>
                ) : (
                    <div className='add-on-prc noqty'>
                        <FormattedMessage id='order.included' />
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddonPlan;
