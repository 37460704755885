import React, {useReducer, useContext, useState, useRef, useEffect} from 'react';
import pay1 from '../../assets/image/pay-1.png';
import pay2 from '../../assets/image/pay-2.png';
import pay3 from '../../assets/image/pay-3.png';
import {billingReducer} from '../../util/payment/billing';
import {Formik, useField, Field} from 'formik';
import AddonViewOnly from '../../components/addons/addonViewOnly';
import AddonHeaderView from '../../components/addons/addonHeaderView';
import '../../assets/css/billingInfo.css';
import {OnBoardContext} from '../../contexts/onboardContext';
import {FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {useIntl} from 'react-intl';
import tax_us from '../../tax/us.json';
import tax_ca from '../../tax/ca.json';
import tax from '../../tax/tax.json';

import currency from '../../currency/currency.json';
import countries from '../../countries/countries.json';
import errors from '../../errors/errors';

import {Spring, animated, config} from 'react-spring';

// import LoadingOverlay from "react-loading-overlay";

import styled, {css} from 'styled-components';

import ReactTooltip from 'react-tooltip';

const ITEMS = {
    'Cloud PBX - Essentials': 'plan.pbx_ess',
    'Cloud PBX - Enterprise': 'plan.pbx_ent',
    'Cloud PBX - Professional': 'plan.pbx_pro',
    'Croo Talk - Essentials': 'plan.talk_ess_x',
    'Croo Talk - Professional': 'plan.talk_pro_x',
    'Croo Talk - Enterprise': 'plan.talk_ent_x',
    'DID / Canada - USA': 'plan.did_nam',
    'Hard Drive / 10 GB': 'plan.hd_10',
    'Hard Drive / 100 GB': 'plan.hd_100',
    'Hard Drive / 1000 GB': 'plan.hd_1000',
    '1 (8XX) Numbers / Canada - USA': 'plan.1_num',
    'International Phone Number': 'plan.did_inter',
    'Text Messages': 'plan.text_msg',
};

let renderAmount = 0;

const BillingInfo = ({order, onSubmit, onStageChange, quoteInfo}) => {
    const errorHandler = errors;
    const [error, setError] = useState('');
    const [errorMessage, setErrorMessage] = useState([]);
    const [billingState, billingDispatch] = useReducer(billingReducer, {}, () => ({}));
    const history = useHistory();
    const [termsChecked, setTermsChecked] = useState(false);

    const [taxesList, setTaxesList] = useState([]);

    const [taxTotal, setTaxTotal] = useState(0);

    const [provinceValue, setProvinceValue] = useState('Select Value...'); //this will be the state to use for the Province select tag value

    const intl = useIntl();

    const {
        onboardInfo,
        setOnBoardInfo,
        emailLang,
        pageLang,
        quoteTotal,
        onBoardCurrency,

        // ,taxesList, setTaxesList
    } = useContext(OnBoardContext);

    const [countrySelected, setCountrySelected] = useState('');
    const [otherCountry, setOtherCountry] = useState('');
    const [provinceSelected, setProvinceSelected] = useState(
        `${intl.formatMessage({id: 'billing.country_select'})}...`
    );

    const [submitStatus, setSubmitStatus] = useState('idle');

    //this is the local currency state to be used for this page, not ideal to use onBoardCurrency directly since changing its value will rerender the whole application given that it is a state from the OnboardContext
    //NOTE: this is ultimately used by billingInfo self-onBoard only and not quoteBillingInfo
    //see [line 334] for usage
    const [currencyPage, setCurrencyPage] = useState(onBoardCurrency);

    const selectEl = useRef(null);
    const formRef = useRef(); //ref to be used as a setter and getter of Formik values

    let oldOnboard = [];

    renderAmount++;

    const handleCheck = (e) => {
        setTermsChecked(!termsChecked);
    };

    let total = 0;
    const calculateTotal = () => {
        for (let index = 0; index < onboardInfo[2]['order_values'].length; index++) {
            const order = onboardInfo[2]['order_values'][index];

            if (order['price'] > 0) {
                //we update the total price ONLY when order is of type Extra and enabled OR  order is of type Standard
                if (
                    (order['type'] === '[Extra]' && order['enabled'] === true) ||
                    order['type'] === '[Standard]' ||
                    order['type'] === '[Monthly]' ||
                    order['type'] === '[Yearly]'
                ) {
                    total += order['price'] * order['quantity'];
                }
            }
        }
    };

    calculateTotal();

    const getAbbreviation = (province) => {
        let result = 'None';

        for (let index = 0; index < tax_ca.length; index++) {
            if (tax_ca[index]['name'] === province) {
                result = tax_ca[index]['abbreviation'];

                return result;
            }
        }

        for (let index = 0; index < tax_us.length; index++) {
            if (tax_us[index]['name'] === province) {
                result = tax_us[index]['abbreviation'];

                return result;
            }
        }

        return result;
    };

    const getProvinceName = (provinceAbbreviation) => {
        let result = 'None';

        //this is a forced behavior for default province based on the country selected
        if (provinceAbbreviation === 'Select Value...') {
            if (countrySelected === 'CA') {
                result = 'Alberta';
            }
            if (countrySelected === 'US') {
                result = 'Alabama';
            }
        }

        for (let index = 0; index < tax_ca.length; index++) {
            if (tax_ca[index]['abbreviation'] === provinceAbbreviation) {
                result = tax_ca[index]['name'];

                return result;
            }
        }

        for (let index = 0; index < tax_us.length; index++) {
            if (tax_us[index]['abbreviation'] === provinceAbbreviation) {
                result = tax_us[index]['name'];

                return result;
            }
        }

        return result;
    };

    const getCountryName = (countryAbbreviation) => {
        let result = 'None';

        if (countryAbbreviation === 'CA') {
            result = 'Canada';
            return result;
        }
        if (countryAbbreviation === 'US') {
            result = 'United States';
            return result;
        }

        return result;
    };

    const getCurrency = (countryCode) => {
        let result = 'USD';

        for (let index = 0; index < currency['countries']['country'].length; index++) {
            let currencyEl = currency['countries']['country'][index];

            if (currencyEl['countryCode'] === countryCode) {
                result = currencyEl['currencyCode'];
                return result;
            }
        }

        return result;
    };

    const CountrySelect = ({label, ...props}) => {
        const [field, meta] = useField(props);

        //Handle Country Change

        const handleCountryChange = (e) => {
            e.preventDefault();

            resetSelect();

            let countryChosen = e.target.value;

            setCountrySelected(e.target.value);

            if (countryChosen === 'CA') {
                overrideSelect(getAbbreviation('Alberta'));

                //force to show the Taxes with the default CA City on the UI
                let defaultCACity = 'Alberta';
                let defaultCACityAbbr = getAbbreviation(defaultCACity);
                let result = [];
                result = tax['city'][defaultCACityAbbr];

                calculateTax(result, defaultCACityAbbr);

                setCurrencyPage('CAD'); //change currency accordingly, we change currencyPage instead of onBoardCurrency to avoid rerendering of the entire app

                setOtherCountry('');
            }

            if (countryChosen === 'US') {
                overrideSelect(getAbbreviation('Alabama'));
                //reset the Taxes when US has been chosen as a country, regardless of the shown province
                setTaxesList([]);
                updateOrderTotal([]);

                setCurrencyPage('USD'); //change currency accordingly, we change currencyPage instead of onBoardCurrency to avoid rerendering of the entire app

                setOtherCountry('');
            }

            if (countryChosen === 'Other') {
                resetCountry2();

                const currencyFound = getCurrency(otherCountry);

                //reset the Taxes when US has been chosen as a country, regardless of the shown province
                setTaxesList([]);
                updateOrderTotal([]);

                //find out if we could use the currency found (if it's EUR, CAD OR USD )
                if (currencyFound === 'EUR') {
                    setCurrencyPage('EUR');
                } else if (currencyFound === 'CAD') {
                    setCurrencyPage('CAD');
                } else {
                    setCurrencyPage('USD'); //default currency, since the currencyFound is foreign
                }
            }
        };

        return (
            <>
                <select
                    {...field}
                    {...props}
                    value={countrySelected}
                    onChange={handleCountryChange}
                ></select>
                {/* triple verification: we also need to check if countrySelected is not empty */}
                {meta.touched && meta.error && countrySelected === '' && (
                    <div className='input-feedback'>{meta.error}</div>
                )}
            </>
        );
    };

    const ProvinceSelect = ({label, ...props}) => {
        const [field, meta, helpers] = useField(props);

        const {setValue} = helpers;
        const {value} = meta;

        return (
            <>
                <select {...field} {...props} ref={selectEl}></select>
                {meta.touched && meta.error && <div className='input-feedback'>{meta.error}</div>}
            </>
        );
    };

    const updateOrderTotal = (taxList) => {
        let newTotal = 0;
        for (let index = 0; index < taxList.length; index++) {
            const taxElement = taxList[index];

            newTotal += parseFloat(taxElement['unitPrice']);
        }

        setTaxTotal(parseFloat(newTotal.toFixed(2)));
    };

    const calculateTax = (taxList, provinceAbbr) => {
        let taxArray = [];
        let taxElementTotal = 0;

        let totalOrder = 0; //we have to take onboardInfo's totalOrder inside of quoteTotal because onboardInfo is more up to date

        if (onboardInfo[2].totalOrder > 0) {
            totalOrder = onboardInfo[2].totalOrder;
        } else {
            totalOrder = quoteTotal;
        }

        for (let index = 0; index < taxList.length; index++) {
            const taxElement = taxList[index];

            if (provinceAbbr === 'ON') {
                // this is a special case for Ontario, with its HST tax different from the other provinces with 13% instead of 15%
                taxElementTotal = ((totalOrder * 13) / 100).toFixed(2);
            } else {
                taxElementTotal = ((totalOrder * tax['type'][taxElement]['rate']) / 100).toFixed(2);
            }

            taxArray.push({name: taxElement, unitPrice: taxElementTotal});
        }

        setTaxesList(taxArray);

        updateOrderTotal(taxArray);
    };

    const handleOptionChange = (e) => {
        let provinceAbbr = e.target.value;

        let result = [];
        result = tax['city'][provinceAbbr];

        let provinceSelectEl = document.getElementById('provinceSelect');

        provinceSelectEl.value = provinceAbbr;

        //update the Province value to the one chosen
        setProvinceValue(provinceAbbr);

        if (result !== undefined) {
            calculateTax(result, provinceAbbr);
        } else {
            setTaxesList([]);
            setTaxTotal(0);
        }

        overrideSelect(provinceAbbr);
    };

    const overrideSelect = (provinceAbbr) => {
        document.getElementById('test-tax').innerText = getProvinceName(provinceAbbr);

        let abbr = getProvinceName(provinceAbbr);

        if (document.getElementById('test-taxid')) {
            document.getElementById('test-taxid').innerHTML = `
                <option value=${provinceAbbr}> ${abbr} </option>
            
            
            `;
        } else {
            setProvinceSelected(abbr);
        }
    };

    const resetSelect = () => {
        if (document.getElementById('provinceSelect')) {
            document.getElementById('provinceSelect').selectedIndex = -1;
        }
    };

    const resetCountry2 = () => {
        formRef.current.setFieldValue('country2', ''); // we empty the input of the dom element with ID=country2, we can't use vanilla js since we don't know whether the element exists or not
    };

    const handleExpireChange = (expValue) => {};

    // very good filtering for what we need for the credit card expiration date
    // not perfect but can handle common use cases
    // source: https://codepen.io/stoXmod/pen/bGePmdd
    const handleKeyExp = (e) => {
        var code = e.keyCode;
        var allowedKeys = [8];
        if (allowedKeys.indexOf(code) !== -1) {
            return;
        }

        e.target.value = e.target.value
            .replace(
                /^([1-9]\/|[2-9])$/g,
                '0$1/' // 3 > 03/
            )
            .replace(
                /^(0[1-9]|1[0-2])$/g,
                '$1/' // 11 > 11/
            )
            .replace(
                /^([0-1])([3-9])$/g,
                '0$1/$2' // 13 > 01/3
            )
            .replace(
                /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
                '$1/$2' // 141 > 01/41
            )
            .replace(
                /^([0]+)\/|[0]+$/g,
                '0' // 0/ > 0 and 00 > 0
            )
            .replace(
                /[^\d\/]|^[\/]*$/g,
                '' // To allow only digits and `/`
            )
            .replace(
                /\/\//g,
                '/' // Prevent entering more than 1 `/`
            );

        //
    };

    const handleCCKeyExp = (e) => {
        const mask = (value, limit, separator) => {
                var output = [];
                for (let i = 0; i < value.length; i++) {
                    if (i !== 0 && i % limit === 0) {
                        output.push(separator);
                    }

                    output.push(value[i]);
                }

                return output.join('');
            },
            unmask = (value) => {
                let unmaskedValue = value.replace(/[^\d]/g, '');

                return unmaskedValue;
            },
            checkSeparator = (position, interval) => {
                return Math.floor(position / (interval + 1));
            };

        let ccNumberPattern = /^\d{0,16}$/g,
            ccNumberSeparator = ' ',
            ccNumberInputOldValue,
            ccNumberInputOldCursor;

        let el = e.target,
            newValue = unmask(el.value),
            newCursorPosition;

        ccNumberInputOldValue = el.value;
        ccNumberInputOldCursor = el.selectionEnd;

        if (newValue.match(ccNumberPattern)) {
            newValue = mask(newValue, 4, ccNumberSeparator);

            newCursorPosition =
                ccNumberInputOldCursor -
                checkSeparator(ccNumberInputOldCursor, 4) +
                checkSeparator(
                    ccNumberInputOldCursor + (newValue.length - ccNumberInputOldValue.length),
                    4
                ) +
                // (unmask(newValue).length - unmask(ccNumberInputOldValue).length);
                (unmask(newValue).length - unmask(ccNumberInputOldValue).length) +
                1; //add +1 at the end, otherwise the cursor will always be one character behind the full card number

            el.value = newValue !== '' ? newValue : '';

            formRef.current.setFieldValue('cardNumber', newValue);
        } else {
            formRef.current.setFieldValue('cardNumber', ccNumberInputOldValue);

            el.value = ccNumberInputOldValue;
            newCursorPosition = ccNumberInputOldCursor;
        }

        // el.setSelectionRange(newCursorPosition, newCursorPosition);
        el.setSelectionRange(newCursorPosition, newCursorPosition + 1);
    };

    const [hideItemList, sethideItemList] = useState(false);
    const [rotationUnit, setRotationUnit] = useState(0);
    const [overlayLoaded, setOverlayLoaded] = useState(true);

    const DarkBackground = styled.div`
        display: none; /* Hidden by default */
        position: fixed; /* Stay in place */
        z-index: 999; /* Sit on top */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0, 0, 0); /* Fallback color */
        background-color: rgba(206, 54, 121, 1); /* Fuchsia w/ opacity */

        ${(props) =>
            props.disappear &&
            css`
                display: block; /* show */
            `}
    `;

    //    const StyledOverlayLoader = styled(LoadingOverlay)`
    //        top: 50%;
    //   `;

    const TurningArrow = () => {
        const [pressed, setPressed] = useState(false);

        return (
            <Spring native from={{scale: 2}} to={{scale: pressed ? 1.8 : 2}} config={config.wobbly}>
                {({scale}) => (
                    <animated.button
                        className={
                            onboardInfo[0].planCode !== 'cust-quote'
                                ? 'right-a marged-b'
                                : 'right-a marged-a'
                        }
                        style={{
                            transform: scale.to((scale) => `scale(${scale})`),
                        }}
                        onMouseDown={() => setPressed(true)}
                        onClick={() => {
                            setPressed(false);
                            if (rotationUnit === 0) {
                                setRotationUnit(90);
                                sethideItemList(true);
                            } else {
                                setRotationUnit(0);
                                sethideItemList(false);
                            }
                        }}
                        onMouseLeave={() => setPressed(false)}
                    >
                        <animated.i
                            style={{
                                transform: scale.to((scale) => `rotate(${rotationUnit}deg)`),
                            }}
                            className='fa fa-chevron-right'
                            aria-hidden='true'
                        />
                    </animated.button>
                )}
            </Spring>
        );
    };

    useEffect(() => {
        if (submitStatus === 'waiting') {
            setOverlayLoaded(!overlayLoaded);
        } else {
            setOverlayLoaded(false);
        }
    }, [submitStatus]);

    return (
        <>
            {errorMessage?.error && (
                <div className='err-msg'>
                    <i className='fa fa-exclamation-triangle'></i>
                    <div>{errorMessage['error']}</div>
                </div>
            )}
            {/* <DarkBackground disappear={overlayLoaded}>
                    <StyledOverlayLoader
                        active={true}
                        spinner={true}
                        classNamePrefix='OverlayLoader_'
                    >
                    </StyledOverlayLoader>
            </DarkBackground>   */}
            <div className='billing-box'>
                <div className={'arrow-box ' + (hideItemList ? 'onb-hidden ' : '')}>
                    <div className='row'>
                        <div className='col-md-0 col-lg-6 pl-0 my-pr' />
                        <div
                            className={
                                'col-md-6 col-lg-6 pr-0 my-pl ' + (hideItemList ? 'icon-bill ' : '')
                            }
                            style={{textAlign: !hideItemList ? 'right' : 'center'}}
                        >
                            <TurningArrow />
                            {/* go back to the order info page when the pencil icon has been clicked */}
                            <span
                                className='edit-icon'
                                onClick={(e) => onStageChange('orderInfo')}
                                // style={{
                                //     transform: `scale(2)`,
                                // }}
                            >
                                <i className='fa fa-pencil fa-2x' aria-hidden='true' />
                            </span>
                        </div>
                    </div>
                </div>

                <Formik
                    initialValues={{
                        cardNumber: '',
                        expDate: '',
                        cardCVV: '',
                        addressLine1: '',
                        addressLine2: '',
                        country: countrySelected,
                        country2: '',
                        city: '',
                        provinceState: '',
                        postalCode: '',
                        paymentMethod: '',
                        picked: 'CC',
                        accountType: '',
                        accountName: '',
                        accountNumber: '',
                        confirmAccountNumber: '',
                        routingNumber: '',
                    }}
                    validate={(values) => {
                        const errors = {};

                        //IF OPTION CHOSEN IS CREDIT CARD

                        if (values.picked === 'CC') {
                            if (!values.cardCVV) {
                                errors.cardCVV = intl.formatMessage({id: 'error.required'});
                            } else if (!/^[0-9]{3,4}$/.test(values.cardCVV)) {
                                errors.cardCVV = intl.formatMessage({id: 'error.card_cvv'});
                            }
                            let tempCardNumber = values.cardNumber;

                            let formattedCardNumber = tempCardNumber.replaceAll(' ', ''); //replace all spaces first
                            formattedCardNumber = formattedCardNumber.replaceAll('-', ''); //replace all dashes as well

                            if (!values.cardNumber) {
                                errors.cardNumber = intl.formatMessage({id: 'error.required'});
                            }
                            //only do the verification after the card number has been formatted properly, not before
                            else if (
                                !/^4[0-9]{12}(?:[0-9]{3})?$/.test(formattedCardNumber) &&
                                !/^3[47][0-9]{13}$/.test(formattedCardNumber) &&
                                !/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
                                    formattedCardNumber
                                )
                            ) {
                                // else if (!/^4[0-9]{12}(?:[0-9]{3})?$/.test(values.cardNumber) && !/^3[47][0-9]{13}$/.test(values.cardNumber) && !/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(values.cardNumber) )
                                errors.cardNumber = intl.formatMessage({id: 'error.card_number'});
                            }

                            if (!values.expDate) {
                                errors.expDate = intl.formatMessage({id: 'error.required'});
                            } else if (!/^\d{2}\/\d{2}$/.test(values.expDate)) {
                                errors.expDate = intl.formatMessage({id: 'error.card_expdate'});

                                let expDate = values.expDate;

                                handleExpireChange(expDate);
                            }
                        }

                        if (values.picked === 'BA') {
                            if (!values.accountName) {
                                errors.accountName = intl.formatMessage({id: 'error.required'});
                            }
                            if (!values.accountNumber) {
                                errors.accountNumber = intl.formatMessage({id: 'error.required'});
                            }
                            if (!values.routingNumber) {
                                errors.routingNumber = intl.formatMessage({id: 'error.required'});
                            }
                            if (!values.confirmAccountNumber) {
                                errors.confirmAccountNumber = intl.formatMessage({
                                    id: 'error.required',
                                });
                            } else {
                                if (values.confirmAccountNumber !== values.accountNumber) {
                                    errors.confirmAccountNumber = intl.formatMessage({
                                        id: 'error.acc_nber_nomatch',
                                    });
                                }
                            }

                            if (!values.accountType) {
                                errors.accountType = intl.formatMessage({id: 'error.required'});
                            }
                        }

                        //
                        //
                        if (values.addressLine1.length === 0) {
                            errors.addressLine1 = intl.formatMessage({id: 'error.required'});
                        }

                        if (values.city.length === 0) {
                            errors.city = intl.formatMessage({id: 'error.required'});
                        }

                        //if countrySelect has this value, then that means either the user hasn't interacted even once, or the user selected an unusable value, aka ''
                        if (countrySelected === '') {
                            // if (!values.country && countrySelected === '') {
                            // if (!values.country && countrySelected === '') {
                            // if (!values.country) {

                            errors.country = intl.formatMessage({id: 'error.required'});
                        } else {
                            values.country = getCountryName(countrySelected);
                            values.provinceState = getProvinceName(provinceValue);
                        }

                        if (values.postalCode.length === 0) {
                            errors.postalCode = intl.formatMessage({id: 'error.required'});
                        }

                        if (countrySelected === 'Other') {
                            values.provinceState = 'None';

                            if (!values.country2) {
                                errors.country2 = intl.formatMessage({id: 'error.required'});
                            } else {
                                let country = values.country2;

                                let result = '';
                                for (let index = 0; index < countries.length; index++) {
                                    const countryEl = countries[index];

                                    if (countryEl['name'].toLowerCase() === country.toLowerCase()) {
                                        result = countryEl['alpha-2'];

                                        setOtherCountry(result);
                                    }
                                }

                                result = getCurrency(result);

                                //find out if we could use the currency found (if it's EUR, CAD OR USD )
                                if (result === 'EUR') {
                                    setCurrencyPage('EUR');
                                } else if (result === 'CAD') {
                                    setCurrencyPage('CAD');
                                } else {
                                    setCurrencyPage('USD'); //change currency accordingly, we change currencyPage instead of onBoardCurrency to avoid rerendering of the entire app
                                }
                            }
                        }

                        if (values.picked.length === 0) {
                            errors.picked = intl.formatMessage({id: 'error.required'});
                        }

                        return errors;
                    }}
                    onSubmit={async (values) => {
                        await new Promise((resolve) => setTimeout(resolve, 500));

                        if (values?.cardNumber !== undefined) {
                            let tempCardNumber = values?.cardNumber;

                            let formattedCardNumber = tempCardNumber.replaceAll(' ', ''); //replace all spaces first
                            formattedCardNumber = formattedCardNumber.replaceAll('-', ''); //replace all dashes as well

                            values.cardNumber = formattedCardNumber;
                        }

                        alert(JSON.stringify(values, null, 2));

                        const oldOnboardInfo = onboardInfo;
                        oldOnboard = onboardInfo;

                        const tempOnboard = onboardInfo;
                        tempOnboard[1]['contact_email_lang'] = emailLang;
                        tempOnboard[3] = values;

                        tempOnboard[0]['lang'] = pageLang;
                        tempOnboard[0]['currency'] = currencyPage;

                        /******************************************** */
                        //we have to swap between price/quantity of pbx and addon plan before sending it to the BE
                        let pricePBX = 0;
                        let qtyPBX = 0;
                        tempOnboard[2]['order_values'].forEach((order, index) => {
                            //we do not wanna change any of PBX quantity
                            //we just wanna set the plan itself with a quantity of 1 and price of 0
                            if (order['name'].includes('Croo Talk')) {
                                qtyPBX = order['quantity'];
                                pricePBX = order['price'];
                                order['quantity'] = 1;
                                order['price'] = 0;
                            }

                            // if(order['name'].includes('PBX')){
                            //     order['quantity'] = qtyPBX;
                            //     order['price'] = pricePBX;

                            //     tempOnboard[2]['order_values'][index]['quantity'] = qtyPBX;
                            //     tempOnboard[2]['order_values'][index]['price'] = pricePBX;

                            //
                            //
                            // }

                            index++;
                        });

                        setOnBoardInfo(tempOnboard);

                        /*************************************************** */

                        setSubmitStatus('waiting');

                        setErrorMessage({});

                        let errorFound = false;

                        // alert(JSON.stringify(tempOnboard[2]['order_values'], null, 2) );

                        //fetch to add the data needed (contact) to send to the BE
                        // fetch(`https://iqibge86aa.execute-api.us-east-2.amazonaws.com/api/subscriptions/create`, {

                        // fetch(`http://127.0.0.1:8000/subscriptions/create`, {

                        // fetch(`https://iqibge86aa.execute-api.us-east-2.amazonaws.com/api/subscriptions/create`, {
                        fetch(`${process.env.REACT_APP_SERVER_URL}/subscriptions/create`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(onboardInfo),
                        })
                            .then((response) => response.json())
                            .then((json) => {
                                // alert(JSON.stringify(values, null, 2));

                                // setOnBoardInfo(oldOnboardInfo);

                                setOnBoardInfo(oldOnboard);

                                tempOnboard[2]['order_values'].forEach((order, index) => {
                                    //we put back the changes that we made for PBX quantity
                                    if (order['name'].includes('Croo Talk')) {
                                        order['quantity'] = qtyPBX;
                                        order['price'] = pricePBX;
                                    }
                                });
                                //we reset onboard with the changes we made
                                setOnBoardInfo(tempOnboard);

                                if ('error' in json) {
                                    //iterate over our errorHandler, if the error has been found, display it according the client browser's language
                                    for (let index = 0; index < errorHandler.length; index++) {
                                        let toUse = json['error']
                                            .split('. Recurly')[0]
                                            .replace('..', '.');

                                        if (
                                            errorHandler[index]['en'].includes(
                                                json['error']
                                                    .split('. Recurly')[0]
                                                    .replace('..', '.')
                                            )
                                        ) {
                                            setErrorMessage({
                                                error: errorHandler[index][intl.locale],
                                            });
                                            errorFound = true;
                                        }
                                    }

                                    //still set the error, translation not found but error is still valid
                                    if (errorFound == false) {
                                        setErrorMessage(json);
                                        setError(json['error']);
                                    }
                                } else if (
                                    Object.values(json).indexOf('InternalServerError') > -1
                                ) {
                                    let error_msg =
                                        'Internal Server Error. Please contact your administrator';

                                    for (let index = 0; index < errorHandler.length; index++) {
                                        if (errorHandler[index]['en'].includes(error_msg)) {
                                            setErrorMessage({
                                                error: errorHandler[index][intl.locale],
                                            });
                                            setError(error_msg);
                                        }
                                    }

                                    setSubmitStatus('success');
                                } else {
                                    setSubmitStatus('success');

                                    history.push(`/thankyou/?lang=${pageLang}`);
                                    history.go();
                                }
                            });
                    }}
                    innerRef={formRef} //this will be the ref to use that allows us to have access to Formik values outside of the Formik component
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <div className={`row ${hideItemList ? 'row-billing-center' : ''}`}>
                                <div className='col-md-12 col-lg-6 pl-0 my-pr'>
                                    <div
                                        className={`pay-detail ${
                                            hideItemList ? 'row-no-bottom' : ''
                                        }`}
                                    >
                                        <div className='col pl-0'>
                                            <div
                                                role='group'
                                                aria-labelledby='my-radio-group'
                                                className='payment-type'
                                            >
                                                <label
                                                    className={
                                                        values.picked === 'CC'
                                                            ? 'payment-picked'
                                                            : ''
                                                    }
                                                >
                                                    <Field type='radio' name='picked' value='CC' />
                                                    <span>
                                                        <FormattedMessage id='bank.payment_cc' />
                                                    </span>
                                                </label>
                                                <label
                                                    className={
                                                        values.picked === 'BA'
                                                            ? 'payment-picked'
                                                            : ''
                                                    }
                                                >
                                                    <Field type='radio' name='picked' value='BA' />
                                                    <span>
                                                        <FormattedMessage id='bank.payment_ba' />
                                                    </span>
                                                </label>
                                                {errors.picked && touched.picked && (
                                                    <div className='input-feedback'>
                                                        {errors.picked}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='pay-form'>
                                            {formRef.current?.values.picked === 'BA' && (
                                                <div className='row bank-account'>
                                                    <div className='col pl-0'>
                                                        <label>
                                                            <FormattedMessage id='bank.name' />{' '}
                                                            <span className='req-star'>*</span>
                                                        </label>
                                                        <input
                                                            id='accountName'
                                                            placeholder='John Smith'
                                                            type='text'
                                                            value={values.accountName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={
                                                                errors.accountName &&
                                                                touched.accountName
                                                                    ? 'text-input error'
                                                                    : 'text-input'
                                                            }
                                                        />
                                                        {errors.accountName &&
                                                            touched.accountName && (
                                                                <div className='input-feedback'>
                                                                    {errors.accountName}
                                                                </div>
                                                            )}
                                                    </div>

                                                    <div className='col pl-0'>
                                                        <label>
                                                            <FormattedMessage id='bank.routing_nber' />{' '}
                                                            <span className='req-star'>*</span>
                                                        </label>
                                                        <input
                                                            id='routingNumber'
                                                            placeholder=''
                                                            type='text'
                                                            value={values.routingNumber}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={
                                                                errors.routingNumber &&
                                                                touched.routingNumber
                                                                    ? 'text-input error'
                                                                    : 'text-input'
                                                            }
                                                        />
                                                        {errors.routingNumber &&
                                                            touched.routingNumber && (
                                                                <div className='input-feedback'>
                                                                    {errors.routingNumber}
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                            )}
                                            {formRef.current?.values.picked === 'BA' && (
                                                <div className='row bank-account'>
                                                    <div className='col pl-0'>
                                                        <label>
                                                            <FormattedMessage id='bank.account_nber' />{' '}
                                                            <span className='req-star'>*</span>
                                                        </label>
                                                        <input
                                                            id='accountNumber'
                                                            placeholder=''
                                                            type='text'
                                                            value={values.accountNumber}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={
                                                                errors.accountNumber &&
                                                                touched.accountNumber
                                                                    ? 'text-input error'
                                                                    : 'text-input'
                                                            }
                                                        />
                                                        {errors.accountNumber &&
                                                            touched.accountNumber && (
                                                                <div className='input-feedback'>
                                                                    {errors.accountNumber}
                                                                </div>
                                                            )}
                                                    </div>

                                                    <div className='col pl-0'>
                                                        <label>
                                                            <FormattedMessage id='bank.account_nber2' />{' '}
                                                            <span className='req-star'>*</span>
                                                        </label>
                                                        <input
                                                            id='confirmAccountNumber'
                                                            placeholder=''
                                                            type='text'
                                                            value={values.confirmAccountNumber}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={
                                                                errors.confirmAccountNumber &&
                                                                touched.confirmAccountNumber
                                                                    ? 'text-input error'
                                                                    : 'text-input'
                                                            }
                                                        />
                                                        {errors.confirmAccountNumber &&
                                                            touched.confirmAccountNumber && (
                                                                <div className='input-feedback'>
                                                                    {errors.confirmAccountNumber}
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                            )}
                                            {formRef.current?.values.picked === 'BA' && (
                                                <div className='row bank-account'>
                                                    <div className='col pl-0'>
                                                        <label>
                                                            Account Type
                                                            <span className='req-star'>*</span>
                                                        </label>

                                                        <div
                                                            role='group'
                                                            aria-labelledby='my-radio-group'
                                                            className='account-type'
                                                        >
                                                            <label>
                                                                <Field
                                                                    type='radio'
                                                                    name='accountType'
                                                                    value='Checking'
                                                                />
                                                                <FormattedMessage id='bank.account_checking' />
                                                            </label>
                                                            <label>
                                                                <Field
                                                                    type='radio'
                                                                    name='accountType'
                                                                    value='Savings'
                                                                />
                                                                <FormattedMessage id='bank.account_savings' />
                                                            </label>
                                                        </div>
                                                        {errors.accountType &&
                                                            touched.accountType && (
                                                                <div className='input-feedback'>
                                                                    {errors.accountType}
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                            )}
                                            {formRef.current?.values.picked === 'CC' && (
                                                <div className='pay-card'>
                                                    <div className='pay-card-img'>
                                                        <img src={pay1} alt='visa' />
                                                        <img src={pay2} alt='mastercard' />
                                                        <img src={pay3} alt='amex' />
                                                    </div>
                                                </div>
                                            )}

                                            {formRef.current?.values.picked === 'CC' && (
                                                <div className='row'>
                                                    <div className='col pl-0 my-pad'>
                                                        <div className='card-num payment'>
                                                            <label>
                                                                <FormattedMessage id='billing.card_number' />{' '}
                                                                <span className='req-star'>*</span>
                                                            </label>
                                                            <input
                                                                id='cardNumber'
                                                                placeholder='4111111111111111'
                                                                type='text'
                                                                value={values.cardNumber}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={
                                                                    errors.cardNumber &&
                                                                    touched.cardNumber
                                                                        ? 'text-input error'
                                                                        : 'text-input'
                                                                }
                                                                onKeyUp={handleCCKeyExp}
                                                            />
                                                            {errors.cardNumber &&
                                                                touched.cardNumber && (
                                                                    <div className='input-feedback'>
                                                                        {errors.cardNumber}
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {formRef.current?.values.picked === 'CC' && (
                                                <div className='row'>
                                                    <div className='col-md-4 pl-0 my-pad card-num payment'>
                                                        <label>
                                                            <FormattedMessage id='billing.expiration' />{' '}
                                                            <span className='req-star'>*</span>
                                                        </label>
                                                        <input
                                                            id='expDate'
                                                            placeholder='MM / YY'
                                                            type='text'
                                                            value={values.expDate}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={
                                                                errors.expDate && touched.expDate
                                                                    ? 'text-input error'
                                                                    : 'text-input'
                                                            }
                                                            onKeyUp={handleKeyExp}
                                                        />
                                                        {errors.expDate && touched.expDate && (
                                                            <div className='input-feedback'>
                                                                {errors.expDate}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div className='col-md-3 pl-0 my-pad card-num cvv payment'>
                                                        <label>
                                                            CVV
                                                            <span className='cvv-txt'>
                                                                <FormattedMessage id='billing.card_backCVV'>
                                                                    {(placeholder) => (
                                                                        <div
                                                                            className='qua'
                                                                            href='#'
                                                                            data-tip={placeholder}
                                                                        >
                                                                            {' '}
                                                                            ?{' '}
                                                                        </div>
                                                                    )}
                                                                </FormattedMessage>
                                                            </span>
                                                            <span className='req-star'>*</span>
                                                        </label>

                                                        <ReactTooltip />

                                                        <input
                                                            id='cardCVV'
                                                            placeholder='123'
                                                            type='text'
                                                            value={values.cardCVV}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className={
                                                                errors.cardCVV && touched.cardCVV
                                                                    ? 'text-input error'
                                                                    : 'text-input'
                                                            }
                                                        />
                                                        {errors.cardCVV && touched.cardCVV && (
                                                            <div className='input-feedback'>
                                                                {errors.cardCVV}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            <div className='row'>
                                                <div className='col st-ad pl-0 my-pad-1 card-num payment'>
                                                    <label>
                                                        <FormattedMessage id='billing.address_line1' />{' '}
                                                        <span className='req-star'>*</span>
                                                    </label>
                                                    <input
                                                        id='addressLine1'
                                                        type='text'
                                                        value={values.addressLine1}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={
                                                            errors.addressLine1 &&
                                                            touched.addressLine1
                                                                ? 'text-input error'
                                                                : 'text-input'
                                                        }
                                                    />
                                                    {errors.addressLine1 &&
                                                        touched.addressLine1 && (
                                                            <div className='input-feedback'>
                                                                {errors.addressLine1}
                                                            </div>
                                                        )}
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col st-ad pl-0 my-pad-1 card-num payment'>
                                                    <label>
                                                        <FormattedMessage id='billing.address_line2' />{' '}
                                                    </label>
                                                    <input
                                                        id='addressLine2'
                                                        type='text'
                                                        value={values.addressLine2}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={
                                                            errors.addressLine2 &&
                                                            touched.addressLine2
                                                                ? 'text-input error'
                                                                : 'text-input'
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='Country col-l st-ad pl-0 my-pad-1 card-num countryCol'>
                                                    <label>
                                                        <FormattedMessage id='billing.country' />{' '}
                                                        <span className='req-star'>*</span>
                                                    </label>
                                                    <div className='select-box'>
                                                        <div className='down-arrow'></div>
                                                        <CountrySelect
                                                            label='Country'
                                                            name='country'
                                                            className='sel-country'
                                                        >
                                                            <FormattedMessage id='billing.country_select'>
                                                                {(placeholder) => (
                                                                    <option value=''>
                                                                        {placeholder}...
                                                                    </option>
                                                                )}
                                                            </FormattedMessage>

                                                            <option value='CA'>Canada</option>
                                                            <option value='US'>US</option>
                                                            <option value='Other'>Other</option>
                                                        </CountrySelect>
                                                    </div>
                                                </div>

                                                {countrySelected === 'Other' && (
                                                    <>
                                                        <div className='Country col-l st-ad pl-0 my-pad-1 country-2'>
                                                            <label>
                                                                <span
                                                                    style={{visibility: 'hidden'}}
                                                                >
                                                                    Country
                                                                </span>
                                                            </label>
                                                            <input
                                                                id='country2'
                                                                type='text'
                                                                value={values.country2}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={
                                                                    errors.country2 &&
                                                                    touched.country2
                                                                        ? 'text-input error'
                                                                        : 'text-input'
                                                                }
                                                            />
                                                            {errors.country2 &&
                                                                touched.country2 && (
                                                                    <div className='input-feedback'>
                                                                        {errors.country2}
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                            <div className='row'>
                                                {countrySelected !== 'Other' && (
                                                    <div className='col st-ad pl-0 my-pad-1 city-name payment'>
                                                        <label>
                                                            <FormattedMessage id='billing.province_state' />{' '}
                                                            <span className='req-star'>*</span>
                                                        </label>
                                                        <div className='select-box'>
                                                            <div className='down-arrow'></div>
                                                            {/* set the value of Province to be used (value = {provinceValue}) */}
                                                            <ProvinceSelect
                                                                label='Province'
                                                                name='provinceState'
                                                                className='sel-province'
                                                                id='provinceSelect'
                                                                onChange={handleOptionChange}
                                                                value={provinceValue}
                                                                data-test={provinceValue}
                                                            >
                                                                {countrySelected === '' && (
                                                                    <FormattedMessage id='billing.country_select'>
                                                                        {(placeholder) => (
                                                                            <option value=''>
                                                                                {placeholder}...
                                                                            </option>
                                                                        )}
                                                                    </FormattedMessage>
                                                                )}
                                                                {countrySelected === 'CA' &&
                                                                    tax_ca.map(
                                                                        (province, index) => {
                                                                            return (
                                                                                <option
                                                                                    value={
                                                                                        province[
                                                                                            'abbreviation'
                                                                                        ]
                                                                                    }
                                                                                    key={index}
                                                                                >
                                                                                    {
                                                                                        province[
                                                                                            'name'
                                                                                        ]
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}

                                                                {countrySelected === 'US' &&
                                                                    tax_us.map(
                                                                        (province, index) => {
                                                                            return (
                                                                                <option
                                                                                    value={
                                                                                        province[
                                                                                            'abbreviation'
                                                                                        ]
                                                                                    }
                                                                                    key={index}
                                                                                >
                                                                                    {
                                                                                        province[
                                                                                            'name'
                                                                                        ]
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                            </ProvinceSelect>
                                                            <select id='test-taxid'>
                                                                <option>{provinceSelected}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                )}

                                                <div className='col pr-0  ad my-pad-1 city-name payment'>
                                                    <label>
                                                        <FormattedMessage id='billing.city_select' />{' '}
                                                        <span className='req-star'>*</span>
                                                    </label>
                                                    <input
                                                        id='city'
                                                        type='text'
                                                        value={values.city}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={
                                                            errors.city && touched.city
                                                                ? 'text-input error'
                                                                : 'text-input'
                                                        }
                                                    />
                                                    {errors.city && touched.city && (
                                                        <div className='input-feedback'>
                                                            {errors.city}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col st-ad pl-0 my-pad-1 card-num payment'>
                                                    <label>
                                                        <FormattedMessage id='billing.postal_code' />{' '}
                                                        <span className='req-star'>*</span>
                                                    </label>
                                                    <input
                                                        id='postalCode'
                                                        type='text'
                                                        value={values.postalCode}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        className={
                                                            errors.postalCode && touched.postalCode
                                                                ? 'text-input error'
                                                                : 'text-input'
                                                        }
                                                    />
                                                    {errors.postalCode && touched.postalCode && (
                                                        <div className='input-feedback'>
                                                            {errors.postalCode}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={
                                        'col-md-12 col-lg-6 pr-0 my-pl ' +
                                        (hideItemList ? 'row-billing-hide ' : '') +
                                        'item-list'
                                    }
                                >
                                    {!hideItemList && <AddonHeaderView />}

                                    {rotationUnit === 0 &&
                                        onboardInfo[2]['order_values'].map((order, index) => {
                                            //
                                            // only show the Addon that matches one the following conditions:
                                            // 1. order is of type [Standard]
                                            // 2. order is of type [Extra] and has the property 'enabled' to true
                                            // 3. order is of type [Monthly]/[Yearly]

                                            return (
                                                <>
                                                    {order['type'] === '[Standard]' ? (
                                                        <AddonViewOnly
                                                            addonName={order['name']}
                                                            itemName={`${
                                                                ITEMS[
                                                                    order['name'].replace(
                                                                        '[Standard] ',
                                                                        ''
                                                                    )
                                                                ]
                                                            }`}
                                                            itemType={order['type']}
                                                            price={
                                                                order['price'] * order['quantity']
                                                            }
                                                            quantity={order['quantity']}
                                                            currencyCode={currencyPage}
                                                            key={index}
                                                        />
                                                    ) : order['type'] === '[Extra]' &&
                                                      order['enabled'] === true ? (
                                                        <AddonViewOnly
                                                            addonName={order['name']}
                                                            itemName={`${
                                                                ITEMS[
                                                                    order['name'].replace(
                                                                        '[Extra] ',
                                                                        ''
                                                                    )
                                                                ]
                                                            }`}
                                                            itemType={order['type']}
                                                            price={
                                                                order['price'] * order['quantity']
                                                            }
                                                            quantity={order['quantity']}
                                                            currencyCode={currencyPage}
                                                            key={index}
                                                        />
                                                    ) : // order['type'] === '[Monthly]' || order['type'] === '[Yearly]' && (

                                                    //Has to be IF ELSE condition and not OR, otherwise it won't display the Addon of type [Monthly] or [Yearly]
                                                    order['type'] === '[Monthly]' ? (
                                                        <AddonViewOnly
                                                            addonName={order['name']}
                                                            itemName={`${
                                                                ITEMS[
                                                                    order['name'].replace(
                                                                        `${order['type']} `,
                                                                        ''
                                                                    )
                                                                ]
                                                            }`}
                                                            itemType={order['type']}
                                                            price={
                                                                order['price'] * order['quantity']
                                                            }
                                                            quantity={order['quantity']}
                                                            currencyCode={currencyPage}
                                                            key={index}
                                                        />
                                                    ) : (
                                                        order['type'] === '[Yearly]' && (
                                                            <AddonViewOnly
                                                                addonName={order['name']}
                                                                itemName={`${
                                                                    ITEMS[
                                                                        order['name'].replace(
                                                                            `${order['type']} `,
                                                                            ''
                                                                        )
                                                                    ]
                                                                }`}
                                                                itemType={order['type']}
                                                                price={
                                                                    order['price'] *
                                                                    order['quantity']
                                                                }
                                                                quantity={order['quantity']}
                                                                currencyCode={currencyPage}
                                                                key={index}
                                                            />
                                                        )
                                                    )}
                                                </>
                                            );

                                            // : (
                                            //     <div> Add-on view error. Please contact your administrator.</div>
                                        })}
                                    {onboardInfo[2]['order_values'].length === 0 && (
                                        <div>
                                            Onboarding info is empty. Please contact Administrator.
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='flx'>
                                <div className='col-md-12 col-lg-6 pr-0 my-pl or-2'>
                                    <div className='btn-total-1'>
                                        <div className='total'>
                                            <h1>
                                                <FormattedMessage id='order.sub_total' />:
                                            </h1>
                                            <div className='price'>
                                                {onboardInfo[2]?.totalOrder ? (
                                                    <span className='onb-subtotal'>
                                                        {' '}
                                                        {
                                                            currency['symbols'][currencyPage][
                                                                'symbol'
                                                            ]
                                                        }
                                                        {parseInt(
                                                            onboardInfo[2].totalOrder
                                                        ).toFixed(2)}
                                                    </span>
                                                ) : (
                                                    // ? <span className="onb-subtotal">totalOrder {currency['symbols'][currencyPage]['symbol']}{parseInt(total).toFixed(2)}</span>
                                                    <span className='onb-subtotal'>
                                                        {' '}
                                                        {
                                                            currency['symbols'][currencyPage][
                                                                'symbol'
                                                            ]
                                                        }
                                                        {parseInt(quoteTotal).toFixed(2)}
                                                    </span>
                                                )}
                                                <span>
                                                    {currency['symbols'][currencyPage]['code']}
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <span id='test-tax' style={{display: 'none'}}></span>

                                    {/* only display the Taxes UI if taxesList exists */}
                                    {taxesList.length > 0 && (
                                        <div className='btn-total-2'>
                                            <div className='total'>
                                                <h1>Taxes:</h1>
                                                <div className='price'>
                                                    <span>
                                                        <span></span>
                                                    </span>
                                                    <span id='test-tax' style={{opacity: 0}}></span>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {/* Get the list of taxes associated with this province/state */}
                                    {taxesList.map((taxElement, index) => {
                                        // taxesListTest.map((taxElement, index) => {

                                        return (
                                            <>
                                                <div className='btn-total-2'>
                                                    <div className='total'>
                                                        <h1
                                                            id='taxName'
                                                            title={
                                                                tax['type'][taxElement['name']][
                                                                    'code'
                                                                ]
                                                            }
                                                        >
                                                            {taxElement['name']}{' '}
                                                            <span className='tax-code'>
                                                                {' '}
                                                                [
                                                                {
                                                                    tax['type'][taxElement['name']][
                                                                        'code'
                                                                    ]
                                                                }
                                                                ]
                                                            </span>
                                                            :{' '}
                                                        </h1>

                                                        <div className='price'>
                                                            {/* We use currencyPage to display the currency of the price because it is based on the country dropdown and will require instantaneous change*/}
                                                            <span>
                                                                <span>
                                                                    {
                                                                        currency['symbols'][
                                                                            currencyPage
                                                                        ]['symbol']
                                                                    }
                                                                </span>
                                                                {taxElement['unitPrice']}
                                                            </span>
                                                            <span>
                                                                {
                                                                    currency['symbols'][
                                                                        currencyPage
                                                                    ]['code']
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })}

                                    <div className='btn-total-2'>
                                        <div className='total'>
                                            <h1>
                                                <FormattedMessage id='billing.order_total' />
                                            </h1>
                                            <div className='price'>
                                                {onboardInfo[2]?.totalOrder ? (
                                                    <span className='onb-total'>
                                                        {' '}
                                                        {
                                                            currency['symbols'][currencyPage][
                                                                'symbol'
                                                            ]
                                                        }
                                                        {parseFloat(
                                                            onboardInfo[2].totalOrder + taxTotal
                                                        ).toFixed(2)}
                                                    </span>
                                                ) : (
                                                    <span className='onb-total'>
                                                        {' '}
                                                        {
                                                            currency['symbols'][currencyPage][
                                                                'symbol'
                                                            ]
                                                        }
                                                        {parseFloat(quoteTotal + taxTotal).toFixed(
                                                            2
                                                        )}
                                                    </span>
                                                )}
                                                <span>
                                                    {currency['symbols'][currencyPage]['code']}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* do not remove: dummy column for keeping bootstrap formatting from breaking */}
                                <div className='col-md-12 col-lg-6 pl-0 my-pr or-1'>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>

                            <div className='col-md-12 col-lg-6 pl-0 my-pr or-1'>
                                <div className='trial-msg'>
                                    {/* <p><FormattedMessage id="billing.trial_method" /></p> */}
                                    <p>
                                        <FormattedMessage id='billing.invoice_message' />{' '}
                                    </p>
                                </div>
                                <div>
                                    <input type='checkbox' onClick={handleCheck}></input>
                                    <span> </span>
                                    <FormattedMessage id='payment.terms1' />
                                    <FormattedMessage id='payment.terms_link'>
                                        {(placeholder) => (
                                            <a
                                                href={placeholder}
                                                target='_blank'
                                                rel='noopener noreferrer'
                                            >
                                                <FormattedMessage id='payment.terms2' />
                                            </a>
                                        )}
                                    </FormattedMessage>
                                </div>

                                <div className='pay-con'>
                                    <button type='submit' disabled={termsChecked ? '' : 'disabled'}>
                                        <FormattedMessage id='billing.subscribe' />
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>

            {/* By checking out, you will automatically have access a free 14 days trial of the service you have chosen. You will not be charged any subscription fees until the 14 days trial ends. */}
        </>
    );
};

export default BillingInfo;
