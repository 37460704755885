import React from 'react';
import {FormattedMessage} from 'react-intl';

const PaymentHeader = ({paymentStage}) => {
    return (
        <div className='check-out'>
            <div className='row'>
                <div className='col-4'>
                    <div
                        className={`main-box-c ${paymentStage === 'contactInfo' ? 'active-4' : ''}`}
                    >
                        {/* <div className="ch-circle" onClick={()=>onStageChange("contactInfo")} id='0' > */}
                        {/* <div className="ch-circle" onClick={() => checkDirection(0, "contactInfo")} id='0' > */}
                        <div className='ch-circle' id='0'>
                            <i className='fa fa-user' aria-hidden='true' />
                        </div>
                        <h1>
                            <FormattedMessage id='payment.step1.top' />
                            <br />
                            <FormattedMessage id='payment.step1.bottom' />
                        </h1>
                    </div>
                </div>
                <div className='col-4'>
                    <div className={`main-box-c ${paymentStage === 'orderInfo' ? 'active-4' : ''}`}>
                        {/* <div className="ch-circle" onClick={checkEmptyContact} id='1'> */}
                        <div className='ch-circle' id='1'>
                            <i className='fa fa-shopping-bag' aria-hidden='true' />
                        </div>
                        <h1>
                            <FormattedMessage id='payment.step2.top' />
                            <br />
                            <FormattedMessage id='payment.step2.bottom' />
                        </h1>
                    </div>
                </div>
                <div className='col-4'>
                    <div
                        className={`main-box-c ${
                            paymentStage === 'billingInfo' || paymentStage === 'quoteBillingInfo'
                                ? 'active-4'
                                : ''
                        }`}
                    >
                        {/* <div className="ch-circle" onClick={() => checkDirection(2, "billingInfo")} id='2'> */}
                        <div className='ch-circle' id='2'>
                            <i className='fa fa-usd' aria-hidden='true' />
                        </div>
                        <h1>
                            <FormattedMessage id='payment.step3.top' />
                            <br />
                            <FormattedMessage id='payment.step3.bottom' />
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentHeader;
