const errors = [
    {
        en:
            'Shipping addresses , Shipping addresses[0] zip is invalid, Billing info address postal code is invalid',
        fr:
            "Adresses de livraison , Adresses de livraison [0] zip non valide, Le code postal de l'adresse de facturation n'est pas valide.",
    },
    {
        fr:
            'Votre transaction a été refusée. Veuillez utiliser une autre carte ou contacter votre banque.',
        en: 'Your transaction was declined. Please use a different card or contact your bank.',
    },
    {
        fr: 'Erreur lors de votre inscription. Veuillez contactez votre administrateur.',
        en: 'Subscription creation error. Please contact your administrator.',
    },
    {
        en: 'Your expiration date is invalid or does not match.',
        fr: "Votre date d'expiration est invalide ou ne correspond pas.",
    },
    {
        fr:
            "Le numéro d'informations de facturation n'est pas un numéro de carte de crédit valide, le numéro d'informations de facturation a expiré ou a une date d'expiration invalide.",
        en:
            'Billing info number is not a valid credit card number, Billing info number is expired or has an invalid expiration date',
    },
    {en: 'Code has already been taken', fr: 'Le code du compte existe deja, veuillez reessayer'},
    {
        en: 'Billing info cvv must be four digits',
        fr: 'Le cvv des informations de la carte doit comporter quatre chiffres',
    },
    {
        en: 'Internal Server Error. Please contact your administrator',
        fr: 'Erreur de serveur interne. Veuillez contacter votre administrateur.',
    },
    {
        en:
            'Your billing address does not match the address on your account. Please update your address or contact your bank.',
        fr:
            "Votre adresse de facturation ne correspond pas à l'adresse de votre compte. Veuillez mettre à jour votre adresse ou contacter votre banque.",
    },
    {
        en: 'Your card number is not valid. Please update your card number.',
        fr: "Votre numéro de carte n'est pas valide. Veuillez mettre à jour votre numéro de carte.",
    },
    {
        en:
            'Your transaction was declined due to insufficient funds in your account. Please use a different card or contact your bank.',
        fr:
            'Votre transaction a été refusée en raison de fonds insuffisants sur votre compte. Veuillez utiliser une autre carte ou contacter votre banque.',
    },
    {
        en: 'The transaction was declined. Please contact support.',
        fr: 'La transaction a été refusée. Veuillez contacter le support.',
    },
    {
        en: 'The security code you entered does not match. Please update the CVV and try again.',
        fr:
            'Le code de sécurité que vous avez entré ne correspond pas. Veuillez mettre à jour le CVV et réessayer.',
    },
    {
        en:
            'Your transaction was declined. Please contact your bank for further details or try another card.',
        fr:
            'Votre transaction a été refusée. Veuillez contacter votre banque pour plus de détails ou essayez une autre carte.',
    },
    {
        en: 'The transaction was declined due to invalid data.',
        fr: 'La transaction a été refusée en raison de données invalides.',
    },
    {
        en:
            'The transaction was declined. Please use a different card, contact your bank, or contact support.',
        fr:
            'La transaction a été refusée. Veuillez utiliser une autre carte, contacter votre banque ou contacter le support.',
    },
    {
        en: 'This transaction was declined. Please contact your bank or try a different card.',
        fr:
            'Cette transaction a été refusée. Veuillez contacter votre banque ou essayer une autre carte.',
    },
    {
        en:
            'Please contact support: the payment system did not respond in time to process your transaction.',
        fr:
            "Veuillez contacter le support : le système de paiement n'a pas répondu à temps pour traiter votre transaction.",
    },
    {
        en:
            'A similar transaction was recently submitted. Please wait a few minutes and try again.',
        fr:
            "Une transaction similaire a été soumise récemment. S'il vous plait attendez quelques minutes et réessayez.",
    },
    {
        en: 'Your card type is not accepted. Please try another card.',
        fr: "Votre type de carte n'est pas accepté. Veuillez essayer une autre carte.",
    },
    {
        en: 'The transaction was declined. Please use a different card or contact your bank.',
        fr:
            'La transaction a été refusée. Veuillez utiliser une autre carte ou contacter votre banque.',
    },
    {
        en: 'Your card has a temporary hold. Please use a different card or contact your bank.',
        fr:
            'Votre carte a une retenue temporaire. Veuillez utiliser une autre carte ou contacter votre banque.',
    },
    {
        en:
            'The transaction was declined. You have exceeded a reasonable number of attempts. Please wait a while before retrying your card, or try a different card.',
        fr:
            'La transaction a été refusée. Vous avez dépassé un nombre raisonnable de tentatives. Veuillez patienter un peu avant de réessayer votre carte, ou essayez une autre carte.',
    },
    {
        en:
            'The transaction was declined because a simultaneous transaction is already in progress for your account. Please wait 10 seconds before trying again.',
        fr:
            'La transaction a été refusée car une transaction simultanée est déjà en cours pour votre compte. Veuillez attendre 10 secondes avant de réessayer.',
    },
    {
        en:
            'The transaction was declined due to insufficient funds in your account. Please use a different card or contact your bank.',
        fr:
            'La transaction a été refusée en raison de fonds insuffisants sur votre compte. Veuillez utiliser une autre carte ou contacter votre banque.',
    },
    {
        en:
            'Your transaction was declined. Please contact your bank for further details or try another card.',
        fr:
            'Votre transaction a été refusée. Veuillez contacter votre banque pour plus de détails ou essayez une autre carte.',
    },
    {
        en:
            'Please contact support: the payment system experienced an unspecified error with your card issuer.',
        fr:
            "Veuillez contacter l'assistance : le système de paiement a rencontré une erreur non spécifiée avec l'émetteur de votre carte.",
    },
    {
        en: 'This payment was cancelled at your request.',
        fr: 'Ce paiement a été annulé à votre demande.',
    },
    {
        en:
            'Your primary funding source was declined. Please try again to use your secondary funding source.',
        fr:
            "Votre principale source de financement a été refusée. Veuillez réessayer d'utiliser votre source de financement secondaire.",
    },
    {
        en:
            'Your PayPal funding source was declined. Please try again to use an alternate funding source.',
        fr:
            "Votre principale source de financement a été refusée. Veuillez réessayer d'utiliser votre source de financement secondaire.",
    },
    {
        en: 'Transaction declined for an unknown reason, please try again later.',
        fr: 'Transaction refusée pour une raison inconnue, veuillez réessayer plus tard.',
    },
    {
        en:
            'The security code (CVV) or expiration date you entered does not match. Please update the CVV/Expiration Date and try again.',
        fr:
            'The payment gateway declined the transaction because the security code (CVV) or expiration date did not match.',
    },
    {
        en: 'Your card number is not valid. Please update your card number.',
        fr: "Votre numéro de carte n'est pas valide. Veuillez mettre à jour votre numéro de carte.",
    },
    {
        en:
            'Your card cannot be accepted. Please contact your issuing bank for details or try another card.',
        fr:
            'Votre carte ne peut pas être acceptée. Veuillez contacter votre banque émettrice pour plus de détails ou essayez une autre carte.',
    },
    {
        en:
            'The attempt to authenticate failed due to a connection error. Please try again or try a different payment method.',
        fr:
            "La tentative d'authentification a échoué en raison d'une erreur de connexion. Veuillez réessayer ou essayer un autre mode de paiement.",
    },
    {
        en:
            'Your transaction was declined. Please contact your bank for further details or try another bank account.',
        fr:
            'Votre transaction a été refusée. Veuillez contacter votre banque pour plus de détails ou essayez un autre compte bancaire.',
    },
    {
        en: 'Your transaction was cancelled. Please contact support for further details.',
        fr: 'Votre transaction a été annulée. Veuillez contacter le support pour plus de détails.',
    },
    {
        en: 'Your transaction was cancelled. Please contact your bank for further details.',
        fr:
            'Votre transaction a été annulée. Veuillez contacter votre banque pour plus de détails.',
    },
    {
        en: 'Information provided was invalid. Please contact support for further details.',
        fr:
            'Les informations fournies étaient invalides. Veuillez contacter le support pour plus de détails.',
    },
    {
        en:
            'The payment system experienced an unspecified error. Please contact support for further details.',
        fr:
            'Le système de paiement a rencontré une erreur non spécifiée. Veuillez contacter le support pour plus de détails.',
    },
    {
        en:
            'The payment system experienced an error. Your account was not charged. Please contact support for further details.',
        fr:
            "Le système de paiement a rencontré une erreur. Votre compte n'a pas été débité. Veuillez contacter le support pour plus de détails.",
    },
    {
        en:
            'Your transaction was declined. Please contact your bank for further details or try another card.',
        fr:
            'Votre transaction a été refusée. Veuillez contacter votre banque pour plus de détails ou essayez une autre carte.',
    },
    {
        en:
            'Your card was declined. In order to resolve the issue, check your payment method in Amazon.',
        fr:
            'Votre carte a été refusée. Afin de résoudre le problème, vérifiez votre mode de paiement sur Amazon.',
    },
    {
        en: 'Your billing agreement is no longer valid and has been replaced.',
        fr: "Votre accord de facturation n'est plus valide et a été remplacé.",
    },
    {
        en:
            'Please contact support: the payment system is configured incorrectly. Your PayPal account was not charged.',
        fr:
            "Veuillez contacter le support : le système de paiement est mal configuré. Votre compte PayPal n'a pas été débité.",
    },
    {
        en:
            'Please contact support: the payment system experienced an unspecified error with your card issuer.',
        fr:
            "Veuillez contacter l'assistance : le système de paiement a rencontré une erreur non spécifiée avec l'émetteur de votre carte.",
    },
    {
        en:
            'Please contact support: the payment system experienced an error. Your card was not charged.',
        fr:
            "Veuillez contacter le support : le système de paiement a rencontré une erreur. Votre carte n'a pas été débitée.",
    },
    {
        en:
            'Please contact support: the requested currency is not supported for this merchant. Your card was not charged.',
        fr:
            "Veuillez contacter le support : la devise demandée n'est pas prise en charge pour ce marchand. Votre carte n'a pas été débitée.",
    },
    {
        en:
            'Please contact support: the payment system experienced an error while authorizing your card. Your card was not charged.',
        fr:
            "Veuillez contacter le support : le système de paiement a rencontré une erreur lors de l'autorisation de votre carte. Votre carte n'a pas été débitée.",
    },
    {
        en:
            'Please contact support: the payment system experienced an error while authorizing your card. Your card was not charged.',
        fr:
            "Veuillez contacter le support : le système de paiement a rencontré une erreur lors de l'autorisation de votre carte. Votre carte n'a pas été débitée.",
    },

    {
        en:
            'Please contact support: the payment system is configured incorrectly. The transaction was not processed.',
        fr:
            "Veuillez contacter le support : le système de paiement est mal configuré. La transaction n'a pas été traitée.",
    },
    {
        en: 'Please contact support: ACH/EFT transfers are not supported.',
        fr: 'Veuillez contacter le support : les transferts ACH/EFT ne sont pas pris en charge.',
    },
    {
        en:
            'Your payment gateway 3DS credentials are missing or incorrect. The card was not charged.',
        fr:
            "Les informations d'identification de votre passerelle de paiement 3DS sont manquantes ou incorrectes. La carte n'a pas été débitée.",
    },
    {
        en:
            'You cannot pay yourself using PayPal. Please use a different PayPal account for the transaction.',
        fr:
            'Vous ne pouvez pas vous payer avec PayPal. Veuillez utiliser un autre compte PayPal pour la transaction.',
    },
    {
        en:
            'The transaction failed because you did not accept the PayPal billing agreement. Please try again.',
        fr:
            "La transaction a échoué car vous n'avez pas accepté l'accord de facturation PayPal. Veuillez réessayer.",
    },
    {
        en:
            'The PayPal billing agreement failed because you have already accepted the agreement. Please try again or contact support.',
        fr:
            "L'accord de facturation PayPal a échoué car vous avez déjà accepté l'accord. Veuillez réessayer ou contacter l'assistance.",
    },
    {
        en:
            'Your Roku billing agreement is no longer valid. Please update your billing information.',
        fr:
            "Votre accord de facturation Roku n'est plus valide. Veuillez mettre à jour vos informations de facturation.",
    },
    {
        en: 'Your transaction was declined. Please try again or try another card.',
        fr: 'Votre transaction a été refusée. Veuillez réessayer ou essayer une autre carte.',
    },
    {
        en: 'Your billing information is missing some required information.',
        fr: 'Il manque certaines informations obligatoires dans vos informations de facturation.',
    },
    {
        en: 'The transaction was declined due to invalid data.',
        fr: 'La transaction a été refusée en raison de données invalides.',
    },
    {
        en: 'The transaction was declined. Please contact support.',
        fr: "La transaction a été refusée. Veuillez contacter l'assistance.",
    },
    {en: 'Your email address is not valid.', fr: "Votre adresse e-mail n'est pas valide."},
    {
        en: 'Your card number is not valid. Please update your card number.',
        fr: "Votre numéro de carte n'est pas valide. Veuillez mettre à jour votre numéro de carte.",
    },
    {
        en: 'Your account number is not valid. Please update your account number.',
        fr:
            "Votre numéro de compte n'est pas valide. Veuillez mettre à jour votre numéro de compte.",
    },
    {
        en: 'Your payment details were not found. Please update your billing information.',
        fr:
            "Vos informations de paiement n'ont pas été trouvées. Veuillez mettre à jour vos informations de facturation.",
    },
    {
        en: 'Your credit card is expired, please update your card.',
        fr: 'Votre carte de crédit est expirée, veuillez mettre à jour votre carte.',
    },
    {
        en: 'Your expiration date is invalid or does not match.',
        fr: "Votre date d'expiration n'est pas valide ou ne correspond pas.",
    },
    {
        en: 'Your card is not allowed to complete this transaction. Please try another card.',
        fr:
            "Votre carte n'est pas autorisée à effectuer cette transaction. Veuillez essayer une autre carte.",
    },
    {
        en:
            'Your card is not allowed to complete this transaction. Please contact your bank or try another card.',
        fr:
            "Votre carte n'est pas autorisée à effectuer cette transaction. Veuillez contacter votre banque ou essayer une autre carte.",
    },
    {
        en: 'Your card number is not valid. Please try another card or contact your bank.',
        fr:
            "Votre numéro de carte n'est pas valide. Veuillez essayer une autre carte ou contacter votre banque.",
    },
    {
        en: 'Your card type is not accepted. Please try another card.',
        fr: "Votre type de carte n'est pas accepté. Veuillez essayer une autre carte.",
    },
    {
        en: 'Your payment type is not accepted. Please try another card.',
        fr: "Votre type de paiement n'est pas accepté. Veuillez essayer une autre carte.",
    },
    {
        en:
            'Your card cannot be accepted. Please contact your issuing bank for details or try another card.',
        fr:
            'Votre carte ne peut pas être acceptée. Veuillez contacter votre banque émettrice pour plus de détails ou essayez une autre carte.',
    },
    {
        en:
            'Your card has not been activated. Please call your bank to activate your card and try again.',
        fr:
            "Votre carte n'a pas été activée. Veuillez appeler votre banque pour activer votre carte et réessayer.",
    },
    {
        en: 'The refund cannot be processed because of a chargeback.',
        fr: "Le remboursement ne peut pas être traité en raison d'une rétrofacturation.",
    },
    {
        en:
            'You canceled the transaction after it was approved. Please update your billing information to authorize a new transaction.',
        fr:
            'Vous avez annulé la transaction après son approbation. Veuillez mettre à jour vos informations de facturation pour autoriser une nouvelle transaction.',
    },
    {
        en:
            'You requested recurring payments no longer be accepted on this card. Please update your billing information.',
        fr:
            'Vous avez demandé que les paiements récurrents ne soient plus acceptés sur cette carte. Veuillez mettre à jour vos informations de facturation.',
    },
    {
        en: 'Your billing information is no longer valid. Please update your billing information.',
        fr:
            'Vos informations de facturation ne sont plus valides. Veuillez mettre à jour vos informations de facturation.',
    },

    {
        en: 'Payment was not processed. Please re-enter your billing information.',
        fr:
            "Le paiement n'a pas été traité. Veuillez saisir à nouveau vos informations de facturation.",
    },
    {
        en: 'Your billing information is not on file. Please add your billing information.',
        fr:
            'Vos informations de facturation ne sont pas enregistrées. Veuillez ajouter vos informations de facturation.',
    },
    {
        en:
            'Your PayPal billing agreement is no longer valid. Please update your billing information.',
        fr:
            "Votre accord de facturation PayPal n'est plus valide. Veuillez mettre à jour vos informations de facturation.",
    },
    {
        en:
            'Your primary funding source was declined. Please update your billing information with PayPal or try again.',
        fr:
            'Votre principale source de financement a été refusée. Veuillez mettre à jour vos informations de facturation avec PayPal ou réessayer.',
    },
    {
        en:
            'The billing info for this account has not been verified, please wait 24 hours and try again.',
        fr:
            "Les informations de facturation de ce compte n'ont pas été vérifiées, veuillez patienter 24 heures et réessayer.",
    },
    {
        en:
            'One or more values submitted did not pass a gateway specific validation. Please contact support to learn the cause.',
        fr:
            "Une ou plusieurs valeurs soumises n'ont pas réussi une validation spécifique à la passerelle. Veuillez contacter le support pour connaître la cause.",
    },
    {en: 'You must provide a first and last name.', fr: 'Vous devez fournir un prénom et un nom.'},
    {
        en:
            'Your direct debit type is not accepted. Please try another direct debit type / currency combination.',
        fr:
            "Votre type de prélèvement n'est pas accepté. Veuillez essayer une autre combinaison type de prélèvement automatique/devise.",
    },
    {
        en: 'An error occurred while processing your transaction. Please contact support.',
        fr:
            "Une erreur s'est produite lors du traitement de votre transaction. Veuillez contacter l'assistance.",
    },
    {
        en: 'The original transaction was not found.',
        fr: "La transaction d'origine n'a pas été trouvée.",
    },
    {
        en:
            'The transaction has already been settled, so it cannot be voided. Please try a refund.',
        fr:
            'La transaction a déjà été réglée, elle ne peut donc pas être annulée. Veuillez essayer un remboursement.',
    },
    {
        en: 'The transaction has already been voided, so it cannot be settled or refunded.',
        fr: 'La transaction a déjà été annulée, elle ne peut donc pas être réglée ou remboursée.',
    },
    {
        en: 'The transaction did not settle successfully. Please update your billing information.',
        fr:
            "La transaction n'a pas été réglée avec succès. Veuillez mettre à jour vos informations de facturation.",
    },
    {
        en: 'An error occurred while voiding your payment authorization. Please contact support.',
        fr:
            "Une erreur s'est produite lors de l'annulation de votre autorisation de paiement. Veuillez contacter l'assistance.",
    },
    {
        en: 'An error occurred while refunding your transaction. Please contact support.',
        fr:
            "Une erreur s'est produite lors du remboursement de votre transaction. Veuillez contacter l'assistance.",
    },

    {
        en: 'The transaction cannot be voided. Please contact support.',
        fr: "La transaction ne peut pas être annulée. Veuillez contacter l'assistance.",
    },
    {en: 'The transaction has already been refunded.', fr: 'La transaction a déjà été remboursée.'},
    {
        en:
            'An error occurred while processing your transaction. Please update your billing information.',
        fr:
            "Une erreur s'est produite lors du traitement de votre transaction. Veuillez mettre à jour vos informations de facturation.",
    },
    {
        en: 'The transaction cannot be refunded at this time. Please try again later.',
        fr:
            'La transaction ne peut pas être remboursée pour le moment. Veuillez réessayer plus tard.',
    },
    {
        en: 'Your billing agreement is no longer valid. Please update your billing information.',
        fr:
            "Votre accord de facturation n'est plus valide. Veuillez mettre à jour vos informations de facturation.",
    },
    {
        en:
            'Your transaction was declined. Please contact your bank for further details or try another card.',
        fr:
            'Votre transaction a été refusée. Veuillez contacter votre banque pour plus de détails ou essayez une autre carte.',
    },

    {
        en:
            'Your billing address does not match the address on your account. Please update your address or contact your bank.',
        fr:
            "Votre adresse de facturation ne correspond pas à l'adresse de votre compte. Veuillez mettre à jour votre adresse ou contacter votre banque.",
    },
    {
        en: 'The security code you entered does not match. Please update the CVV and try again.',
        fr:
            'Le code de sécurité que vous avez entré ne correspond pas. Veuillez mettre à jour le CVV et réessayer.',
    },
    {
        en: 'The transaction was declined. Please use a different card or contact your bank.',
        fr:
            'La transaction a été refusée. Veuillez utiliser une autre carte ou contacter votre banque.',
    },
    {
        en: 'The transaction was declined. Please contact support.',
        fr: "La transaction a été refusée. Veuillez contacter l'assistance.",
    },
    {
        en:
            'Please validate information and try again. If the problem persists, please contact your bank.',
        fr:
            'Veuillez valider les informations et réessayer. Si le problème persiste, veuillez contacter votre banque.',
    },
    {
        en:
            'Your billing address does not match the address on your account. Please fix your address or contact your bank.',
        fr:
            "Votre adresse de facturation ne correspond pas à l'adresse de votre compte. Veuillez corriger votre adresse ou contacter votre banque.",
    },
    {
        en: 'This transaction was declined. Please contact your bank or try a different card.',
        fr:
            'Cette transaction a été refusée. Veuillez contacter votre banque ou essayer une autre carte.',
    },

    {
        en:
            'Please contact support: the payment system experienced an unspecified error with your card issuer.',
        fr:
            "Veuillez contacter l'assistance : le système de paiement a rencontré une erreur non spécifiée avec l'émetteur de votre carte.",
    },
    {
        en:
            'Please contact support: the payment system did not respond in time to process your transaction.',
        fr:
            "Veuillez contacter le support : le système de paiement n'a pas répondu à temps pour traiter votre transaction.",
    },
    {en: 'Please try your transaction again.', fr: 'Veuillez réessayer votre transaction.'},
    {
        en: 'Please contact support: the payment system is rate limiting requests.',
        fr: 'Veuillez contacter le support : le système de paiement limite les demandes.',
    },
    {
        en: 'Please try again in a few minutes.',
        fr: "S'il vous plait, réessayez dans quelques minutes.",
    },
    {
        en: 'Your card must be authenticated with 3-D Secure before continuing.',
        fr: 'Votre carte doit être authentifiée avec 3-D Secure avant de continuer.',
    },
];

export default errors;
