import React, {useContext} from 'react';
import {OnBoardContext} from '../../contexts/onboardContext';
import '../../assets/css/contactInfoView.css';

const ContactOnboardInfoView = ({onStageChange}) => {
    //will be used in the future
    const {onboardInfo} = useContext(OnBoardContext);

    let companyInfoOnboard = (
        <div style={{textAlign: 'left'}} className='contact-info'>
            <div className='column-info'>
                <ul className='company-info'>
                    <li style={{fontWeight: 'bold'}}>{onboardInfo[1]['contact_company']}</li>
                    {/* <li style={{fontWeight: "bold"}} >{onboardInfo[4]['contacts'][0]['company']}</li> */}
                    {
                        // double check first if the company exists
                        onboardInfo[1] !== null && (
                            <>
                                <li>
                                    {onboardInfo[1]['contact_firstname']}{' '}
                                    {onboardInfo[1]['contact_lastname']}
                                </li>
                                <li>{onboardInfo[1]['contact_email']}</li>
                                <li>{onboardInfo[1]['contact_phonenumber']}</li>
                            </>
                        )
                    }
                </ul>
            </div>
        </div>
    );

    return <>{companyInfoOnboard}</>;
};

export default ContactOnboardInfoView;
